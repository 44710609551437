import React, { useState, useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import AdminCalender from "./AdminCalender";
import EventScheduler from "./EventSchedule";
import secureLocalStorage from "react-secure-storage";

// React Component
const ApexChart = () => {
  const [adminCalender, setAdminCalender] = useState([]);
  const [events, setEvents] = useState([]);

  let userKycData = JSON.parse(secureLocalStorage.getItem("kycData"));
  const name =
    userKycData?.fullName === "Reshma Ranjan"
      ? "Reshma Ranjan"
      : userKycData?.fullName === "Saptarshi Das"
      ? "Saptarshi Das"
      : userKycData?.fullName === "Nitesh Kumar Mishra"
      ? "Nitesh Mishra"
      : userKycData?.fullName === "Nitish Kumar Pradhan"
      ? "Nitish Pradhan"
      : userKycData?.fullName === "Chandan Kumar"
      ? "Chandan Kumar"
      : userKycData?.fullName === "Jyoti"
      ? "Jyoti"
      : "";


  useEffect(() => {
    setLoading2(true);
    fetch(`https://api-scdndestates.com/api/get-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        setAdminCalender(response.dates);
        setEvents(response.events);
        setLoading2(false);
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  }, []);

  const [chartVisible, setChartVisible] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("Not Started");
  const [filter, setFilter] = useState("Entire");
  const [selectedRate, setselectedRate] = useState("unrated");
  const [pieChartData, setPieChartData] = useState([]);
  const [loading2, setLoading2] = useState(true);
  const [viewMode, setViewMode] = useState("total");
  const [assignees, setAssignees] = useState(null);
  const [months, setMonths] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [ratedData, setRatedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const [totalTask, setTotalTask] = useState([]);
  const [totalTime, setTotalTime] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(name);

  const statuses = [
    "Not Started",
    "In Progress",
    "Defective",
    "Pending",
    "Postponed",
    "Completed",
  ];

  const ratings = [
    "Unrated",
    "Exceeds Expectation",
    "Meets Expectation",
    "Not Meeting Expectation",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsOpen2(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside2);
    return () => document.removeEventListener("mousedown", handleClickOutside2);
  }, []);

  // Extract unique employee names from assignees
  const employeeNames = assignees;

  const transformPieChartData = (data) => {
    const ratings = [
      "unrated",
      "exceeds_expectation",
      "meets_expectation",
      "not_meeting_expectation",
    ];
    let transformedData = [];
    ratedData
      ?.filter((data) => data.name === selectedEmployee)
      ?.forEach((person) => {
        let temp = [0, 0, 0, 0];
        let name = selectedEmployee;
        ratings.map((rate, index) =>
          ["P0", "P1", "P2", "P3", "P4"].forEach((priority) => {
            Object.values(person?.[rate]?.[priority] || {}).forEach((days) => {
              temp[index] += days.Days;
            });
          })
        );

        transformedData[name] = { series: temp };
      });
    return transformedData;
  };

  // Example Usage

  const transformBarChartData = (ratedData) => {
    const categories = ["Completed", "Postponed", "Not_Started", "Pending"];
    const transformedData = categories.map((status) => ({
      name: status,
      data: [0, 0, 0, 0, 0],
    }));

    ratedData
      ?.filter((data) => data.name === selectedEmployee)
      ?.forEach((person) => {
        if (person?.[selectedRate]) {
          ["P0", "P1", "P2", "P3", "P4"].forEach((priority, index) => {
            transformedData.forEach((item) => {
              Object.entries(person?.[selectedRate]?.[priority] || {}).forEach(
                ([k, v]) => {
                  const statusKey = item.name?.replace(" ", "_");
                  if (v?.[statusKey]) {
                    item.data[index] += v.Days || 0;
                  }
                }
              );
              // Ensure values have only one decimal place
              item.data[index] = parseFloat(item.data[index].toFixed(1));
            });
          });
        }
      });

    return transformedData;
  };

  const mockRatingsData = transformPieChartData(ratedData);

  const mockBarChartData = transformBarChartData(ratedData); // Match object keys

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchRatings = async (filter = "Entire") => {
    await delay(500); // Simulate network delay
    const data = mockRatingsData[selectedEmployee] || { series: [0, 0, 0, 0] };
    return { ...data, filter };
  };

  useEffect(() => {
    const eventsOnDate = events
      .map((event) => ({
        event_date: event.Date,
        title: event.title,
        assignee: event.Assignee,
        desc: event.Description,
        blocker: event.Blocker,
        rating: event.Rating,
        priority: event.Priority,
        status: event.Status,
        budget: event.Budget,
        duration: event.Duration,
        dependency: event.Dependency,
        validators: event.file_validator,
        interrupted: event.Interrupted,
        resumption: event.Resumption,
      }))
      .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
    setAssignees([...new Set(eventsOnDate.map((event) => event.assignee))]);
    let months = [...new Set(getUniqueMonths(eventsOnDate))];
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    let filteredMonths = months.filter((month) => {
      let [monthName, year] = month.split(" ");
      year = parseInt(year, 10);

      return (
        year === currentYear ||
        (year === previousYear && ["Oct", "Nov", "Dec"].includes(monthName))
      );
    });

    setMonths(filteredMonths);
    let monthlyDataTemp = [];

    assignees?.forEach((assignee) => {
      let assigneeData = {
        name: assignee,
        not_started: 0,
        in_progress: 0,
        defective: 0,
        pending: 0,
        postponed: 0,
        completed: 0,
      };

      statuses?.forEach((status) => {
        let count = Object.values(eventsOnDate).filter((event) => {
          if (filter === "Entire") {
            return (
              event.assignee?.toLowerCase() === assignee?.toLowerCase() &&
              event.status?.toLowerCase() === status?.toLowerCase()
            );
          }
          return (
            new Date(event.event_date).getMonth() ===
              new Date(filter).getMonth() &&
            new Date(event.event_date).getFullYear() ===
              new Date(filter).getFullYear() &&
            event.assignee?.toLowerCase() === assignee?.toLowerCase() &&
            event.status?.toLowerCase() === status?.toLowerCase()
          );
        }).length;
        assigneeData[status?.replace(" ", "_")?.toLowerCase()] = count;
      });

      monthlyDataTemp?.push(assigneeData);
    });

    setFilteredData(monthlyDataTemp);
    setRatedData(convertEventsToData(eventsOnDate, filter));
  }, [
    JSON.stringify(assignees),
    JSON.stringify(events),
    viewMode,
    chartVisible,
    filter,
    selectedEmployee,
  ]);

  function convertEventsToData(eventsOnDate, filter) {
    let data = [];
    eventsOnDate
      .filter((event) => {
        if (filter === "Entire") {
          return true;
        }
        return (
          new Date(event.event_date).getMonth() ===
            new Date(filter).getMonth() &&
          new Date(event.event_date).getFullYear() ===
            new Date(filter).getFullYear()
        );
      })
      .forEach((event) => {
        let assignee = event.assignee;
        let rating = event.rating?.toLowerCase()?.replace(/\s+/g, "_"); // Convert rating to snake_case
        let priority = event.priority;
        let status = event.status?.replace(/\s+/g, "_");

        let days = event?.duration || "0 days";

        function convertToDays(duration) {
          if (duration?.includes("hour")) {
            return parseFloat(duration) / 8;
          } else if (duration?.includes("day")) {
            return parseFloat(duration);
          } else if (duration?.includes("week")) {
            return parseFloat(duration) * 5;
          }
          return 0; // Default case
        }

        let dayValue = convertToDays(days);

        // Find or create assignee object
        let assigneeObj = data.find((item) => item.name === assignee);
        if (!assigneeObj) {
          assigneeObj = {
            name: assignee,
            unrated: initPriorityObject(),
            exceeds_expectation: initPriorityObject(),
            meets_expectation: initPriorityObject(),
            not_meeting_expectation: initPriorityObject(),
          };
          data.push(assigneeObj);
        }

        // Find the corresponding status object in the array
        let statusArray = assigneeObj[rating]?.[priority];
        if (statusArray) {
          let statusObj = statusArray.find((obj) => obj.hasOwnProperty(status));
          if (statusObj) {
            statusObj[status]++; // Increment count
            statusObj["Days"] += dayValue; // Add days to corresponding field
          }
        }
      });

    return data;
  }

  function initPriorityObject() {
    return {
      P0: initStatusArray(),
      P1: initStatusArray(),
      P2: initStatusArray(),
      P3: initStatusArray(),
      P4: initStatusArray(),
    };
  }

  function initStatusArray() {
    return [
      { Completed: 0, Days: 0 },
      { Not_Started: 0, Days: 0 },
      { In_Progress: 0, Days: 0 },
      { Defective: 0, Days: 0 },
      { Pending: 0, Days: 0 },
      { Postponed: 0, Days: 0 },
    ];
  }

  const getUniqueMonths = (events) => {
    const monthSet = new Set();

    events.forEach((event) => {
      if (event.event_date) {
        const date = new Date(event.event_date);
        const formattedMonth = date.toLocaleString("en-US", {
          month: "short",
          year: "numeric",
        });
        monthSet.add(formattedMonth);
      }
    });

    return Array.from(monthSet).sort((a, b) => {
      return new Date(a) - new Date(b);
    });
  };

  // Fetch pie chart data when employee or filter changes
  useEffect(() => {
    const loadPieChartData = async () => {
      if (selectedEmployee) {
        const data = await fetchRatings(selectedEmployee, filter);
        setPieChartData({
          series: data.series,
          options: {
            chart: {
              type: "donut",
              events: {
                dataPointSelection: function (event, chartContext, config) {
                  const selectedLabel =
                    config.w.config.labels[config.dataPointIndex];
                  const label =
                    selectedLabel === "EE"
                      ? "exceeds_expectation"
                      : selectedLabel === "NE"
                      ? "not_meeting_expectation"
                      : selectedLabel === "ME"
                      ? "meets_expectation"
                      : "unrated";
                  setselectedRate(label);
                },
              },
            },
            labels: ["Unrated", "EE", "ME", "NE"],
            colors: ["#b3a2de", "#563A9C", "#8B5DFF", "#a786fc"],
            fill: {
              type: "gradient",
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return value.toFixed(0) + (value <= 1 ? " day" : " days");
                },
              },
            },
            legend: {
              show: true,
              position: "bottom",
              labels: {
                colors: "#333",
              },
            },
          },
        });
      }
    };

    loadPieChartData();
  }, [selectedEmployee, filter, JSON.stringify(ratedData), selectedRate]);

  const [stackedBarChartState, setStackedBarChartState] = useState({
    series: mockBarChartData,
    options: {
      colors: ["#94DAFF", "#52575D", "#FF8787", "#FFD966"],

      chart: {
        type: "bar",
        height: "400",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "40%", // Reduce the width percentage as needed
          barHeight: "80%",
          horizontal: false,
          borderRadius: 2,
          borderRadiusWhenStacked: "top", // Ensures only the top stacked bar is rounded

          dataLabels: {
            enabled: false,
            total: {
              enabled: false,
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            // Handle the case where value is undefined, null, or NaN
            if (isNaN(value) || value === undefined || value === null) {
              return "0.00 days";
            }
            // Format to 2 decimal places and add day/days
            const formattedValue = value.toFixed(0);
            return `${formattedValue} ${value <= 1 ? "day" : "days"}`;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return isNaN(value) || value === undefined || value === null
              ? "0.0" // Default value if undefined
              : parseFloat(value.toFixed(1)); // Ensures 1 decimal place
          },
        },
      },
      xaxis: {
        categories: ["P0", "P1", "P2", "P3", "P4", "P5"],
      },

      legend: {
        markers: {
          width: 10, // Adjust circle size
          height: 10, // Ensure width and height are equal for a perfect circle
          radius: 12, // Set high radius to make it circular
        },
        formatter: function (seriesName) {
          return seriesName.replaceAll("_", " "); // Replaces underscores with spaces in the legend
        },
      },
    },
  });

  useEffect(() => {
    setStackedBarChartState((prev) => ({
      ...prev,
      series: mockBarChartData,
    }));
  }, [JSON.stringify(mockBarChartData), selectedRate]);

  const taskStatuses = [
    { key: "not_started", label: "Not Started", icon: "/images/cancel.svg" },
    { key: "pending", label: "Pending", icon: "/images/hourglass.svg" },
    { key: "defective", label: "Defective", icon: "/images/radioactive.svg" },
    { key: "completed", label: "Completed", icon: "/images/checklist.svg" },
    {
      key: "in_progress",
      label: "In Progress",
      icon: "/images/processing-time.svg",
    },
    {
      key: "overdue",
      label: "Overdue",
      icon: "/images/coorperation.svg",
    },
    {
      key: "in_research",
      label: "In Research",
      icon: "/images/magnifying-glass.svg",
    },
    { key: "for_review", label: "For Review", icon: "/images/send.svg" },
    { key: "postponed", label: "Postponed", icon: "/images/time.svg" },
  ];

  function formattedDate(date) {
    var newDate = new Date(date);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    newDate = newDate.toLocaleDateString("en-IN", options)?.replace(" ", "-");
    const formattedDate2 = newDate?.replace(" ", "-");
    return formattedDate2;
  }

  // Inside your component:
  const [count1, setCount1] = useState(0);
  const totalTasks = Math.round(
    filteredData?.find((person) => person.name === selectedEmployee)
      ? Object.values(
          filteredData.find((person) => person.name === selectedEmployee) || {}
        )
          .slice(1)
          .reduce((sum, num) => sum + (num || 0), 0)
      : 0
  );

  useEffect(() => {
    // Reset count to 0 when totalTasks changes
    setCount1(0);

    const increment = Math.ceil(totalTasks / 10); // Will complete in 10 steps

    if (totalTasks > 0) {
      const timer = setInterval(() => {
        setCount1((prev) => {
          if (prev + increment >= totalTasks) {
            clearInterval(timer);
            return totalTasks;
          }
          return prev + increment;
        });
      }, 40);

      return () => clearInterval(timer);
    }
  }, [totalTasks]);

  // Inside your component:
  const [count2, setCount2] = useState(0);
  const finalValue = Math.round(
    pieChartData?.series?.reduce((sum, num) => sum + num, 0) || 0
  );

  useEffect(() => {
    // Reset count to 0 when finalValue changes
    setCount2(0);

    const increment = Math.ceil(finalValue / 10); // Will complete in 10 steps

    if (finalValue > 0) {
      const timer = setInterval(() => {
        setCount2((prev) => {
          if (prev + increment >= finalValue) {
            clearInterval(timer);
            return finalValue;
          }
          return prev + increment;
        });
      }, 40);

      return () => clearInterval(timer);
    }
  }, [finalValue]);

  return (
    <div className="overflow-hidden">
      {chartVisible === 0 ? (
        <div className="p-2 overflow-hidden">
          <div className="flex flex-col items-start justify-center  ">
            <div className="w-full flex  items-start justify-start gap-4">
              <div
                className=" w-32 max-md:mb-4 md:w-44 relative"
                ref={dropdownRef}
              >
                {/* Selected Item */}
                <button
                  className={`w-full relative flex items-center justify-between   px-2 py-1.5  group font-semibold md:text-base   border-2 border-[#563A9C] hover:bg-purple-50  overflow-hidden  transition-all duration-200 ease-linear text-black/70  text-[10px] ${
                    isOpen ? "rounded-t-lg bg-purple-50  " : "rounded-lg "
                  }`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {selectedEmployee || "Select Employee"}

                  <svg
                    version="1.1"
                    id="fi_318275"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    class={`md:w-5 md:h-5 w-4 h-4   stroke-black/70   transition-all duration-500 ease-in-out ${
                      isOpen ? "-rotate-90" : "rotate-90"
                    }`}
                  >
                    <g>
                      <g>
                        <g>
                          <path
                            d="M256,0C114.618,0,0,114.618,0,256s114.618,256,256,256s256-114.618,256-256S397.382,0,256,0z M256,469.333
				c-117.818,0-213.333-95.515-213.333-213.333S138.182,42.667,256,42.667S469.333,138.182,469.333,256S373.818,469.333,256,469.333
				z"
                          ></path>
                          <path
                            d="M228.418,134.248c-8.331-8.331-21.839-8.331-30.17,0c-8.331,8.331-8.331,21.839,0,30.17L289.83,256l-91.582,91.582
				c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0l106.667-106.667c8.331-8.331,8.331-21.839,0-30.17
				L228.418,134.248z"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </button>

                <ul
                  className={`absolute no-scrollbar left-0  overflow-y-auto  w-full bg-purple-50  z-10  duration-700 transition-all ease-in-out ${
                    isOpen ? "h-48 rounded-b-lg  " : "h-0  rounded-lg "
                  }`}
                >
                  {employeeNames?.map((name, index) => (
                    <li
                      key={index}
                      className={`px-2 py-1.5 text-xs md:text-sm flex justify-between items-center cursor-pointer hover:bg-purple-200 ${
                        selectedEmployee === name
                          ? "bg-purple-200 font-semibold"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedEmployee(name);
                        setIsOpen(false);
                      }}
                    >
                      {name}
                      {selectedEmployee === name && ""}
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className=" w-32 max-md:mb-4 md:w-44 relative"
                ref={dropdownRef2}
              >
                {/* Selected Item */}
                <button
                  className={`w-full relative flex items-center justify-between   px-2 py-1.5  group font-semibold md:text-base  border-2 border-[#563A9C] hover:bg-purple-50  overflow-hidden  transition-all duration-200 ease-linear text-black/70  text-[10px] ${
                    isOpen2 ? "rounded-t-lg bg-purple-50  " : "rounded-lg "
                  }`}
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  {filter || "Select Month"}
                  <svg
                    version="1.1"
                    id="fi_318275"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    class={`md:w-5 md:h-5 w-4 h-4   stroke-black/70   transition-all duration-500 ease-in-out ${
                      isOpen2 ? "-rotate-90" : "rotate-90"
                    }`}
                  >
                    <g>
                      <g>
                        <g>
                          <path
                            d="M256,0C114.618,0,0,114.618,0,256s114.618,256,256,256s256-114.618,256-256S397.382,0,256,0z M256,469.333
				c-117.818,0-213.333-95.515-213.333-213.333S138.182,42.667,256,42.667S469.333,138.182,469.333,256S373.818,469.333,256,469.333
				z"
                          ></path>
                          <path
                            d="M228.418,134.248c-8.331-8.331-21.839-8.331-30.17,0c-8.331,8.331-8.331,21.839,0,30.17L289.83,256l-91.582,91.582
				c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0l106.667-106.667c8.331-8.331,8.331-21.839,0-30.17
				L228.418,134.248z"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </button>

                <ul
                  className={`absolute no-scrollbar left-0  overflow-y-auto  w-full bg-purple-50  z-10  duration-700 transition-all ease-in-out ${
                    isOpen2
                      ? "h-48 rounded-b-lg  shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                      : "h-0  rounded-lg "
                  }`}
                >
                  <li
                    className={`px-2 py-1.5 text-sm flex justify-between items-center cursor-pointer hover:bg-purple-200 ${
                      filter === "Entire" ? "bg-purple-200 font-semibold" : ""
                    }`}
                    onClick={() => {
                      setFilter("Entire");
                      setIsOpen(false);
                    }}
                  >
                    Entire
                  </li>
                  {months?.map((name, index) => (
                    <li
                      key={index}
                      className={`px-2 py-1.5 text-xs md:text-sm flex justify-between items-center cursor-pointer hover:bg-purple-200 ${
                        filter === name ? "bg-purple-200 font-semibold" : ""
                      }`}
                      onClick={() => {
                        setFilter(name);
                        setIsOpen(false);
                      }}
                    >
                      {name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="w-full flex md:flex-row max-md:flex-col items-center justify-center">
              <div className="w-full flex items-center justify-center    md:w-1/2">
                {loading2 ? (
                  <div className="w-full h-full  max-md:min-h-[300px] flex items-center justify-center opacity-50">
                    <img
                      className="w-20 h-20 animate-pulse"
                      src="/images/analytics.gif"
                      alt=""
                    />{" "}
                  </div>
                ) : (
                  <div className=" w-full h-full items-center flex justify-center">
                    <div className="absolute mb-4 w-40 h-40 flex flex-col items-center gap-y-2 justify-center bg-white p-1 font-medium  rounded-full">
                      <button class="group relative">
                        <div class="relative flex items-center gap-2 rounded-xl   p-1 pr-4">
                          <div class="flex items-center gap-2 rounded-lg ">
                            <div class="relative flex items-center bg-purple-50 max-md:p-0.5 md:p-1 rounded-lg">
                              <svg
                                class="relative md:h-6 md:w-6 w-4 h-4 fill-purple-500"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                id="fi_9741134"
                              >
                                <g>
                                  <path d="m17.6201 9.62012h-5.25c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.25c.41 0 .75.34.75.75s-.33.75-.75.75z"></path>
                                  <path d="m7.11957 10.3801c-.19 0-.38-.07-.53-.22l-.75-.75003c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l.22.22 1.72-1.72c.29-.29.77-.29 1.06 0 .29003.29.29003.77 0 1.06l-2.25 2.25003c-.14.14-.33.22-.53.22z"></path>
                                  <path d="m17.6201 16.6201h-5.25c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.25c.41 0 .75.34.75.75s-.33.75-.75.75z"></path>
                                  <path d="m7.11957 17.3801c-.19 0-.38-.07-.53-.22l-.75-.75c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l.22.22 1.72-1.72c.29-.29.77-.29 1.06 0 .29003.29.29003.77 0 1.06l-2.25 2.25c-.14.14-.33.22-.53.22z"></path>
                                  <path d="m15 22.75h-6c-5.43 0-7.75-2.32-7.75-7.75v-6c0-5.43 2.32-7.75 7.75-7.75h6c5.43 0 7.75 2.32 7.75 7.75v6c0 5.43-2.32 7.75-7.75 7.75zm-6-20c-4.61 0-6.25 1.64-6.25 6.25v6c0 4.61 1.64 6.25 6.25 6.25h6c4.61 0 6.25-1.64 6.25-6.25v-6c0-4.61-1.64-6.25-6.25-6.25z"></path>
                                </g>
                              </svg>
                            </div>

                            <div class="flex flex-col items-start leading-3 md:leading-4  justify-center">
                              <div className="text-[8px] md:text-[10px] ">
                                A total of
                              </div>
                              <div class="flex text-[13px] md:text-[16px] font-bold  ">
                                {count1} {count1 === 1 ? "task" : "tasks"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                      <button class="group relative">
                        <div class="relative flex items-center gap-2 rounded-xl   p-1 pr-4">
                          <div class="flex items-center gap-2 rounded-lg   ">
                            <div class="relative flex items-center bg-purple-50 max-md:p-0.5 md:p-1  rounded-lg">
                              <svg
                                version="1.1"
                                id="fi_106061"
                                xmlns="http://www.w3.org/2000/svg"
                                class="relative  md:h-6 md:w-6 w-4 h-4 fill-purple-500"
                                viewBox="0 0 219.786 219.786"
                              >
                                <g>
                                  <path
                                    d="M109.881,183.46c-4.142,0-7.5,3.358-7.5,7.5v21.324c0,4.142,3.358,7.5,7.5,7.5c4.143,0,7.5-3.358,7.5-7.5V190.96
		C117.381,186.817,114.023,183.46,109.881,183.46z"
                                  ></path>
                                  <path
                                    d="M109.881,36.329c4.143,0,7.5-3.358,7.5-7.5V7.503c0-4.142-3.357-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v21.326
		C102.381,32.971,105.739,36.329,109.881,36.329z"
                                  ></path>
                                  <path
                                    d="M47.269,161.909l-15.084,15.076c-2.93,2.928-2.931,7.677-0.003,10.606c1.465,1.465,3.385,2.198,5.305,2.198
		c1.919,0,3.837-0.732,5.302-2.195l15.084-15.076c2.93-2.928,2.931-7.677,0.003-10.606
		C54.946,158.982,50.198,158.982,47.269,161.909z"
                                  ></path>
                                  <path
                                    d="M167.208,60.067c1.919,0,3.838-0.732,5.303-2.196l15.082-15.076c2.929-2.929,2.93-7.677,0.002-10.607
		c-2.929-2.93-7.677-2.931-10.607-0.001l-15.082,15.076c-2.929,2.928-2.93,7.677-0.002,10.606
		C163.368,59.335,165.288,60.067,167.208,60.067z"
                                  ></path>
                                  <path
                                    d="M36.324,109.895c0-4.142-3.358-7.5-7.5-7.5H7.5c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h21.324
		C32.966,117.395,36.324,114.037,36.324,109.895z"
                                  ></path>
                                  <path
                                    d="M212.286,102.395h-21.334c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h21.334c4.143,0,7.5-3.358,7.5-7.5
		C219.786,105.754,216.429,102.395,212.286,102.395z"
                                  ></path>
                                  <path
                                    d="M47.267,57.871c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.732,5.303-2.196c2.929-2.929,2.929-7.678,0-10.607
		L42.797,32.188c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.606L47.267,57.871z"
                                  ></path>
                                  <path
                                    d="M172.52,161.911c-2.929-2.929-7.678-2.93-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.606l15.074,15.076
		c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196c2.93-2.929,2.93-7.678,0.001-10.606L172.52,161.911z"
                                  ></path>
                                  <path
                                    d="M109.889,51.518c-32.187,0-58.373,26.188-58.373,58.377c0,32.188,26.186,58.375,58.373,58.375
		c32.19,0,58.378-26.187,58.378-58.375C168.267,77.706,142.078,51.518,109.889,51.518z M109.889,153.27
		c-23.916,0-43.373-19.458-43.373-43.375c0-23.918,19.457-43.377,43.373-43.377c23.919,0,43.378,19.459,43.378,43.377
		C153.267,133.812,133.808,153.27,109.889,153.27z"
                                  ></path>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                              </svg>
                            </div>
                            <div class="flex flex-col items-start leading-3 md:leading-4 justify-center">
                              <div className="text-[8px] md:text-[10px] ">
                                Total Duration
                              </div>
                              <div class="flex text-[13px] md:text-[16px] font-bold   ">
                                {count2} {count2 === 1 ? "day" : "days"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className="w-full p-0 min-w-[370px] relative max-w-[500px]">
                      <ReactApexChart
                        options={pieChartData?.options || {}}
                        series={pieChartData?.series || []}
                        type="donut"
                        className=""
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className=" overflow-scroll w-full md:w-1/2  md:p-4 border-2 border-purple-50 rounded-lg max-md:mt-4  ">
                <div className="  flex items-center justify-start  bg-purple-50/90 rounded-full p-2">
                  {/* <div class="flex h-8 w-8 items-center justify-center rounded-lg bg-gradient-to-br from-indigo-500 to-purple-500">
                    <svg
                      class="h-4 w-4 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                      ></path>
                    </svg>
                  </div> */}
                  <span class="flex items-center gap-x-1 rounded-full bg-purple-500/20 px-2 py-1 text-xs font-semibold text-purple-600">
                    <span class="h-2 w-2 rounded-full bg-purple-600 animate-pulse"></span>
                    Live
                  </span>
                  <div className="px-2 text-base text-center text-black/80 lg:text-xl font-semibold">
                    {" "}
                    {`${selectedRate.split("")[0].toUpperCase()}${selectedRate
                      .replaceAll("_", " ")
                      .slice(1)}`}
                  </div>
                </div>
                <div className="w-full min-w-[400px]">
                  <ReactApexChart
                    options={stackedBarChartState.options}
                    series={stackedBarChartState.series}
                    type="bar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full overflow-hidden flex md:flex-row max-md:flex-col items-center justify-center my-5">
            <div className="w-full md:w-1/2 md:p-2">
              <AdminCalender
                adminCalender={adminCalender}
                onReload2={(data) => {
                  setEvents(data);
                  
                }}
              />
              
            </div>
            <div className="w-full md:w-1/2">
              {loading2 ? (
                <>
                  <div className="w-full flex flex-col items-center justify-center gap-y-4 max-md:px-0 md:px-4 lg:p-0 max-md:mt-5">
                    {/* Task Statuses */}
                    <div className=" max-md:w-full max-w-lg rounded-lg flex flex-wrap max-lg:gap-x-3 lg:gap-x-5 gap-y-5 justify-start items-start bg-white p-1 ">
                      {[...Array(9)].map((_, index) => (
                        <div
                          key={index}
                          className="flex flex-col items-center justify-center w-[30%] h-[85px] md:w-24 md:h-24 lg:w-32 lg:h-28 xl:w-36 xl:h-[120px]  text-xs font-semibold   bg-gray-200 gap-x-2    duration-300 ease-in-out rounded-lg  cursor-pointer group animate-pulse"
                        ></div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {filteredData
                    .filter((assignee) => assignee.name === selectedEmployee) // Show only selected employee
                    .map((assignee) => {
                      const totalTasks = taskStatuses.reduce(
                        (total, status) => total + (assignee[status.key] || 0),
                        0
                      );

                      return (
                        <div className="w-full flex flex-col items-center justify-center gap-y-4 md:px-4 lg:p-0 max-md:mt-5">
                          {/* Task Statuses */}
                          <div
                            key={assignee.name}
                            className="max-w-lg rounded-lg flex flex-wrap max-lg:gap-x-3 lg:gap-x-5 gap-y-5 justify-start items-start bg-white p-1 "
                          >
                            {taskStatuses.map((status) => (
                              <div
                                key={status.key}
                                onClick={() => {
                                  setSelectedStatus(status.label); // Use status.label here
                                  setChartVisible(1);
                                }}
                                className="flex flex-col items-center justify-center w-[30%] h-[85px] md:w-24 md:h-24 lg:w-32 lg:h-28 xl:w-36 xl:h-[120px]  text-xs font-semibold text-[#313131] bg-slate-50/35 gap-x-2 border-2 border-gray-200 hover:border-gray-400 hover:bg-slate-50 duration-300 ease-in-out rounded-lg p-1.5 cursor-pointer group"
                              >
                                {/* Task Status Icon */}
                                <div className="p-1 ">
                                  <img
                                    className=" w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-lg group-hover:animate-pulse"
                                    src={status.icon}
                                    alt={status.label}
                                  />
                                </div>

                                {/* Task Count */}
                                <div className="flex flex-col items-center  leading-[6px] justify-center pt-2">
                                  <div className="text-[10px] xl:text-xs">
                                    {status.label}
                                  </div>
                                  <div className="text-sm lg:text-base xl:text-lg p-0 font-bold">
                                    {assignee[status.key] || 0} Tasks
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>
      ) : chartVisible === 1 ? (
        <div className=" w-full h-full p-2">
          <div className="App h-full">
            <EventScheduler
              adminCalender={adminCalender}
              emp={selectedEmployee}
              st={selectedStatus}
              onReload={(data) => {
                setEvents(data);
              }}
            />
          </div>
          <button
            type="submit"
            className="flex duration-300 ease-in-out w-32 justify-center rounded-md bg-[#5ab2ff] max-md:px-2.5 max-md:py-1.5 md:px-3 md:py-2.5 text-xs md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase"
            onClick={() => {
              setChartVisible(0);
            }}
          >
            Go back
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ApexChart;
