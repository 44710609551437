import React from "react";
import "./PLRealStateBroker.css";
import Header from "../../components/Header/Header";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { RiBookmark3Fill } from "react-icons/ri";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Helmet } from "react-helmet";

const PLRealStateBroker = () => {
  return (
    <>
      <Helmet>
        <title>Real State Broker | SCDND Estates</title>
        <meta
          name="description"
          content="In the rapidly changing technology of online advertisement and buyer-seller matching algorithms, traditional agents who spent years nurturing relationships with investors, builders and retail clients are facing unprecedented challenges."
        />
        <meta
          name="description"
          content="The emergence of discount brokerages offering fast App-based trading have disrupted the commission model of traditional brokers but also shifted the dynamics of customer engagement away from research recommendations and investor education."
        />
        <link rel="canonical" href="https://scdndestates.com/realStateBroker" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Real State Broker - SCDND Estates"
        />
        <meta
          property="og:description"
          content="In the rapidly changing technology of online advertisement and buyer-seller matching algorithms, traditional agents who spent years nurturing relationships with investors, builders and retail clients are facing unprecedented challenges."
        />

        <meta property="og:url" content="https://scdndestates.com/realStateBroker" />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Real State Broker - SCDND Estates"
        />
        <meta
          name="twitter:description"
          content="In the rapidly changing technology of online advertisement and buyer-seller matching algorithms, traditional agents who spent years nurturing relationships with investors, builders and retail clients are facing unprecedented challenges."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      <Header />

      <div className="after-header Partnerships-layer flex flex-col items-center max-w-[1800px] mx-auto">
        <p className="w-[90%] mx-auto text-sm lg:text-base ">
          <a href="/" className="hover:underline">
            Home
          </a>{" "}
          {">"}{" "}
          <a href="/partnerships" className="hover:underline">
            Partnership
          </a>{" "}
          {">"}{" "}
          <a href="/realStateBroker ">
            <u>Real State Broker</u>
          </a>
        </p>
        <div className="container-fluid w-full max-w-[90%] mx-auto mt-4  bg-gradient-to-tr from-[#dbedf9] to-[#e7f6ff] pt-5 px-2 lg:p-5 rounded-2xl">
          <div className="flex flex-wrap gap-y-10 gap-x-4 justify-around ">
            {/*       Card - 1     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/LeadGen2.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Lead Generation Challenges
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Lead generation and Networking must be done for both the
                        Seller and Buyer sides.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Streamlining Buyer-Side Lead Generation
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "Lead generation and Networking are required for the buyer side only.",
                          "This reduces our expenditure by half and can be spent on time, manpower and operational cost to generate revenue.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none  text-[#414141] font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 2     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/MeetClient.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Meeting Diverse Client Needs
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Each Client has multiple preferences, like location,
                        vastu, floor, interior design/quality
                        schools/hospitals/markets nearby etc., that must be
                        matched with available stock.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        The Efficiency of Decentralized Assets
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "Decentralized Assets are financial contracts defined by three parameters: risk, return, and liquidity.",
                          "These parameters make it easy to explain and compare with existing investments, making it quick and cost-efficient to match client preferences.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 3     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px] overflow-hidden">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/SiteVisit.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        The Challenge of Excessive <br /> Site Visits
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Several site-visits are required (as each Client
                        explores multiple options before finalizing), which
                        consumes enormous time, fuel and staff expense.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Simplifying Transactions Without Site Visits
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "No site-visits are required.",
                          "The website provides Tutorial videos and FAQs to explain financial products, its features, and advantages. Additionally, the complete ownership value and transaction history are also visible online.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 4     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/GeoCon.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Geographical Constraints
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        {" "}
                        Broker's expertise is concentrated within a specific
                        region, catering to clients seeking properties in that
                        area.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="md:mb-3 text-xl font-bold  text-[#212121] ">
                        The Boundless Market of Decentralized Real Estate Assets
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white">
                        {[
                          "The potential market is unlimited.",
                          "Individuals from any geographical location can trade in Decentralized Assets on the portal, using SCDND or can trade directly with each other.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className=" bg-white">{item}</span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 5     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/Paperwork.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        The Paperwork Maze Challenge
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Real estate deals involve extensive paperwork and due
                        diligence - verifying ownership chains and managing sale
                        deeds & post-sale registrations.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        The Future of Paperless, Hassle-Free Deals
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "Both purchase and redemption process and peer-to-peer trading is entirely online. There is no paperwork or need to comply with municipal or development authority formalities.",
                          "Due diligence is straightforward, transparent, and fully digitized.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 6     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/RealEstExt.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Real Estate's Extended <br /> Sales Cycles
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Frequency of real estate transactions per client is only
                        twice-thrice per lifetime, therefore repeated income
                        from the same customer is possible once every 10-15
                        years.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="md:mb-3  text-xl font-bold  text-[#212121] ">
                        Fungibility creates Upselling Opportunities
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "Maintaining relationships with each client generates regular income based on their value.",
                          "Additionally, clients can be potentially upsold and switched between financial products at relatively short intervals (for earning an onboarding fee).",
                        ].map((item, index) => (
                          <div
                            key={index}
                            className="flex space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className=" bg-white">{item}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
          </div>

          <div className="my-4 text-center pt-3 xl:px-20">
            <p className=" just-content-center px-4 pt-3 leading-7 text-sm md:text-base font-semibold">
              In a world where the real estate industry is rapidly evolving, we
              offer a lifeline to real estate agents who are looking to not just
              survive but thrive. Our digital platform is a game-changer,
              enabling agents to tap into their potential and redefine their
              role in the market.
            </p>
            <p className="mb-3 just-content-center px-4 pt-1 leading-7 text-sm md:text-base font-semibold">
              Don't let your hard-earned market value erode in the face of
              technological advancements. Join us in this exciting journey to
              revolutionize the real estate business and secure your future in
              the industry. Together, we can turn the challenges of today into
              the opportunities of tomorrow.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PLRealStateBroker;
