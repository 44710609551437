import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./EventScheduler.css";
import { IoTrashBin } from "react-icons/io5";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import Loader from "../components/Loaders/Loader1.jsx";
import CheckAuth from "../components/ServiceFunctions/CheckAuth.jsx";
import { MdSecurityUpdateGood } from "react-icons/md";
import AlertYellow from "../components/Alerts/AlertYellow.jsx";
import AlertRed from "../components/Alerts/AlertRed.jsx";
import AlertGreen from "../components/Alerts/AlertGreen.jsx";
import secureLocalStorage from "react-secure-storage";
import { event } from "jquery";

const EventScheduler = ({ adminCalendar, onReload, emp, st }) => {
  const { phone } = CheckAuth();
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [resendStatusTimer, setResendStatusTimer] = useState(90);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState("");
  const [show3, setShow3] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedBlocker, setEditedBlocker] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showTitle, setShowTitle] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [allTitle, setAllTitle] = useState([]);
  const [allPriority, setAllPriority] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [changeRate, setChangeRate] = useState(-1);
  const [isFilterOpen1, setIsFilterOpen1] = useState(false);
  const [assignee, setAssignee] = useState(emp ? emp : "All");
  const [status, setStatus] = useState(st ? st : "All");
  const [priority, setPriority] = useState("All");
  const [generatedRatingOtp, setGeneratedRatingOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [showModal, setShowModal] = useState(-1);
  const [enteredDate, setEnteredDate] = useState(null);
  const [resendRatingTimer, setResendRatingTimer] = useState(90);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const timerRef = useRef(null);
  const [timer, setTimer] = useState(300);
  const [userOtp, setUserOtp] = useState();
  const [otp, setOtp] = useState();
  const [statusSaved, setStatusSaved] = useState(-1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [requestQuote, setRequestQuote] = useState(false);
  const rowsPerPage = 7;

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((prev) => [...prev, ...files]);
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (uploadedFiles.length === 0) {
      alert("Please select files before uploading.");
      return;
    }

    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("uploadedFiles", file);
    });

    formData.append("selectedTitle", selectedEvent.title);

    // Append selectedValidators as query parameters
    const queryParams = new URLSearchParams();
    selectedEvent.validators.forEach((validator) => {
      queryParams.append("selectedEvent.validators[]", validator);
    });

    try {
      const response = await fetch(
        `https://api-scdndestates.com/api/upload_assignee_file/?${queryParams.toString()}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        alert("Files uploaded successfully!");
        setUploadedFiles([]); // Clear selected files after upload
      } else {
        const errorData = await response.json();
        alert(`Upload failed: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Error uploading files.");
    }
  };
  const handleResendStatusTimer = () => {
    setResendStatusTimer(90);

    const timerInterval = setInterval(() => {
      setResendStatusTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  useEffect(() => {
    if (showPopup || show1) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopup, show1]);

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/get-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        setAllEvents(response.events);
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
    setLoader(true);
    const eventsOnDate = allEvents
      .map((event) => ({
        event_date: event.Date,
        title: event.title,
        assignee: event.Assignee,
        desc: event.Description,
        blocker: event.Blocker,
        rating: event.Rating,
        priority: event.Priority,
        status: event.Status,
        budget: event.Budget,
        duration: event.Duration,
        dependency: event.Dependency,
        validators: event.file_validator,
        interrupted: event.Interrupted,
        resumption: event.Resumption,
      }))
      .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));

    setAssignees([...new Set(eventsOnDate.map((event) => event.assignee))]);
    setAllStatus([...new Set(eventsOnDate.map((event) => event.status))]);
    setAllPriority([...new Set(eventsOnDate.map((event) => event.priority))]);
    const filteredEvents = eventsOnDate.filter((event) => {
      const assigneeMatch = assignee === "All" || event.assignee === assignee;
      const statusMatch = status === "All" || event.status === status;
      const priorityMatch = priority === "All" || event.priority === priority;
      const titleMatch =
        allTitle?.includes(event?.title) || allTitle?.length === 0;
      return assigneeMatch && statusMatch && priorityMatch && titleMatch;
    });
    setEvents(filteredEvents);

    // Calculate default page based on today's date or upcoming event

    setLoader(false);
  }, [JSON.stringify(allEvents), assignee, status, priority, selectedStatus]);

  useEffect(() => {
    const today = new Date();
    const firstUpcomingEventIndex = events?.findIndex(
      (event) => new Date(event.event_date) >= today
    );
    if (firstUpcomingEventIndex !== -1) {
      const defaultPage = Math.ceil(
        (firstUpcomingEventIndex + 1) / rowsPerPage
      );
      setCurrentPage(defaultPage);
    } else {
      setCurrentPage(1);
    }
    setLoader(false);
  }, [
    JSON.stringify(allEvents),
    assignee,
    status,
    priority,
    JSON.stringify(allTitle),
  ]);

  const handleDeleteEvent = (val, date) => {
    if (userOtp === otp) {
      setLoader2(true);
      fetch(`https://api-scdndestates.com/api/delete-event-and-reminders/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          val,
          date,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to delete event/reminder");
          }
          return response.json();
        })
        .then((response) => {
          if (response.message === "Success") {
            setShowModal(2);
            setTimeout(() => {
              setShowModal(-1);
              setLoader2(false);
            }, 3000);
            setShow1(false);
            secureLocalStorage.setItem(
              "admin-calendar",
              JSON.stringify(response.dates)
            );
            onReload(response.dates);
            setAllEvents(response.dates);
          }
          setUserOtp("");
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      setShowModal(1);
      setTimeout(() => {
        setShowModal(-1);
      }, 3000);
    }
  };

  const handleEvent = (title, key, value) => {
    fetch(`https://api-scdndestates.com/api/edit-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        date: selectedEvent.event_date,
        key,
        value,
        enteredDate,
        editedBlocker,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message !== "Success") {
          alert(response.message);
        } else {
          setEvents((prevEvents) =>
            prevEvents?.map((event) =>
              event.title === title
                ? { ...event, [key?.toLowerCase()]: value }
                : event
            )
          );
        }
        onReload(response.dates);
        setAllEvents(response.dates);
        setEnteredDate(null);
        setLoader2(false);
        setShow2(false);
        setOtp();
        setUserOtp();
        setShow3(false);
        setIsEditing(false);
        setEditedBlocker(null);
        setStatusSaved(1);
        setTimeout(() => {
          setStatusSaved(-1);
          setSelectedStatus(null);
          setShowPopup2("");
        }, 3000);
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(events?.length / rowsPerPage)) {
      setCurrentPage(page);
    }
  };

  function formattedDate(dateString) {
    const date = new Date(dateString);
    const monthShortName = date.toLocaleString("en-IN", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return `${day}-${monthShortName}-${year}`;
  }

  const renderRows = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return events?.length > 0 ? (
      events?.slice(start, end).map((event, index) =>
        loader ? (
          <div className="relative flex left-[15%] md:left-[42%] md:-translate-[50%]">
            <Loader />
          </div>
        ) : (
          <div
            key={index}
            className={`w-full my-1 flex items-center border-b border-gray-200 hover:bg-slate-100 transition duration-200 cursor-pointer text-[#313131] font-semibold text-sm ${
              isAnimating ? "" : "table-fade-in"
            }`}
          >
            <div className="p-2 w-[100px] xl:w-[11%]">
              <div className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed">
                {formattedDate(event.event_date)}
              </div>
            </div>
            <div
              className="p-2 w-[300px] xl:w-[40%]"
              onClick={() => {
                setSelectedEvent(event);
                setShowPopup(true);
                setChangeRate(-1);
              }}
            >
              <div
                onClick={() => setShowTitle(event.title)}
                className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded ring-2 ring-[#cbefff] focus:outline-none"
              >
                {event?.title?.length > 50
                  ? `${event?.title?.slice(0, 50)}...`
                  : event?.title}
              </div>
            </div>
            <div className="p-2 w-[140px] xl:w-[11.5%]">
              <div
                type="text"
                className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed"
              >
                {event.assignee}
              </div>
            </div>
            <div className="p-2 w-[140px] xl:w-[11.5%]">
              <div
                type="text"
                className="w-full overflow-hidden whitespace-nowrap  bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none"
                onClick={() => {
                  let title = [];
                  Object.values(event?.dependency).map((data, index) => {
                    title.push(data);
                  });
                  setAllTitle(title);
                }}
              >
                {event?.dependency
                  ? Object.entries(event?.dependency)?.length
                  : 0}
              </div>
            </div>
            <div className="p-2 w-[100px] xl:w-[9%]">
              <div className="flex w-full overflow-hidden whitespace-nowrap ">
                <div className="w-4/12 flex items-center justify-center">
                  <div
                    className={`${
                      event.priority === "P0"
                        ? "bg-blue-950"
                        : event.priority === "P1"
                        ? "bg-blue-800"
                        : event.priority === "P2"
                        ? "bg-blue-600"
                        : event.priority === "P3"
                        ? "bg-blue-400"
                        : event.priority === "P4"
                        ? "bg-blue-300"
                        : event.priority === "P5" && "bg-blue-200"
                    } w-3 h-3 rounded-full `}
                  ></div>
                </div>
                <select
                  value={event.priority || "P0"}
                  onChange={(e) =>
                    handleEvent(event.title, "Priority", e.target.value)
                  }
                  className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded  focus:outline-none transition ease-in-out duration-300"
                >
                  <option>P0</option>
                  <option>P1</option>
                  <option>P2</option>
                  <option>P3</option>
                  <option>P4</option>
                  <option>P5</option>
                </select>
              </div>
            </div>
            <div className="p-2 w-[140px] xl:w-[13%]">
              <div
                type="text"
                className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed"
              >
                {event.status}
              </div>
            </div>
            <div className="p-2 w-[50px] xl:w-[3%]">
              <div className="flex items-center justify-center">
                <IoTrashBin
                  onClick={() => {
                    sendDeleteOtp(event.title);
                    setSelectedEvent(event);
                    setTimer(90);
                    handleTimer();
                  }}
                  className="w-5 h-5 cursor-pointer text-red-600 hover:scale-90 duration-300"
                />
              </div>
            </div>
          </div>
        )
      )
    ) : (
      <div className=" text-sm md:text-base">No Events Scheduled yet...</div>
    );
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const handleTimer = () => {
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  //Deadline Calculation
  function convertintoHours(duration) {
    if (!duration) return 0;
    if (duration.includes("hour")) {
      return parseFloat(duration);
    } else if (duration.includes("day")) {
      return parseFloat(duration) * 8;
    } else if (duration.includes("week")) {
      return parseFloat(duration) * 5 * 8;
    }

    return 0; // Default case for unexpected inputs
  }

  function isNonWorkingDay(date, holidays) {
    const dateString = date.toISOString().split("T")[0];
    return (
      holidays.has(dateString) || date.getDay() === 0 || date.getDay() === 6
    );
  }

  function moveToNextWorkingDay(date, holidays) {
    while (isNonWorkingDay(date, holidays)) {
      date.setDate(date.getDate() + 1);
      date.setHours(10, 30, 0, 0);
    }
  }

  function calculateLostWorkHours(interruptedDate, resumptionDate, holidays) {
    if (
      !interruptedDate ||
      !resumptionDate ||
      interruptedDate >= resumptionDate
    )
      return 0;

    const workStartHour = 10.5; // 10:30 AM
    const workEndHour = 18.5; // 6:30 PM
    const workHoursPerDay = workEndHour - workStartHour;

    let lostHours = 0;
    let currentDate = new Date(interruptedDate);

    // Move to the next working day if necessary
    moveToNextWorkingDay(currentDate, holidays);
    moveToNextWorkingDay(resumptionDate, holidays);

    while (currentDate < resumptionDate) {
      if (!isNonWorkingDay(currentDate, holidays)) {
        let currentHour =
          currentDate.getHours() + currentDate.getMinutes() / 60;
        let dayEnd = new Date(currentDate);
        dayEnd.setHours(workEndHour, 0, 0, 0);

        if (currentHour < workStartHour) {
          currentDate.setHours(workStartHour, 0, 0, 0);
          currentHour = workStartHour;
        }

        if (currentDate >= resumptionDate) break;

        let availableHours = Math.min(
          (dayEnd - currentDate) / (1000 * 60 * 60),
          workHoursPerDay
        );

        // If the resumption date is within today’s work hours, limit the count
        if (currentDate.toDateString() === resumptionDate.toDateString()) {
          let resumptionHour =
            resumptionDate.getHours() + resumptionDate.getMinutes() / 60;
          availableHours = Math.max(0, resumptionHour - currentHour);
        }

        lostHours += availableHours;
      }

      // Move to the next day at 10:30 AM
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(workStartHour, 0, 0, 0);
    }

    return lostHours;
  }

  function adjustEventDate(eventDate, holidays) {
    const workStartHour = 10.5;
    const workEndHour = 18.5;
    if (eventDate.getHours() + eventDate.getMinutes() / 60 < workStartHour) {
      eventDate.setHours(10, 30, 0, 0);
    } else if (
      eventDate.getHours() + eventDate.getMinutes() / 60 >
      workEndHour
    ) {
      eventDate.setDate(eventDate.getDate() + 1);
      eventDate.setHours(10, 30, 0, 0);
    }
    while (isNonWorkingDay(eventDate, holidays)) {
      eventDate.setDate(eventDate.getDate() + 1);
      eventDate.setHours(10, 30, 0, 0);
    }
    return eventDate;
  }

  function calculateDeadline(event, adminCalendar) {
    // Calculate all holidays including Saturday and Sunday
    const holidays = new Set();
    Object.values(adminCalendar || {}).forEach((data) => {
      if (data.National_holidays) {
        Object.keys(data.National_holidays).forEach((val) => {
          holidays.add(val);
        });
      }
    });

    // Adjust event start date
    let eventDate = enteredDate
      ? new Date(enteredDate)
      : new Date(event.event_date);
    // If event date or duration is null, then return no Deadline
    if (!eventDate || !event?.duration) return null;
    eventDate = adjustEventDate(eventDate, holidays);
    let currentDate = new Date(eventDate);

    // Calculate duration in hours
    let remainingHours = event?.duration
      ? convertintoHours(event.duration)
      : null;

    // Calculate postponed hours
    const interruptedDate = event?.interrupted
      ? new Date(event.interrupted)
      : null;
    const resumptionDate = event?.resumption
      ? new Date(event.resumption)
      : null;
    const postponedHours = calculateLostWorkHours(
      interruptedDate,
      resumptionDate,
      holidays
    );

    // Total hours left to work on
    remainingHours += postponedHours;

    const workStartHour = 10.5;
    const workEndHour = 18.5;

    while (remainingHours > 0) {
      // Skip non-working days (weekends and holidays)
      while (isNonWorkingDay(currentDate, holidays)) {
        currentDate.setDate(currentDate.getDate() + 1); // Move to next day
        currentDate.setHours(10, 30, 0, 0); // Start at 10:30 AM
      }

      // Calculate available work hours for the current day
      const currentHour =
        currentDate.getHours() + currentDate.getMinutes() / 60;
      let availableHours = workEndHour - currentHour;

      if (availableHours >= remainingHours) {
        // If today's available hours are enough
        currentDate.setHours(
          currentDate.getHours() + remainingHours,
          currentDate.getMinutes(),
          0,
          0
        );
        remainingHours = 0;
      } else {
        // Use full available time and move to the next day
        remainingHours -= availableHours;
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(10, 30, 0, 0); // Reset to 10:30 AM
      }
    }

    // Format the final deadline date as dd-mmm-yyyy, hh:mm
    const formattedDate = formatDate(currentDate);
    return formattedDate;
  }

  function formatDate(date) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensuring two digits for minutes

    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  }
  //Deadline Calculation

  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
  // use effect for filter close
  const filterRef = useRef(null); // Use a ref to reference the filter dropdown

  const handleResendRatingTimer = () => {
    setResendRatingTimer(90);

    const timerInterval = setInterval(() => {
      setResendRatingTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const sendRatingOtp = () => {
    if (phone.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-rating-otp/`, {
        method: "POST",
        body: JSON.stringify({
          title: selectedEvent.title,
          assignee: selectedEvent.assignee,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else if (response.error === "task not completed") {
            setShowModal(5);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else {
            setGeneratedRatingOtp(response.otp);
            handleResendRatingTimer();
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const sendDeleteOtp = (val) => {
    if (phone?.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-delete-otp/`, {
        method: "POST",
        body: JSON.stringify({
          title: val,
          phone: "91" + phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else if (response.error === "task not completed") {
            setShowModal(5);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else {
            setOtp(response.otp);
            setShow1(true);
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const sendStatusOtp = () => {
    setShowModal(6);
    setTimeout(() => {
      setShowModal(-1);
    }, 3000);
    if (phone?.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-duration-otp/`, {
        method: "POST",
        body: JSON.stringify({
          phone: "91" + phone,
          title: selectedEvent.title,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else {
            setOtp(response.otp);
            setShow2(true);
            setTimer(90);
            handleTimer();
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const verifyGeneratedOtp = (type, userOtp) => {
    const otp = type === "rating" && generatedRatingOtp;
    if (otp === userOtp) {
      handleEvent(selectedEvent.title, "Rating", selectedRate);
      setChangeRate(1);
      setTimeout(() => {
        setChangeRate(-1);
        setGeneratedRatingOtp("");
      }, 5000);
    } else {
      setShowModal(1);
    }
    setTimeout(() => {
      setShowModal(-1);
    }, 5000);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [options, setOptions] = useState([
    { daysRequired: "", budget: "", reason: "" },
    { daysRequired: "", budget: "", reason: "" },
    { daysRequired: "", budget: "", reason: "" },
  ]);

  const handleInputChange = (index, e) => {
    const newOptions = [...options];
    newOptions[index][e.target.name] = e.target.value;
    setOptions(newOptions);
  };

  const handleCheckboxChange = (index, e) => {
    const { checked } = e.target;
    setOptions((prev) =>
      prev.map((opt, i) => (i === index ? { ...opt, selected: checked } : opt))
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    setEditedBlocker(selectedEvent?.blocker || null);
    setEnteredDate(selectedEvent?.resumption || null);
  }, [JSON.stringify(selectedEvent)]);

  function checkDeadlineStatus(event, adminCalendar) {
    const deadlineStr = calculateDeadline(event, adminCalendar);
    const deadline = new Date(deadlineStr);
    const now = new Date();
    const timeDiff = deadline - now;

    const hoursBeforeDeadline = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutesBeforeDeadline = Math.floor(
      (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
    );
    if (now > deadline) {
      return "You have missed the Deadline of this Task as set by the Admin. Please enter the reason in the Blocker.";
    } else {
      return `You have finished this Task ${hoursBeforeDeadline} hours and ${minutesBeforeDeadline} minutes before Deadline.`;
    }
  }

  const verifyStatusOtp = () => {
    setLoader2(true);
    console.log(showPopup2, enteredDate, message, editedBlocker);
    if (showPopup2 === "Postponed" || showPopup2 === "Not Started") {
      if (enteredDate !== null) {
        if (
          message ===
          "You have missed the Deadline of this Task as set by the Admin. Please enter the reason in the Blocker."
        ) {
          if (editedBlocker?.trim() !== "" || editedBlocker !== null) {
            setMessage(checkDeadlineStatus(selectedEvent, adminCalendar));
            setShowModal(6);
            setTimeout(() => {
              setShowModal(-1);
            }, 3000);
            sendStatusOtp();
          } else {
            setShowModal(7);
            setTimeout(() => {
              setShowModal(-1);
            }, 3000);
            setLoader2(false);
            setShow3(true);
            setIsEditing(true);
          }
        } else {
          setMessage(checkDeadlineStatus(selectedEvent, adminCalendar));
          setShowModal(6);
          setTimeout(() => {
            setShowModal(-1);
          }, 3000);
          sendStatusOtp();
        }
      } else {
        setShowModal(8);
        setTimeout(() => {
          setShowModal(-1);
        }, 3000);
        setLoader2(false);
      }
    } else {
      if (
        message ===
        "You have missed the Deadline of this Task as set by the Admin. Please enter the reason in the Blocker."
      ) {
        if (editedBlocker?.trim() !== "" || editedBlocker !== null) {
          setMessage(checkDeadlineStatus(selectedEvent, adminCalendar));
          setShowModal(6);
          setTimeout(() => {
            setShowModal(-1);
          }, 3000);
          sendStatusOtp();
        } else {
          setShowModal(7);
          setTimeout(() => {
            setShowModal(-1);
          }, 3000);
          setLoader2(false);
          setShow3(true);
          setIsEditing(true);
        }
      } else {
        setMessage(checkDeadlineStatus(selectedEvent, adminCalendar));
        setShowModal(6);
        setTimeout(() => {
          setShowModal(-1);
        }, 3000);
        sendStatusOtp();
      }
    }
  };

  useEffect(() => {
    if (statusSaved === 2 || statusSaved === 3) {
      setMessage(checkDeadlineStatus(selectedEvent, adminCalendar));
      verifyStatusOtp();
    }
  }, [statusSaved]);

  return (
    <div className="w-full overflow-hidden h-full mx-auto  rounded-lg relative">
      {/* Title and Pagination Control */}
      <div
        className={`flex justify-between duration-500 ease-in-out transition-all bg-sky-600 rounded-t-lg items-center py-2.5 px-2 md:px-3 ${
          showPopup ? "opacity-20" : "opacity-100"
        }`}
      >
        <h1 className="text-base md:text-lg font-bold text-[#ffffff]">
          Sprint
        </h1>
        <div className="flex gap-5 items-center relative">
          {/* {label === "Admin" && ( */}
          <div ref={filterRef} className="relative   max-md:hidden">
            {/* Increased z-index here */}
            <button
              onClick={toggleFilter}
              className="flex bg-sky-100 hover:bg-[#BAE6FC] gap-1 pl-1 py-1 pr-2 rounded text-sm font-semibold items-center hover:scale-95 duration-300"
            >
              <span className="rotate-90">
                <svg
                  id="fi_9702724"
                  className="w-4 h-4 text-black"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                >
                  <g
                    id="layer1"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="4.1"
                    transform="translate(-300 -100)"
                  >
                    <path
                      id="path6012"
                      d="m321 106.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-13.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h13.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h3.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-3.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                    <path
                      id="path6014"
                      d="m311 116.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-3.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h3.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h13.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-13.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                  </g>
                </svg>
              </span>
              Filter
            </button>
            {isFilterOpen && (
              <div className="alert-animation absolute right-0 z-[50] mt-2 w-full bg-white border border-gray-200 rounded-md shadow-lg min-w-[200px] ">
                <div className="p-2.5 space-y-3 z-20">
                  {/* Assignee Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Assignee
                    </label>
                    <select
                      value={assignee}
                      onChange={(e) => {
                        setAssignee(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                        setCurrentPage(1);
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {assignees.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Status Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Status
                    </label>
                    <select
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                        setCurrentPage(1);
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {allStatus.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Priority Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Priority
                    </label>
                    <select
                      value={priority}
                      onChange={(e) => {
                        setPriority(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                        setCurrentPage(1);
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {allPriority
                        .sort((a, b) => {
                          const numA = parseInt(a.substring(1), 10);
                          const numB = parseInt(b.substring(1), 10);
                          return numA - numB;
                        })
                        .map((a) => (
                          <option key={a} value={a}>
                            {a}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* )} */}
          {/* Side bar */}
          <div className="md:hidden pl-[120px]">
            {/* Increased z-index here */}
            <button
              onClick={() => setIsFilterOpen1(true)}
              className="flex bg-sky-100 hover:bg-[#BAE6FC] gap-1 p-1 rounded text-sm font-semibold items-center hover:scale-95 duration-300"
            >
              <span className="rotate-90">
                <svg
                  id="fi_9702724"
                  className="w-4 h-4 text-black"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                >
                  <g
                    id="layer1"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="4.1"
                    transform="translate(-300 -100)"
                  >
                    <path
                      id="path6012"
                      d="m321 106.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-13.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h13.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h3.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-3.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                    <path
                      id="path6014"
                      d="m311 116.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-3.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h3.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h13.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-13.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
          <div className="max-md:hidden md:flex space-x-4 items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
                currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Previous
            </button>
            <span className="text-[#ffffff] text-sm">
              <span className="font-semibold">Page {currentPage}</span> of{" "}
              {Math.ceil(events?.length / rowsPerPage)}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(events?.length / rowsPerPage)}
              className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
                currentPage === Math.ceil(events?.length / rowsPerPage)
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              Next
            </button>
          </div>
        </div>
        <div className="max-md:flex md:hidden space-x-4 items-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <MdSkipPrevious />
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(events?.length / rowsPerPage)}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === Math.ceil(events?.length / rowsPerPage)
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            <MdSkipNext />
          </button>
        </div>
      </div>

      {/* Event Table */}
      <div
        className={`w-full h-[600px] duration-500 ease-in-out transition-all overflow-scroll bg-white ${
          showPopup ? "opacity-20" : "opacity-100"
        }`}
      >
        <div className="min-w-[990px] w-full h-[500px] ">
          <div className="bg-sky-100 text-[#212121] flex text-sm uppercase leading-normal">
            <div className="p-2.5 text-left font-bold w-[100px] xl:w-[11%]">
              Date
            </div>
            <div className="p-2.5 text-left font-bold w-[300px] xl:w-[40%]">
              Task
            </div>
            <div className="p-2.5 text-left font-bold w-[140px] xl:w-[11.5%]">
              Assignee
            </div>
            <div className="p-2.5 text-left font-bold w-[140px] xl:w-[11.5%]">
              Dependency
            </div>
            <div className="p-2.5 text-left font-bold w-[100px] xl:w-[9%]">
              Priority
            </div>
            <div className="p-2.5 text-left font-bold w-[140px] xl:w-[13%]">
              Status
            </div>
            <div className="flex items-center justify-center  text-red-600 w-[50px] xl:w-[3%]"></div>
          </div>
          <div className={`${isAnimating ? "" : "table-fade-in"}`}>
            {renderRows()}
          </div>
        </div>
      </div>

      {/* Side bar option */}
      <div
        className={`md:hidden absolute -left-6 -top-8 h-screen z-[5] mt-2 w-full bg-white border border-gray-200 rounded-md  max-w-[260px] transition-transform duration-500 ease-in-out ${
          isFilterOpen1 ? "translate-x-0 shadow-lg" : "-translate-x-full"
        }`}
      >
        <div className="p-4 space-y-3 z-20">
          <div className="flex items-center justify-between">
            <p className="text-[15px] font-bold text-black">Filter</p>

            <div
              onClick={() => setIsFilterOpen1(false)}
              className=" flex justify-end p-0"
            >
              <button
                className="circleadmin"
                data-animation="showShadow"
                data-remove="3000"
              ></button>
            </div>
          </div>
          <hr />
          {/* Assignee Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Assignee
            </label>
            <select
              value={assignee}
              onChange={(e) => {
                setAssignee(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen1(false);
                }
                setCurrentPage(1);
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {assignees.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>

          {/* Status Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Status
            </label>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen1(false);
                }
                setCurrentPage(1);
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {allStatus.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>

          {/* Priority Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Priority
            </label>
            <select
              value={priority}
              onChange={(e) => {
                setPriority(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen(false);
                }
                setCurrentPage(1);
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {allPriority
                .sort((a, b) => {
                  const numA = parseInt(a.substring(1), 10);
                  const numB = parseInt(b.substring(1), 10);
                  return numA - numB;
                })
                .map((a) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      {/* Popup */}
      <div
        className={`bg-white absolute h-[600px] overflow-y-hidden top-0 right-0 transition-all duration-1000 ease-in-out rounded-lg w-[100%] border-2 ${
          showPopup ? "translate-x-[0%]" : "translate-x-[105%]"
        }`}
      >
        <div className="h-full overflow-scroll">
          <div className="flex justify-between px-4 py-2 border-b-2 shadow-md">
            {" "}
            <h2 className="text-base md:text-lg font-semibold text-black flex items-center gap-2">
              <span className="">
                <svg
                  id="fi_18401969"
                  className="w-6 h-6"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                >
                  <path d="m2.55 13.33v43.18a5.5 5.5 0 0 0 5.5 5.49h47.9a5.5 5.5 0 0 0 5.5-5.49v-43.18a5.5 5.5 0 0 0 -5.5-5.49h-2.8v-1.47a4.4 4.4 0 0 0 -1.29-3.09 4.363 4.363 0 0 0 -7.45 3.09v1.47h-24.82v-1.47a4.37 4.37 0 1 0 -8.74 0v1.47h-2.8a5.5 5.5 0 0 0 -5.5 5.49zm43.86-6.96a2.375 2.375 0 0 1 2.37-2.37 2.338 2.338 0 0 1 1.66.69 2.372 2.372 0 0 1 .71 1.68v5.97a2.37 2.37 0 1 1 -4.74 0zm-33.56 0a2.369 2.369 0 0 1 4.04-1.68 2.4 2.4 0 0 1 .7 1.68v5.97a2.37 2.37 0 1 1 -4.74 0zm-8.3 17.02h54.9v33.12a3.5 3.5 0 0 1 -3.5 3.49h-47.9a3.5 3.5 0 0 1 -3.5-3.49z"></path>
                  <path d="m15.78 34.8a5.15 5.15 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.3a3.15 3.15 0 1 1 -3.16 3.15 3.156 3.156 0 0 1 3.16-3.15z"></path>
                  <path d="m32 34.8a5.15 5.15 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.15 3.15 0 0 1 3.15-3.15z"></path>
                  <path d="m48.22 34.8a5.15 5.15 0 1 0 -5.15-5.15 5.158 5.158 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.158 3.158 0 0 1 3.15-3.15z"></path>
                  <path d="m15.78 46.62a5.155 5.155 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.31a3.155 3.155 0 1 1 -3.16 3.16 3.165 3.165 0 0 1 3.16-3.16z"></path>
                  <path d="m32 46.62a5.155 5.155 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.31a3.155 3.155 0 1 1 -3.15 3.16 3.158 3.158 0 0 1 3.15-3.16z"></path>
                  <path d="m48.22 46.62a5.155 5.155 0 1 0 -5.15-5.15 5.158 5.158 0 0 0 5.15 5.15zm0-8.31a3.155 3.155 0 1 1 -3.15 3.16 3.167 3.167 0 0 1 3.15-3.16z"></path>
                  <path d="m15.78 58.43a5.15 5.15 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.3a3.15 3.15 0 1 1 -3.16 3.15 3.156 3.156 0 0 1 3.16-3.15z"></path>
                  <path d="m32 58.43a5.15 5.15 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.15 3.15 0 0 1 3.15-3.15z"></path>
                </svg>
              </span>
              Event Details
            </h2>
            <div
              onClick={() => closePopup(false)}
              className=" flex justify-end  p-0 m-0 rounded-full"
            >
              <button
                className="circleadmin bg-white"
                data-animation="showShadow"
                data-remove="3000"
              ></button>
            </div>
          </div>
          <div className="p-3 pt-2  bg-white text-sm md:text-base rounded-b-lg">
            <div className="flex flex-col mb-2">
              <span className="font-semibold text-black">Title:</span>
              <span className="border rounded p-1 font-medium ">
                {showTitle}
              </span>
            </div>
            <div className="mb-2">
              <span className="font-semibold text-black">Description:</span>
              <textarea
                className="w-full p-2 border border-gray-300 leading-5 rounded min-h-[80px]"
                defaultValue={selectedEvent.desc}
                placeholder="Description text here..."
                rows="7"
                readOnly
              />
            </div>
            <div>
              <div className="w-full flex items-center justify-between mb-1">
                <div className="flex font-semibold text-black items-center">
                  Blockers{" "}
                  {show3 ? (
                    <>
                      (Enter a Reason in Blocker to delay the Task)
                      <span className="text-sm font-normal text-red-500">
                        **
                      </span>
                    </>
                  ) : (
                    <>(if any)</>
                  )}
                  :
                </div>

                <div className="flex justify-end mt-1">
                  {isEditing ? (
                    <button
                      className="px-2 py-1 bg-sky-400 text-white text-sm rounded hover:bg-sky-500 duration-300"
                      onClick={(e) => {
                        if (
                          editedBlocker?.trim() !== "" ||
                          editedBlocker?.trim() !== null
                        ) {
                          setIsEditing(false);
                          setStatusSaved(2);
                          handleEvent(
                            selectedEvent.title,
                            "Blocker",
                            editedBlocker
                          );
                        }
                      }}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="px-2 py-1 bg-sky-400 text-white font-semibold text-sm rounded hover:bg-sky-500 duration-300"
                      onClick={(e) => {
                        setIsEditing(true);
                        setEditedBlocker(selectedEvent?.blocker || null);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>
              <textarea
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Why this project could not be finished in time..."
                value={editedBlocker} // Show the editing state value
                onChange={(e) => setEditedBlocker(e.target.value)} // Update the editedBlocker state
                rows="3"
                readOnly={!isEditing}
              />
            </div>

            {/* Display Selected Files */}
            <div className="mt-3">
              {uploadedFiles.length > 0 && (
                <ul className="border p-2 rounded-lg bg-gray-100">
                  {uploadedFiles.map((file, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center py-1"
                    >
                      <span className="text-sm">{file.name}</span>
                      <button
                        onClick={() => handleRemoveFile(index)}
                        className="text-red-500 text-xs font-bold"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* File Upload Section */}
            {selectedEvent.validators?.length > 0 && (
              <div className="mt-4">
                <input
                  type="file"
                  accept={selectedEvent.validators}
                  multiple
                  onChange={handleFileChange}
                  className="border p-2 rounded-lg w-full"
                />

                <span>
                  Please attach an{" "}
                  {selectedEvent.validators?.map((file) => `${file}, `)} file as
                  requested by the WTD.
                </span>
                {uploadedFiles?.length > 0 && (
                  <ul className="mt-2">
                    {uploadedFiles?.map((file, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center text-sm text-gray-700"
                      >
                        {file.name}
                        <button
                          onClick={() => handleRemoveFile(index)}
                          className="ml-2 text-red-500 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                <button
                  onClick={handleUpload}
                  className="mt-2 bg-blue-500 text-white p-2 rounded-lg"
                >
                  Upload
                </button>
              </div>
            )}

            {/* otp */}
            <div className="flex justify-between items-start mb-1 mt-2 gap-2">
              <div className="w-[33%]">
                <span className="font-semibold text-black flex">
                  Deadline:{" "}
                </span>
                <div className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300">
                  {calculateDeadline(selectedEvent, adminCalendar)
                    ? calculateDeadline(selectedEvent, adminCalendar)
                    : "No Deadline"}{" "}
                  ({selectedEvent?.duration})
                </div>
              </div>

              <div className="w-[33%]">
                <span className="font-semibold text-black flex">Budget: </span>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600">
                    ₹
                  </span>
                  <input
                    type="text"
                    value={selectedEvent.budget || 0}
                    className="w-full bg-gray-50 p-1.5 pl-7 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                    placeholder="Enter Budget"
                  />
                </div>
              </div>

              <div className="w-[33%]">
                <div className="font-semibold text-black">Rating:</div>
                <select
                  value={selectedEvent.rating || "Select"}
                  onChange={(e) => {
                    setSelectedRate(e.target.value);
                    setChangeRate(0);
                  }}
                  className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                >
                  <option value="Select">Select</option>
                  <option value="Exceeds Expectation">
                    Exceeds Expectation
                  </option>
                  <option value="Meets Expectation">Meets Expectation</option>
                  <option value="Not Meeting Expectation">
                    Not Meeting Expectation
                  </option>
                </select>

                {changeRate !== -1 &&
                  (generatedRatingOtp !== "" ? (
                    <div className="w-[49%] mt-2">
                      <div className="font-semibold text-black">Enter OTP:</div>
                      <div className=" w-full flex items-center gap-2 justify-between">
                        <input
                          className="w-full p-1.5 border border-gray-300 rounded"
                          placeholder="12345"
                          onChange={(e) => {
                            if (e.target.value.length === 5) {
                              verifyGeneratedOtp("rating", e.target.value);
                            }
                          }}
                          disabled={changeRate === 1}
                          maxLength={5}
                        />
                        {changeRate === 1 ? (
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                        ) : (
                          <>
                            {resendRatingTimer === 0 ? (
                              <div
                                className=" cursor-pointer font-semibold underline underline-offset-2 text-xs md:text-sm text-[#5ab2ff] hover:text-[#3aa0ff]"
                                onClick={() => {
                                  sendRatingOtp();
                                }}
                              >
                                Resend
                              </div>
                            ) : (
                              <div className="font-normal text-sm text-[#5ab2ff] hover:text-[#3aa0ff]">
                                {resendRatingTimer}s
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <button
                        className="w-[49%] mt-2 px-2 py-2 bg-sky-400 text-white font-semibold text-sm rounded hover:bg-sky-500 duration-300"
                        onClick={sendRatingOtp}
                      >
                        Send OTP
                      </button>
                    </>
                  ))}
              </div>
            </div>
            <div className="flex justify-between items-start mb-1 mt-2 gap-2">
              <div className="w-[33%]">
                <div className="font-semibold text-black">Status:</div>
                <select
                  value={selectedEvent.status || "Not Started"}
                  onChange={(e) => {
                    setShowPopup2(selectedEvent.status);
                    setSelectedStatus(e.target.value);
                    setSelectedEvent((prevEvents) => ({
                      ...prevEvents,
                      status: e.target.value,
                    }));
                    if (e.target.value === "Completed") {
                      setMessage(
                        checkDeadlineStatus(selectedEvent, adminCalendar)
                      );
                      verifyStatusOtp();
                    } else {
                      handleEvent(
                        selectedEvent.title,
                        "Status",
                        e.target.value
                      );
                    }
                  }}
                  className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                >
                  <option disabled>Not Started</option>
                  <option>In Progress</option>
                  <option>Defective</option>
                  <option disabled>Pending</option>
                  <option>Postponed</option>
                  <option>Completed</option>
                  <option disabled>Overdue</option>
                </select>

                {selectedStatus === "Completed" && (
                  <div className="w-[49%] mt-2">
                    <div className="font-semibold text-black">Enter OTP:</div>
                    <div className=" w-full flex items-center gap-2 justify-between">
                      <input
                        className="w-full p-1.5 border border-gray-300 rounded"
                        placeholder="12345"
                        onChange={(e) => {
                          if (e.target.value.length === 5) {
                            setUserOtp(e.target.value);
                            if (otp === e.target.value) {
                              handleEvent(
                                selectedEvent.title,
                                "Status",
                                selectedStatus
                              );
                            } else {
                              setShowModal(1);
                              setTimeout(() => {
                                setShowModal(-1);
                              }, 3000);
                              setLoader2(false);
                              setStatusSaved(0);
                            }
                          }
                        }}
                        disabled={otp === userOtp}
                        maxLength={5}
                      />
                      {statusSaved === 1 ? (
                        <svg
                          width="30px"
                          height="30px"
                          viewBox="-5 -5 93 93"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <circle
                              id="oval"
                              stroke="#43ac6a"
                              strokeWidth="9"
                              fill="transparent"
                              cx="41.5"
                              cy="41.5"
                              r="41.5"
                            ></circle>
                            <polygon
                              id="tick"
                              fill="#43ac6a"
                              points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                            ></polygon>
                          </g>
                        </svg>
                      ) : (
                        <>
                          {timer === 0 ? (
                            <div
                              className=" cursor-pointer font-semibold underline underline-offset-2 text-xs md:text-sm text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={() => {
                                sendStatusOtp();
                                setTimer(90);
                                handleTimer();
                              }}
                            >
                              Resend
                            </div>
                          ) : (
                            <div className="font-normal text-sm text-[#5ab2ff] hover:text-[#3aa0ff]">
                              {formatTime(timer)}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {(showPopup2 === "Postponed" || showPopup2 === "Not Started") &&
                selectedStatus === "Completed" && (
                  <div className="w-[33%]">
                    <div className="font-semibold text-black">
                      {showPopup2 === "Postponed" ? "Resumption" : "Start"}{" "}
                      date:
                    </div>
                    <input
                      className="w-full bg-gray-50 p-1.5 pl-7 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                      onChange={(e) => setEnteredDate(e.target.value)}
                      onBlur={() => {
                        setStatusSaved(3);
                      }}
                      max={new Date()}
                      type="datetime-local"
                    />
                  </div>
                )}
            </div>
            <div className="w-full ">
              {/* Toggle Button */}
              <div className="flex items-center justify-start gap-x-3 mt-4 mb-4">
                <label className="text-base font-semibold text-[#212121]">
                  Submit Findings
                </label>
                <button
                  className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors ${
                    requestQuote ? "bg-blue-500" : "bg-gray-300"
                  }`}
                  onClick={() => setRequestQuote(!requestQuote)}
                >
                  <div
                    className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-transform ${
                      requestQuote ? "translate-x-6" : ""
                    }`}
                  ></div>
                </button>
              </div>

              {/* Quote Form (Only visible when requestQuote is true) */}
              {requestQuote && (
                <div className=" rounded-lg ">
                  {options.map((option, index) => (
                    <div
                      key={index}
                      className={`flex gap-4 items-center mb-1 transition-colors duration-300 ease-in-out
    ${option.selected ? "text-blue-400" : "text-gray-700"}`}
                    >
                      {/* No. of Days Required */}
                      <div className="w-[32%]">
                        <span className="font-semibold text-black flex">
                          Work Duration:{" "}
                        </span>
                        <select
                          value={option.daysRequired}
                          onChange={(e) => handleInputChange(index, e)}
                          className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                        >
                          <option value="Select">Select</option>
                          <option value="2 hours">2 hours</option>
                          <option value="4 hours">4 hours</option>
                          <option value="1 day">1 day</option>
                          <option value="1.5 day">1.5 day</option>
                          <option value="2 days">2 days</option>
                          <option value="3 days">3 days</option>
                          <option value="4 days">4 days</option>
                          <option value="1 week">1 week</option>
                          <option value="2 weeks">2 weeks</option>
                        </select>
                      </div>

                      {/* Budget */}
                      <div className="w-[32%]">
                        <span className="font-semibold text-black flex">
                          Budget:{" "}
                        </span>
                        <div className="relative">
                          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600">
                            ₹
                          </span>
                          <input
                            type="text"
                            className="w-full bg-gray-50 p-1.5 pl-7 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                            placeholder="Enter Budget"
                            value={option.budget}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </div>
                      </div>

                      {/* Reason */}
                      <div className="flex-1">
                        <label className="block  font-semibold">Reason</label>
                        <input
                          type="text"
                          name="reason"
                          value={option.reason}
                          onChange={(e) => handleInputChange(index, e)}
                          placeholder="Enter Reason"
                          className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                        />
                      </div>

                      {/* Checkbox for Selection */}
                      <div className="flex items-center pt-4">
                        <label class="text-white">
                          <input
                            class="transition-all cursor-pointer duration-500 rounded-xl ease-in-out w-5 h-5"
                            type="checkbox"
                            name="selected"
                            checked={option.selected || false}
                            onChange={(e) => handleCheckboxChange(index, e)}
                          />
                        </label>
                      </div>
                    </div>
                  ))}

                  {/* Add Option Button (Max 3) */}
                  <div className="flex items-center justify-center mt-4">
                    {/* Submit Button */}
                    <button className="w-[49%] bg-blue-500 text-white py-2 rounded-lg  hover:bg-blue-600 transition">
                      Submit Request
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div>
      )} */}
      {show1 && (
        <div className="demo1-container ">
          <div className="bg-white w-[95%] p-3 rounded-xl pop-up-animation">
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="circle fromMiddle scale-90 left-2"
                data-animation="magnify"
                data-remove="3000"
                onClick={() => {
                  setShow1(false);
                  setUserOtp("");
                }}
              >
                <span></span>
              </button>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center p-3">
              <MdSecurityUpdateGood
                size={100}
                color="#fff"
                className="otp-icon"
              />
              <h6 className="text-center mt-4 font-semibold text-[#000000]">
                Are you sure you want to permanently delete this task{" "}
                <span className="font-bold text-blue-500">
                  {`${selectedEvent.title.slice(0, 50)}...`}
                </span>{" "}
                dated{" "}
                <span className="font-bold text-blue-500">
                  {formattedDate(selectedEvent.event_date)}
                </span>
              </h6>
              <h6 className="text-sm text-center font-medium pb-2 mt-2">
                A code has been sent to xxxxxx{phone?.slice(-4)}
              </h6>
              <div
                id="otp"
                className="inputs d-flex flex-row justify-content-center mt-2 "
              >
                <input
                  className=" text-center border-2 rounded p-1.5"
                  type="number"
                  placeholder="Enter OTP"
                  value={userOtp}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setUserOtp(value);
                    }
                  }}
                />
              </div>
              <div className="mt-4 flex items-center gap-4">
                {loader2 ? (
                  <button className="btn btn-sm btn-primary w-[110px] flex justify-center items-center">
                    <img
                      key="1"
                      className="h-4 remove-white-bg"
                      src="/images/btn-transparent.gif"
                      alt={""}
                      autoPlay
                      loop
                      muted
                    />
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-primary w-[110px]"
                    onClick={() =>
                      handleDeleteEvent(
                        selectedEvent.title,
                        selectedEvent.event_date
                      )
                    }
                  >
                    Verify OTP
                  </button>
                )}

                {timer === 0 ? (
                  <button
                    className="  btn-sm rounded border w-[110px]"
                    onClick={() => {
                      sendDeleteOtp(selectedEvent.title);
                      setTimer(90);
                      handleTimer();
                    }}
                  >
                    Resend OTP
                  </button>
                ) : (
                  <button className="btn-sm rounded border w-[110px]">
                    <span className="text-xs">{formatTime(timer)}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal === 1 ? (
        <AlertYellow
          message="Incorrect OTP!"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 2 ? (
        <AlertGreen
          message="OTP is verified."
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 3 ? (
        <AlertYellow
          message="Mobile number must be of 10 digits"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 4 ? (
        <AlertRed
          message="Mobile number provided is not Registered"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 5 ? (
        <AlertRed
          message="Your task is not completed yet."
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 6 ? (
        <AlertRed message={message} onClose={() => setShowModal(-1)} />
      ) : showModal === 7 ? (
        <AlertRed
          message="Blocker is Compulsory."
          onClose={() => setShowModal(-1)}
        />
      ) : (
        showModal === 8 && (
          <AlertRed
            message="Date is Compulsory."
            onClose={() => setShowModal(-1)}
          />
        )
      )}
    </div>
  );
};

export default EventScheduler;
