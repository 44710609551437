import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Header from "../../components/Header/Header";
import { IoIosMail } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import "./Contactus1.css";
import AlertYellow from "../../components/Alerts/AlertYellow.jsx";
import AlertGreen from "../../components/Alerts/AlertGreen.jsx";
import { RiAdminFill } from "react-icons/ri";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [check, setCheck] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  // connect with firebase
  // const submitData = (e) => {
  //   e.preventDefault(); // Prevents default form submission
  //   if (phone.length !== 10) {
  //     setShowModal1(true);
  //     setShowModal2(false);
  //     setShowModal3(false);
  //     setTimeout(() => {
  //       setShowModal1(false);
  //     }, 5000);
  //   } else {
  //     if (check) {
  //       fetch(`https://api-scdndestates.com/api/user-enquiry/`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ userName, phone, email, subject, message }),
  //       })
  //         .then((response) => {
  //           if (!response.ok) {
  //             throw new Error("Failed to submit enquiry");
  //           }
  //           return response.json();
  //         })
  //         .then((response) => {
  //           if (response.message === "Success") {
  //             secureLocalStorage.setItem(
  //               "user-enquiry",
  //               JSON.stringify(response.data)
  //             );
  //             setShowModal3(true);
  //             setShowModal1(false);
  //             setShowModal2(false);
  //             setTimeout(() => {
  //               setShowModal3(false);
  //             }, 5000);
  //           }
  //           setUserName("");
  //           setPhone("");
  //           setEmail("");
  //           setSubject("");
  //           setMessage("");
  //           setCheck(false);
  //         })
  //         .catch((error) => {
  //           // console.error("Error during enquiry submission:", error);
  //         });
  //     } else {
  //       setShowModal2(true);
  //       setShowModal1(false);
  //       setShowModal3(false);
  //       setTimeout(() => {
  //         setShowModal2(false);
  //       }, 5000);
  //     }
  //   }
  // };

  // connect with firebase
  const submitData = (e) => {
    fetch(`https://api-scdndestates.com/api/user-enquiry/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userName, phone, email, subject, message }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to submit enquiry");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "Success") {
          secureLocalStorage.setItem(
            "user-enquiry",
            JSON.stringify(response.data)
          );
          setShowModal3(true);
          setShowModal1(false);
          setShowModal2(false);
          setTimeout(() => {
            setShowModal3(false);
          }, 5000);
        }
        setUserName("");
        setPhone("");
        setEmail("");
        setSubject("");
        setMessage("");
        setCheck(false);
      })
      .catch((error) => {
        // console.error("Error during enquiry submission:", error);
      });
  };

  const [selectedField, setSelectedField] = useState(null);

  const handleFocus = (field) => {
    setSelectedField(field);
  };

  const handleBlur = () => {
    setSelectedField(null);
  };

  const handlephone = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length < 11) {
      if (/^\d*$/.test(inputValue)) {
        setPhone(inputValue);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Customer Care or Helpdesk - Contact us | SCDND Estates</title>
        <meta
          name="description"
          content="Send us a message. Our representatives will contact you shortly. Coupon payout to Bank Account, KYC completion or update, Transaction failure or reversal, Adding/removing of funds from Money Wallet."
        />
        <meta
          name="description"
          content="Users can lodge complaints, ask for clarifications and leave general enquiries on any variety of subjects related to portal use or decentralized assets in general, by leaving your Email and Mobile and describing your query."
        />

        <link
          rel="canonical"
          href="https://scdndestates.com/ContactUs"
        />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Customer Care or Helpdesk - Contact us"
        />
        <meta
          property="og:description"
          content="Send us a message. Our representatives will contact you shortly. Coupon payout to Bank Account, KYC completion or update, Transaction failure or reversal, Adding/removing of funds from Money Wallet."
        />

        <meta
          property="og:url"
          content="https://scdndestates.com/ContactUs"
        />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Customer Care or Helpdesk - Contact us"
        />
        <meta
          name="twitter:description"
          content="Send us a message. Our representatives will contact you shortly. Coupon payout to Bank Account, KYC completion or update, Transaction failure or reversal, Adding/removing of funds from Money Wallet."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      <Header />
      <div className="after-header max-w-[1800px] mx-auto ">
        <div className="w-[80%] mx-auto text-black rounded-lg flex flex-wrap justify-between shadow-md mt-4 ">
          {/* Left container */}
          <div className="w-full md:w-[48%] bg-zinc-100 p-[5%] xl:p-[3%] rounded-t-lg md:rounded-l-lg md:rounded-tr-none border">
            <h2 className="text-2xl sm:text-3xl font-bold">Get in touch</h2>
            <p className="font-semibold text-gray-700 text-xs sm:text-sm mt-2">
              If you have any queries kindly take a moment to fill up this form,
              Our representatives will contact you shortly.
            </p>

            <div className="flex flex-wrap justify-between gap-2 mt-4">
              <div className="w-full xl:w-[48%]">
                <div className="flex flex-col justify-center items-center relative bg-white border px-3 py-4 rounded-lg">
                  <img
                    src="/images/customer-care.webp"
                    alt=""
                    className="w-20 sm:w-32"
                  />
                  <h2 className="text-base sm:text-lg font-bold mt-2 text-black">
                    Customer Care
                  </h2>
                  <div className="flex flex-col items-center text-xs sm:text-base font-bold mt-2 gap-2">
                    <p className="rounded-full flex items-center bg-zinc-100">
                      <span className="bg-[#5AB2FF] py-2.5 px-2 sm:py-2 sm:px-2 h-full text-base sm:text-xl rounded-full text-white">
                        <BiSolidPhoneCall />
                      </span>
                      <span className="py-1 pl-2 pr-3 text-xs sm:text-sm">
                        (+91) - 1203180111
                      </span>
                    </p>
                    <p className="rounded-full flex items-center bg-zinc-100">
                      <span className="bg-[#5AB2FF] h-full py-2.5 px-2 sm:py-2 sm:px-2 text-base sm:text-xl rounded-full text-white">
                        <IoIosMail />
                      </span>
                      <span className="py-1 pl-3 pr-4 text-xs sm:text-sm break-all">
                        helpdesk@ <br />
                        scdndestates.com
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full xl:w-[48%]">
                <div className="flex flex-col justify-center items-center relative bg-white border px-3 py-4 rounded-lg">
                  <img
                    src="/images/compliance.webp"
                    alt=""
                    className="w-16 sm:w-[6.5rem]"
                  />
                  <h2 className="text-base sm:text-lg font-bold mt-2 text-black">
                    Compliance
                  </h2>
                  <div className="flex flex-col items-center text-xs sm:text-base font-bold mt-2 gap-2">
                    <p className="rounded-full flex items-center bg-zinc-100">
                      <span className="bg-[#5AB2FF] py-2.5 px-2 sm:py-2 sm:px-2 h-full text-base sm:text-xl rounded-full text-white">
                        <BiSolidPhoneCall />
                      </span>
                      <span className="py-1 pl-2 pr-3 text-xs sm:text-sm">
                        (+91) - 9953140287
                      </span>
                    </p>
                    <p className="rounded-full flex items-center bg-zinc-100">
                      <span className="bg-[#5AB2FF] py-2.5 px-2 sm:py-2 sm:px-2 h-full text-base sm:text-xl rounded-full text-white">
                        <RiAdminFill />
                      </span>
                      <span className="py-1 pl-2 pr-3 text-xs sm:text-sm">
                        Officer-in-charge - <br />
                        Jyoti
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Container */}
          <div className="w-full md:w-[52%] p-[5%] xl:p-[3%] rounded-b-lg md:rounded-r-lg md:rounded-bl-none border">
            <h2 className="text-2xl sm:text-3xl font-bold">
              Send us a message
            </h2>
            <form onSubmit={submitData} className="mt-4">
              <div className="flex flex-wrap justify-between gap-3">
                <div className="flex flex-col w-full xl:w-[48%]">
                  <label htmlFor="nameInput" className="font-semibold text-sm">
                    Enter Your Name
                  </label>
                  <input
                    id="nameInput"
                    onFocus={() => handleFocus("nameInput")}
                    onBlur={handleBlur}
                    type="text"
                    value={userName}
                    placeholder="Name"
                    onChange={(e) => setUserName(e.target.value)}
                    required
                    className={`mt-1 w-full px-2 py-1 rounded-lg outline-none border-1 shadow-sm duration-300 placeholder:text-xs sm:placeholder:text-sm ${
                      selectedField === "nameInput" ? "border-[#43A4F9]" : ""
                    }`}
                  />
                </div>

                <div className="flex flex-col w-full xl:w-[48%]">
                  <label
                    htmlFor="numberInput"
                    className="font-semibold text-sm"
                  >
                    Enter Your Number
                  </label>
                  <input
                    id="numberInput"
                    onFocus={() => handleFocus("numberInput")}
                    onBlur={handleBlur}
                    type="text"
                    onChange={handlephone}
                    required
                    placeholder="Number"
                    value={phone}
                    className={`mt-1 w-full px-2 py-1 rounded-lg outline-none border-1 shadow-sm duration-300 placeholder:text-xs sm:placeholder:text-sm ${
                      selectedField === "numberInput" ? "border-[#43A4F9]" : ""
                    }`}
                  />
                </div>

                <div className="flex flex-col w-full xl:w-[48%]">
                  <label htmlFor="emailInput" className="font-semibold text-sm">
                    Enter Your Email ID
                  </label>
                  <input
                    id="emailInput"
                    onFocus={() => handleFocus("emailInput")}
                    onBlur={handleBlur}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Email"
                    className={`mt-1 w-full px-2 py-1 rounded-lg outline-none border-1 shadow-sm duration-300 placeholder:text-xs sm:placeholder:text-sm ${
                      selectedField === "emailInput" ? "border-[#43A4F9]" : ""
                    }`}
                  />
                </div>

                <div className="flex flex-col w-full xl:w-[48%]">
                  <label
                    htmlFor="subjectInput"
                    className="font-semibold text-sm"
                  >
                    Subject <span className="text-red-600">*</span>
                  </label>
                  <select
                    name=""
                    id="subjectInput"
                    onFocus={() => handleFocus("SubjectInput")}
                    onBlur={handleBlur}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                    className={`mt-1 shadow-sm text-sm w-full px-2 py-1.5 rounded-lg border outline-none duration-300 cursor-pointer border-none overflow-hidden${
                      selectedField === "SubjectInput" ? "border-[#43A4F9]" : ""
                    }`}
                  >
                    <option value="">Select Subject</option>
                    <option value="Coupon payout to bank account">
                      Coupon payout to bank account
                    </option>
                    <option value="KYC Completion or update">
                      KYC Completion or update
                    </option>
                    <option value="Transaction failure or reversal">
                      Transaction failure or reversal
                    </option>
                    <option value="Adding/Removing funds from money wallet">
                      Adding/Removing funds from money wallet
                    </option>
                    <option value="Features of decentralized assets">
                      Features of decentralized assets
                    </option>
                    <option value="Understanding our products">
                      Understanding our products
                    </option>
                    <option value="Others, Please specify below.">
                      Others, Please specify below.
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex flex-col mt-3">
                <label
                  htmlFor="messageInput"
                  className="font-semibold text-xs sm:text-sm"
                >
                  Message <span className="text-red-600">*</span>
                </label>
                <textarea
                  id="messageInput"
                  onFocus={() => handleFocus("messageInput")}
                  onBlur={handleBlur}
                  value={message}
                  placeholder="Message"
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  className={`mt-1 w-full px-2 py-1 rounded-lg outline-none border-1 shadow-sm duration-300 placeholder:text-xs sm:placeholder:text-sm min-h-20 ${
                    selectedField === "messageInput" ? "border-[#43A4F9]" : ""
                  }`}
                ></textarea>
              </div>

              {/* Declaration */}
              <div className="checkbox-wrapper-46 mt-3 flex items-start gap-2">
                <input
                  type="checkbox"
                  id="cbx-46"
                  className="checkbox-style"
                  checked={check} // Bind checkbox to the state
                  onChange={(e) => setCheck(e.target.checked)}
                />
                <label htmlFor="cbx-46" className="cbx flex items-start gap-1">
                  {/* <span>
                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </svg>
                  </span> */}
                  <span className="font-semibold text-xs sm:text-sm text-gray-700">
                    I agree that the SCDND may contact me at the email address
                    or phone number above
                  </span>
                </label>
              </div>

              {/* Submit */}
              <div className="w-full flex justify-center mt-4">
                <button
                  type="submit"
                  className="w-full md:max-w-[150px] px-2 py-1.5 rounded-full font-semibold text-white bg-[#5AB2FF] duration-200 hover:bg-[#3190e3]"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal for showing alert */}
      {showModal1 && (
        <AlertYellow
          message="Mobile number must be 10 digits"
          onClose={() => setShowModal1(false)}
        />
      )}
      {showModal2 && (
        <AlertYellow
          message="Checkbox is also mandatory"
          onClose={() => setShowModal2(false)}
        />
      )}
      {showModal3 && (
        <AlertGreen
          message="Message sent successfully"
          onClose={() => setShowModal3(false)}
        />
      )}
    </>
  );
}

export default ContactUs;
