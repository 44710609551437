import React from "react";
import Header from "../../components/Header/Header";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { RiBookmark3Fill } from "react-icons/ri";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Helmet } from "react-helmet";

function PLForeignPortfolio() {
  return (
    <>
      <Helmet>
        <title>Foreign Portfolio | SCDND Estates</title>
        <meta
          name="description"
          content="India's nascent capital markets, with poor financial reporting, limited reach of independent valuers and credit rating agencies, and underdeveloped derivatives and Swap markets, pose significant challenges for valuing and assessing risk for unlisted assets."
        />
        <meta
          name="description"
          content="Traditional ETFs are permitted by SEBI to hold the most liquid stocks and bonds as their underlying portfolio due to the passive nature of their investment mandate. Unfortunately, that consists of only a tiny fraction of the real Indian economy."
        />
        <link
          rel="canonical"
          href="https://scdndestates.com/ForeignPortfolio"
        />
        {/* Open Graph */}
        <meta property="og:title" content="Foreign Portfolio - SCDND Estates" />
        <meta
          property="og:description"
          content="India's nascent capital markets, with poor financial reporting, limited reach of independent valuers and credit rating agencies, and underdeveloped derivatives and Swap markets, pose significant challenges for valuing and assessing risk for unlisted assets."
        />

        <meta
          property="og:url"
          content="https://scdndestates.com/ForeignPortfolio"
        />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Foreign Portfolio - SCDND Estates"
        />
        <meta
          name="twitter:description"
          content="India's nascent capital markets, with poor financial reporting, limited reach of independent valuers and credit rating agencies, and underdeveloped derivatives and Swap markets, pose significant challenges for valuing and assessing risk for unlisted assets."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      <Header />
      <div className="after-header Partnerships-layer flex flex-col items-center max-w-[1800px] mx-auto">
        <p className="w-[90%] mx-auto text-sm lg:text-base ">
          <a href="/" className="hover:underline">
            Home
          </a>{" "}
          {">"}{" "}
          <a href="/partnerships" className="hover:underline">
            Partnership
          </a>{" "}
          {">"}{" "}
          <a href="/ForeignPortfolio">
            <u>Foreign Portfolio</u>
          </a>
        </p>
        <div className="container-fluid w-full max-w-[90%] mx-auto mt-4  bg-gradient-to-tr from-[#dbedf9] to-[#e7f6ff] pt-5 px-2 lg:p-5 rounded-2xl">
          <div className="flex flex-wrap gap-y-10 gap-x-4 justify-around ">
            {/*       Card - 1     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[500px] md:h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/unlisted.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Unlisted Asset Challenges
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        India's nascent capital markets, with poor financial
                        reporting, limited reach of independent valuers and
                        credit rating agencies, and underdeveloped derivatives
                        and Swap markets, pose significant challenges for
                        valuing and assessing risk for unlisted assets.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3 text-xl font-bold  text-[#212121] ">
                        DeFi: No Valuation, No Counterparty Risk
                      </h2>

                      <ul className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "Valuation of underlying portfolio constituents are unnecessary as the overall DeAs is linked to an external benchmark.",
                          "The collateral consists of internalized and liquid assets whose prices are known publicly at all times.",
                          "Counterparty risk is eliminated completely due to the decentralized nature of the assets, thereby rendering external credit rating unnecessary.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 -bottom-8 md:bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none  text-[#414141] font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 2     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[500px] md:h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/limited-etf.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Limited ETFs, Limited India
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Traditional ETFs are permitted by SEBI to hold the most
                        liquid stocks and bonds as their underlying portfolio
                        due to the passive nature of their investment mandate.
                        Unfortunately, that consists of only a tiny fraction of
                        the real Indian economy.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        DeA: Beyond Benchmark, Active Management
                      </h2>

                      <ul className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "The underlying securities in our model can be significantly different (and vary through time) from the reference index or benchmark.",
                          " Decentralized Assets may potentially operate under a different regulatory framework compared to traditional financial products, potentially leading to a simplified compliance process for distributors.",
                          "Much more freedom to the fund manager to allocate capital better instead of just in the biggest stocks, while also not exposing the DeAs holder to their individual price-fluctuations.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 -bottom-8 md:bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 3     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[500px] md:h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px] overflow-hidden">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/sebi-rules.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        SEBI Rules vs. FPI Clients{" "}
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Listed stocks/bonds use regulated infrastructure such as
                        Exchanges, Depositories, Banks, Registrars etc.,
                        therefore SEBI imposes (and frequently revises)
                        restrictions/limits on the trading activities of FPIs,
                        which often are not in line with their financial
                        interests.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        DeFi: On-Chain Transparency, Off-Chain Privacy
                      </h2>

                      <ul className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "No SEBI regulated market infrastructure is used, instead all Client Liabilities are stored in a public blockchain, with all transactions processed through a Proof-of-Work mechanism.",
                          "The cumulative Units outstanding of every financial product is publicly known at all times and the Collateral side is open to public audit, significantly increasing transparency while simultaneously removing the cost and burden of regulations and reporting.",
                          "The identities of the owners themselves (identified only by their Wallet IDs inside the network) are protected from each other, but not from regulators as all clients PAN/Aadhaar based KYC are stored.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 -bottom-8 md:bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 4     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[500px] md:h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/stale-market.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Stale Market, Soaring Stocks
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        The present Indian capital market lacks innovative
                        financial products that can compete with cutting-edge
                        wealth management, synthetic and risk-splicing
                        securities present in developed countries, inflating the
                        valuation of a handful of stocks further to dangerous
                        levels.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        DeAs: Reimagining ETFs on Blockchain
                      </h2>

                      <ul className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "The decentralized embodiments of major asset classes, and their peer-2-peer transfer using Blockchain technology is a unique financial product in itself.",
                          "A DeAs looks exactly like an ETF from the outside (but is constructed very differently inside), thereby capturing the best features of passive funds from the perspective of investors.",
                          "Both the financial engineering and technology of DeAs are not limited by geographical barriers or economic conditions, and have tremendous potential to replace traditional investments designed in the last century.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 -bottom-8 md:bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
          </div>

          <div className="my-4 text-center pt-3 xl:px-20">
            <p className=" just-content-center px-4 pt-3 leading-7 text-sm md:text-base font-semibold">
              In a world where online competition threatens traditional
              insurance sales and client retention, we offer insurance agents
              the opportunity to evolve their role and remain at the forefront
              of the industry. Our financial product is a game-changer, enabling
              agents to provide their clients with innovative, risk-free
              investment options.
            </p>
            <p className="mb-3 just-content-center px-4 pt-1 leading-7 text-sm md:text-base font-semibold">
              Don't let the challenges of low margins, complex products, and
              competition hold you back. Join us in this exciting journey to
              revolutionize the financial industry and secure your position as a
              trusted advisor in the digital age. Together, we can turn the
              challenges of today into the opportunities of tomorrow, ensuring
              that insurance agents continue to be the go-to source for
              financial protection and financial growth for their valued
              clients.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PLForeignPortfolio;
