import React, { useEffect, useState, useRef } from "react";
import "./Footer.css";
import { FaCircle } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";
import { GiInjustice } from "react-icons/gi";
import AlertRed from "../Alerts/AlertRed.jsx";
import AlertGreen from "../Alerts/AlertGreen.jsx";
import CheckAuth from "../ServiceFunctions/CheckAuth.jsx";

function Footer() {
  const { email, label } = CheckAuth();
  const inactiveTimeRef = useRef(0);
  const intervalRef = useRef(null);
  const [showModal1, setShowModal1] = useState(false);
  const navigateTimeout = useRef(null);
  const [message2, setMessage2] = useState("");

  const handleNavigate2 = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    window.location.href = "/userLost";
  };

  useEffect(() => {
    if (message2 !== "") {
      setTimeout(() => {
        setMessage2("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate2, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message2]);

  // logout function
  const logout = () => {
    if (email) {
      const refreshToken = getCookie("refresh_token");
      secureLocalStorage.removeItem("kycData");
      secureLocalStorage.removeItem("blockchainData");
      secureLocalStorage.removeItem("ownershipData");
      secureLocalStorage.removeItem("tempData");
      secureLocalStorage.removeItem("couponData");
      secureLocalStorage.removeItem("access_token_time");
      if (refreshToken) {
        fetch(`https://api-scdndestates.com/api/logout/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: refreshToken,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to logout");
            }
            return response.json();
          })
          .then((response) => {
            document.cookie =
              "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie =
              "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            if (response.error === "Logged out successfully") {
              secureLocalStorage.removeItem("eventFormData");
              const currentUrl = new URL(window.location.href);
              if (currentUrl.pathname === "/") {
                window.location.reload();
              } else {
                window.location.href = "/";
              }
            } else {
              setMessage2(response.error);
            }
          })
          .catch((error) => {
            // console.error("Error during logout:", error);
          });
      } else {
        setShowModal1(true);
        setTimeout(() => {
          setShowModal1(false);
        }, 3000);
        window.location.href = "/accessDenied";
      }
    }
  };
  // logout function

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const isTokenExpired = (token) => {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const expiry = payload.exp * 1000; // Convert to milliseconds
      return Date.now() > expiry;
    } catch (error) {
      // console.error("Failed to parse token:", error);
      return true; // Consider token expired if we can't parse it
    }
  };

  const fetchProtectedData = () => {
    const accessToken = getCookie("access_token");
    const refreshToken = getCookie("refresh_token");

    if (!accessToken || !refreshToken) {
      return;
    }
    if (isTokenExpired(accessToken) === false) {
      fetch(`https://api-scdndestates.com/api/refresh_access_token/`, {
        method: "POST",
        body: JSON.stringify({
          refresh_token: refreshToken,
          access_token: accessToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch protected data");
          }
          return response.json();
        })
        .then((data) => {
          setTokens(data.new_access_token);
        })
        .catch((error) => {
          // console.error("Error fetching protected data:", error);
        });
    }
  };

  const setTokens = (accessToken) => {
    const access_token_expires = new Date();

    // Set access token to expire after 2 minutes
    access_token_expires.setTime(
      access_token_expires.getTime() + 2 * 60 * 1000
    );

    document.cookie = `access_token=${accessToken};expires=${access_token_expires.toUTCString()};path=/`;
    secureLocalStorage.setItem("access_token_time", access_token_expires);
  };

  // refreshing access token

  // refreshing access token
  const checkAndRefreshToken = () => {
    const storedTokenTime = secureLocalStorage.getItem("access_token_time");
    const currentTime = new Date();
    let timeDifference = 0;

    if (storedTokenTime) {
      timeDifference = currentTime - new Date(storedTokenTime) + 2 * 60 * 1000;
      if (timeDifference >= 1 * 60 * 1000 && timeDifference < 2 * 60 * 1000) {
        console.log("running refresh token...");
        fetchProtectedData();
      } else if (timeDifference >= 2 * 60 * 1000) {
        console.log("logging out...");
        logout();
      }
    }
  };

  useEffect(() => {
    if (email) {
      const events = ["click", "scroll", "keydown", "touchstart", "touchmove"];

      const handleActivity = () => {
        inactiveTimeRef.current = 0;
      };
      // Reset inactivity time on user activity
      events.forEach((event) => window.addEventListener(event, handleActivity));

      // Set up the interval to check for inactivity and refresh the token
      intervalRef.current = setInterval(() => {
        console.log("checking after every 3 sec...");
        checkAndRefreshToken();
        inactiveTimeRef.current += 3;

        if (inactiveTimeRef.current >= 7 * 60 && label !== "Employee") {
          logout();
        }
      }, 3000); // Check every 3 seconds

      // Cleanup function
      return () => {
        clearInterval(intervalRef.current);
        events.forEach((event) =>
          window.removeEventListener(event, handleActivity)
        );
      };
    }
  }, [email, inactiveTimeRef.current]);

  return (
    <>
      {" "}
      <div className="before-footer">
        <div className="footer flex-col min-[2000px]:items-center">
          <div className="footer-title flex-col align-center max-w-[2000px]">
            <div className="topsecc flex-column justify-evenly w-fit">
              <div className="logo-left  showw">
                <img className="footerLogo" src="/images/logo02.webp" alt="" />
                <div className="logo-p text-white">
                  <p className="footerLeftText">CIN: U70109WB2016PTC215755</p>
                  <p className="footerLeftText">SEBI AIF-1 Reg. No:</p>
                  <p className="footerLeftText">RBI NBFC (ICC)</p>
                </div>
              </div>
              <p className="footer-title flex items-center  ">
                Reinventing the Financial System
              </p>
            </div>
            <div className="bottom-line flex justify-center align-center showw"></div>
          </div>

          <div className="content-container flex  justify-evenly max-w-[2000px]">
            <div className="logo-left hidee">
              <img className="footerLogo" src="/images/logo02.webp" alt="" />
              <div className="logo-p">
                <p className="footerLeftText">CIN: U70109WB2016PTC215755</p>
                <p className="footerLeftText">SEBI AIF-1 Reg. No:</p>
                <p className="footerLeftText">RBI NBFC (ICC)</p>
              </div>
            </div>
            <div className="footerLine hidee"></div>
            <div className="container1  flex-col">
              <p className="container-head flex items-center ">
                <svg
                  className="c1-icon mt-1"
                  width="38"
                  height="39"
                  viewBox="0 0 38 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.75 12.9831C5.75 11.462 5.75212 10.4218 5.85702 9.64163C5.95805 8.89019 6.13762 8.53132 6.3846 8.28434C6.63158 8.03737 6.99045 7.85779 7.74189 7.75676C8.52209 7.65187 9.56228 7.64974 11.0833 7.64974H26.9167C28.4377 7.64974 29.4779 7.65187 30.2581 7.75676C31.0096 7.85779 31.3684 8.03737 31.6154 8.28434L32.3205 7.57924L31.6154 8.28434C31.8624 8.53132 32.042 8.89019 32.143 9.64163C32.2479 10.4218 32.25 11.462 32.25 12.9831V26.2331H5.75V12.9831Z"
                    stroke="white"
                    strokeWidth="2"
                  />
                  <path
                    d="M5.80556 26.2331C4.6703 26.2331 3.75 27.1534 3.75 28.2886C3.75 30.0068 5.14289 31.3997 6.86111 31.3997H31.1389C32.8571 31.3997 34.25 30.0068 34.25 28.2886C34.25 27.1534 33.3297 26.2331 32.1944 26.2331H5.80556Z"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
                Support
              </p>
              <ul className="ul">
                <li className="li ">
                  <span className="flex gap-2 items-center bulltet-points">
                    <FaCircle className="text-xs  md:text-[8px] sm:text-[6px] bulltet-points-new" />
                    Corporate Office
                  </span>
                  <p className="li-p">
                    C-267 Ground Floor 5th Cross Street Swarn Nagri Greater
                    Noida Uttar Pradesh- 201310
                  </p>
                </li>
                <li className="li mt-2">
                  <span className="flex gap-2 items-center bulltet-points">
                    <FaCircle className="text-xs  md:text-[8px] sm:text-[6px] bulltet-points-new" />
                    Registered Office
                  </span>
                  <p className="li-p">
                    Flat 1/1A Clubtown Greens 167 Jessore Road Kolkata- 700055
                  </p>
                </li>
              </ul>
            </div>
            <div className="container1 flex-col ">
              <p className="container-head flex items-center">
                <svg
                  className="c1-icon mt-1"
                  width="38"
                  height="39"
                  viewBox="0 0 38 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M31.2351 32.691C30.5134 30.6709 28.9231 28.886 26.711 27.6129C24.4988 26.3398 21.7884 25.6497 19 25.6497C16.2116 25.6497 13.5012 26.3398 11.289 27.6129C9.07686 28.886 7.48662 30.6709 6.76494 32.691"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <circle
                    cx="19"
                    cy="12.9831"
                    r="6.33333"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                About Us
              </p>
              <ul className="ul">
                <li className="li">
                  <a href="/OurTeam" className="li flex gap-2 items-center">
                    <FaCircle className="text-xs  md:text-[8px] sm:text-[6px] bulltet-points-new" />
                    <span className="head-a bulltet-points">Our Team </span>
                  </a>
                </li>
                <li className="li">
                  <a href="/Career" className="li flex gap-2 items-center">
                    <FaCircle className="text-xs  md:text-[8px] sm:text-[6px] bulltet-points bulltet-points-new" />
                    <span className="head-a bulltet-points ">Career </span>
                  </a>
                </li>

                <li className="li ">
                  <span className="flex gap-2 items-center bulltet-points">
                    <FaCircle className=" text-xs md:text-[8px] sm:text-[6px] bulltet-points-new" />
                    Pricing{" "}
                  </span>
                  <div className="hyperlinks flex-col justify-center ">
                    <a
                      className="li-a flex items-center "
                      href="/investment_advisory"
                    >
                      <svg
                        className="a-icon mt-1"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                          stroke="white"
                          strokeLinecap="round"
                        />
                        <path
                          d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                          fill="white"
                        />
                        <rect
                          x="2.25"
                          y="8.66666"
                          width="3.08333"
                          height="3.08333"
                          rx="1.5"
                          stroke="white"
                          strokeLinecap="round"
                        />
                      </svg>
                      Investment Advisory
                    </a>
                    <a
                      className="li-a flex items-center"
                      href="/fund_management"
                    >
                      <svg
                        className="a-icon mt-1"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                          stroke="white"
                          strokeLinecap="round"
                        />
                        <path
                          d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                          fill="white"
                        />
                        <rect
                          x="2.25"
                          y="8.66666"
                          width="3.08333"
                          height="3.08333"
                          rx="1.5"
                          stroke="white"
                          strokeLinecap="round"
                        />
                      </svg>
                      Fund Management
                    </a>
                    <a
                      className="li-a flex items-center"
                      href="/merchant_banking"
                    >
                      <svg
                        className="a-icon mt-1"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                          stroke="white"
                          strokeLinecap="round"
                        />
                        <path
                          d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                          fill="white"
                        />
                        <rect
                          x="2.25"
                          y="8.66666"
                          width="3.08333"
                          height="3.08333"
                          rx="1.5"
                          stroke="white"
                          strokeLinecap="round"
                        />
                      </svg>
                      Merchant Banking
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="container1 flex-col ">
              <p className="container-head flex items-center">
                <svg
                  className="c1-icon mt-1"
                  width="38"
                  height="39"
                  viewBox="0 0 38 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.5417 8.23307C26.7654 8.23307 27.8773 8.23307 28.676 8.76675C29.0217 8.99779 29.3186 9.29467 29.5496 9.64044C30.0833 10.4391 30.0833 11.551 30.0833 13.7747V28.8164C30.0833 31.802 30.0833 33.2947 29.1558 34.2222C28.2283 35.1497 26.7356 35.1497 23.75 35.1497H14.25C11.2644 35.1497 9.77165 35.1497 8.84415 34.2222C7.91666 33.2947 7.91666 31.802 7.91666 28.8164V13.7747C7.91666 11.551 7.91666 10.4391 8.45034 9.64044C8.68137 9.29467 8.97825 8.99779 9.32402 8.76675C10.1227 8.23307 11.2346 8.23307 13.4583 8.23307"
                    stroke="white"
                  />
                  <path
                    d="M14.25 8.23307C14.25 6.48417 15.6678 5.06641 17.4167 5.06641H20.5833C22.3322 5.06641 23.75 6.48417 23.75 8.23307C23.75 9.98197 22.3322 11.3997 20.5833 11.3997H17.4167C15.6678 11.3997 14.25 9.98197 14.25 8.23307Z"
                    stroke="white"
                  />
                  <path
                    d="M14.25 19.3164L23.75 19.3164"
                    stroke="white"
                    strokeLinecap="round"
                  />
                  <path
                    d="M14.25 25.6497L20.5833 25.6497"
                    stroke="white"
                    strokeLinecap="round"
                  />
                </svg>
                Company Info
              </p>
              <ul className="ul">
                <li className="li">
                  <a
                    href="/financialStatements"
                    className="li flex gap-2 items-center"
                  >
                    <FaCircle className="text-xs md:text-[8px] sm:text-[6px] bulltet-points-new" />
                    <span className="head-a bulltet-points">
                      Financial Statements
                    </span>
                  </a>
                </li>
                <li className="li">
                  <a
                    href="/annualReports"
                    className="li flex gap-2 items-center"
                  >
                    <FaCircle className="text-xs md:text-[8px] sm:text-[6px] bulltet-points-new" />
                    <span className="head-a bulltet-points">Annual Report</span>
                  </a>
                </li>
                <li className="li">
                  <a
                    href="/shareholdings"
                    className="li flex gap-2 items-center"
                  >
                    <FaCircle className="text-xs md:text-[8px] sm:text-[6px] bulltet-points-new" />
                    <span className="head-a bulltet-points">Shareholdings</span>
                  </a>
                </li>
                <li className="li">
                  <a href="/keyRatios" className="li flex gap-2 items-center">
                    <FaCircle className="text-xs md:text-[8px] sm:text-[6px] bulltet-points-new" />
                    <span className="head-a bulltet-points"> Key Ratios</span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="container1 flex-col ">
              <p className="container-head flex gap-2 items-center">
                <GiInjustice className="text-lg lg:text-2xl xl:text-3xl" />
                Legal
              </p>
              <div className="hyperlinks flex-col justify-center mt-3">
                <a className="li-a flex items-center " href="/disclosures">
                  <svg
                    className="a-icon mt-1"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                      stroke="white"
                      strokeLinecap="round"
                    />
                    <path
                      d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                      fill="white"
                    />
                    <rect
                      x="2.25"
                      y="8.66666"
                      width="3.08333"
                      height="3.08333"
                      rx="1.5"
                      stroke="white"
                      strokeLinecap="round"
                    />
                  </svg>
                  Disclosures
                </a>
                <a
                  className="li-a flex items-center"
                  href="/terms & conditions"
                >
                  <svg
                    className="a-icon mt-1"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                      stroke="white"
                      strokeLinecap="round"
                    />
                    <path
                      d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                      fill="white"
                    />
                    <rect
                      x="2.25"
                      y="8.66666"
                      width="3.08333"
                      height="3.08333"
                      rx="1.5"
                      stroke="white"
                      strokeLinecap="round"
                    />
                  </svg>
                  Terms & Conditions
                </a>
                <a
                  className="li-a flex items-center"
                  href="/asset management risks"
                >
                  <svg
                    className="a-icon mt-1"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                      stroke="white"
                      strokeLinecap="round"
                    />
                    <path
                      d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                      fill="white"
                    />
                    <rect
                      x="2.25"
                      y="8.66666"
                      width="3.08333"
                      height="3.08333"
                      rx="1.5"
                      stroke="white"
                      strokeLinecap="round"
                    />
                  </svg>
                  Asset Management Risk
                </a>
                <a className="li-a flex items-center" href="/privacy policy">
                  <svg
                    className="a-icon mt-1"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                      stroke="white"
                      strokeLinecap="round"
                    />
                    <path
                      d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                      fill="white"
                    />
                    <rect
                      x="2.25"
                      y="8.66666"
                      width="3.08333"
                      height="3.08333"
                      rx="1.5"
                      stroke="white"
                      strokeLinecap="round"
                    />
                  </svg>
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>

          <div className="footer-bottom flex justify-between align-center max-w-[2000px]">
            <div className="f-left-bottom flex-col justify-center">
              <p className="f-bottom-text ">
                Copyright © 2024 SCDND. All rights reserved.
              </p>
              {/* <p className="f-bottom-text flex align-center">
          Copyright © 2023 SCDND. All rights reserved. All rights reserved.
          </p> */}
            </div>

            <div className="right-bottom flex justify-end align-center gap-2">
              {/* LinkedIn */}
              <button className="i-button">
                <a href="https://www.linkedin.com/company/scdnd/">
                  <svg
                    enable-background="new 0 0 24 24"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    className="w-6 h-6 text-gray-800 p-0.5 dark:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_2111499"
                  >
                    <g fill="#fff">
                      <path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"></path>
                      <path d="m.396 7.977h4.976v16.023h-4.976z"></path>
                      <path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"></path>
                    </g>
                  </svg>
                </a>
              </button>

              {/* Instagram */}
              <button className="i-button">
                <a href="https://www.instagram.com/scdnd.estates/">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-800 dark:text-white"
                  >
                    <path
                      clipRule="evenodd"
                      d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                      fillRule="evenodd"
                      fill="currentColor"
                    ></path>
                  </svg>
                </a>
              </button>

              {/* Twitter */}
              <button className="i-button">
                <a href="https://x.com/ScdndEstates">
                  <svg
                    id="fi_5968958"
                    className="w-6 h-6 p-0.5 fill-white text-gray-800 dark:text-white"
                    enable-background="new 0 0 1226.37 1226.37"
                    viewBox="0 0 1226.37 1226.37"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"></path>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </button>

              {/* Youtube */}
              <button className="i-button">
                <a href="https://www.youtube.com/@Scdnd">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-800 dark:text-white"
                  >
                    <path
                      clipRule="evenodd"
                      d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </button>

              {/* Facebook */}
              <button className="i-button">
                <a href="https://www.facebook.com/people/Scdnd-Estates/pfbid0cBVzB1n9jecGtaN9H3GeQHQydV1UM5TWo3vE1b7o7wutHLdfqmW8pQgseRJbusaVl/">
                  <svg
                    id="fi_3128304"
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    enable-background="new 0 0 100 100"
                    height="24"
                    viewBox="0 0 100 100"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="_x30_1._Facebook">
                      <path
                        id="Icon_11_"
                        d="m40.437 55.166c-.314 0-6.901.002-9.939-.001-1.564-.001-2.122-.563-2.122-2.137-.002-4.043-.003-8.086 0-12.129.001-1.554.591-2.147 2.135-2.148 3.038-.002 9.589-.001 9.926-.001 0-.277-.001-6.114 0-8.802.002-3.974.711-7.778 2.73-11.261 2.067-3.565 5.075-6.007 8.93-7.419 2.469-.905 5.032-1.266 7.652-1.268 3.278-.002 6.556.001 9.835.007 1.409.002 2.034.625 2.037 2.044.006 3.803.006 7.606 0 11.408-.002 1.434-.601 2.01-2.042 2.026-2.687.029-5.376.011-8.06.119-2.711 0-4.137 1.324-4.137 4.13-.065 2.968-.027 5.939-.027 9.015.254 0 7.969-.001 11.575 0 1.638 0 2.198.563 2.198 2.21 0 4.021-.001 8.043-.004 12.064-.001 1.623-.527 2.141-2.175 2.142-3.606.002-11.291.001-11.627.001v32.545c0 1.735-.546 2.288-2.258 2.288-4.174 0-8.349.001-12.523 0-1.513 0-2.103-.588-2.103-2.101-.001-10.599-.001-32.36-.001-32.732z"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal1 && (
        <AlertRed
          message="Refresh token not provided or connection failure."
          onClose={() => setShowModal1(false)}
        />
      )}
      {message2 !== "" &&
        (message2 === "Logged out successfully" ? (
          <AlertGreen
            message={message2}
            onClose={() => {
              setMessage2("");
              handleNavigate2();
            }}
          />
        ) : (
          <AlertRed
            message={message2}
            onClose={() => {
              setMessage2("");
              handleNavigate2();
            }}
          />
        ))}
    </>
  );
}

export default Footer;
