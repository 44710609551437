import React, { useState, useEffect } from "react";
import "./admin.css";
import { MdCloudUpload } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import AlertGreen from "../components/Alerts/AlertGreen.jsx";
import AlertRed from "../components/Alerts/AlertRed.jsx";
import AlertYellow from "../components/Alerts/AlertYellow.jsx";
import { FaDownload } from "react-icons/fa6";

function ManageDocument() {
  const [files, setFiles] = useState([]);
  const [message2, setMessage2] = useState("");
  const [filename2, setFilename2] = useState();
  const [filename3, setFilename3] = useState();
  const [filename4, setFilename4] = useState();
  const [filename5, setFilename5] = useState();
  const [filename6, setFilename6] = useState();
  const [showModal1, setShowModal1] = useState(false);
  const [message, setMessage] = useState("");

  const checkExcel = () => {
    setShowModal1(true);
    fetch(`https://api-scdndestates.com/api/check-excel/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to find the file.");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "success") {
          alert(
            "Process Executed successfully and verified/rejected User list created for refund."
          );
        } else {
          alert("File not found! Please upload the File...");
        }
        setShowModal1(false);
      })
      .catch((error) => {
        // console.error("Error during file finding process:", error);
      });
  };

  const Download_Excel = (excelname) => {
    fetch(`https://api-scdndestates.com/api/download-excel/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        excel: excelname,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to download the file");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${excelname}.xlsx`); //or any other <exte>cel</exte>nsion
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        // console.error("Error during file download:", error);
      });
  };

  const Upload_Excel = (excelname, file) => {
    const formData = new FormData();
    formData.append("excel", excelname);
    formData.append("file", file);

    fetch(`https://api-scdndestates.com/api/upload-excel/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload the file");
        }
        return response.json();
      })
      .then((response) => {
        setMessage(response.message);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      })
      .catch((error) => {
        // console.error("Error during file upload:", error);
      });
  };

  const Delete_Excel = (excelname) => {
    fetch(`https://api-scdndestates.com/api/delete-excel/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        excel: excelname,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete the file");
        }
        return response.json();
      })
      .then((response) => {
        setMessage(response.message);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      })
      .catch((error) => {
        // console.error("Error during file delete:", error);
      });
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleUpload = () => {
    const formData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append("files", files[i]);
    }
    fetch(`https://api-scdndestates.com/api/upload_files/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to get the data");
        }
        return response.json();
      })
      .then((response) => {
        setMessage2(response.message);
        setTimeout(() => {
          setMessage2("");
        }, 3000);
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  return (
    <>
      {/* Pool A/c Addition */}
      <div className="border px-3 py-3 rounded-lg mt-2 bg-white relative text-black shadow-md">
        <h2 className="bg-white absolute -top-3 border rounded-md px-2 py-1 font-bold text-base">
          <marquee behavior="" direction="">
            Pool A/C Addition
          </marquee>
        </h2>

        <div className="my-4 max-md:flex-col md:flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">
            E-Collect file
          </span>
          <div className="text-base max-md:mt-2 xl:text-lg flex justify-end gap-3">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename2(e.target.files[0])}
                className="flex h-[32px] md:h-[38px] max-md:w-full w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-1.5 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[220px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
              onClick={() => Upload_Excel("UserPoolAccount", filename2)}
            >
              <i className="text-lg md:text-xl">
                <MdCloudUpload className="icon" />
              </i>
              <p className="font-semibold">Upload</p>
            </button>
          </div>
        </div>

        <div className="my-4 max-md:flex-col md:flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">
            Client Bank Linking
          </span>
          <div className="flex justify-end">
            <button
              className={`${
                showModal1 ? "disabled-button" : "start-button"
              } p-1.5 md:p-1.5 rounded-lg font-semibold border max-md:mt-2 w-[130px] md:w-[120px] xl:w-[160px] text-sm xl:text-base`}
              type="button"
              disabled={showModal1}
              onClick={() => checkExcel()}
            >
              {showModal1 ? "Executing..." : "Start"}
            </button>
          </div>
        </div>

        <div className="my-4 max-md:flex-col md:flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">
            Rejected User A/C
          </span>
          <div className="text-base max-md:mt-2 xl:text-lg flex justify-end gap-3">
            <button
              className="button-excel w-[130px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
              onClick={() => Download_Excel("UserPoolAccount")}
            >
              <i className="text-base md:text-lg">
                <FaDownload className="icon" />
              </i>
              <p className="font-semibold">Download</p>
            </button>
          </div>
        </div>

        <div className="my-4 max-md:flex-col md:flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">
            Userpoolaccount.xlsx
          </span>
          <div className="text-base xl:text-lg flex justify-end gap-5">
            <button
              className="noselect max-md:mt-2 delete-button h-[33px] xl:h-[38px] w-[130px] md:w-[120px] xl:w-[160px]"
              onClick={() => Delete_Excel("UserPoolAccount")}
            >
              <span className="text text-sm md:text-base">Delete</span>
              <span className="icon hover:w-[60px] xl:hover:w-[80px] w-[60px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[20px] h-[12px] xl:w-[24px] xl:h-[20px]"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* Client .pdf Upload */}
      <div className="border p-3 relative flex mt-7  gap-3 rounded-lg bg-white flex-col shadow-md">
        <h2 className="absolute -top-4 bg-white px-4 py-1 text-sm xl:text-base rounded-lg font-bold border text-black w-60">
          <marquee behavior="" direction="">
            Client .pdf Upload
          </marquee>
        </h2>

        <div className="flex flex-wrap justify-between gap-2 w-full mt-3">
          <span className="text-black font-bold text-sm xl:text-base ">
            Form 16A
          </span>
          <div className="text-sm xl:text-base flex gap-3">
            <span className="rounded-lg">
              <input
                type="file"
                multiple
                directory="" // Enables folder selection
                webkitdirectory="" // Enables folder selection in webkit-based browsers
                onChange={handleFileChange}
                className="flex h-[32px] md:h-[38px] max-md:w-full w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-1.5 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[235px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
              onClick={() => handleUpload()}
            >
              <i className="text-lg md:text-xl">
                <MdCloudUpload className="icon" />
              </i>
              <p className="font-semibold">Upload</p>
            </button>
          </div>
        </div>

        <div className="flex flex-wrap justify-between gap-2">
          <span className="text-black font-bold text-sm xl:text-base">
            DSC Contract
          </span>
          <div className="text-sm xl:text-base flex gap-3">
            <span className="rounded-lg">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="flex h-[32px] md:h-[38px] max-md:w-full w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-1.5 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[235px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
              onClick={() => handleUpload()}
            >
              <i className="text-lg md:text-xl">
                <MdCloudUpload className="icon" />
              </i>
              <p className="font-semibold">Upload</p>
            </button>
          </div>
        </div>
      </div>

      {/* Product & Statistics */}
      <div className="border px-3 py-2 rounded-lg bg-white relative text-black mt-7 shadow-md">
        <h2 className="bg-white absolute -top-3 border rounded-md px-3 py-1 font-bold text-base">
          <marquee behavior="" direction="">
            Product & Statistics
          </marquee>
        </h2>

        <div className="my-4 max-md:flex-col md:flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">
            Economic Data
          </span>
          <div className="text-base max-md:mt-2 xl:text-lg flex justify-end gap-3">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename3(e.target.files[0])}
                className="flex h-[32px] md:h-[38px] max-md:w-full w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-1.5 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[235px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
              onClick={() => Upload_Excel("MonthlyWALRRates", filename3)}
            >
              <i className="text-lg md:text-xl">
                <span>
                  <svg
                    className="fill-white w-4 h-4"
                    viewBox="-32 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_1372840"
                  >
                    <path d="m235.605469 203.519531v25.742188c0 8.175781 1.632812 15.035156 4.855469 20.378906 4.445312 7.375 11.742187 11.601563 20.027343 11.601563 8.117188 0 16.183594-3.953126 23.972657-11.742188l85.023437-85.023438c18.667969-18.667968 18.667969-49.042968 0-67.710937l-85.023437-85.023437c-7.789063-7.792969-15.855469-11.742188-23.972657-11.742188-12.386719 0-24.882812 9.890625-24.882812 31.980469v23.378906c-120.710938 7.128906-220.640625 99.765625-235.492188 221.050781-.832031 6.773438 3.019531 13.257813 9.367188 15.769532 1.796875.710937 3.667969 1.054687 5.515625 1.054687 4.679687 0 9.21875-2.191406 12.109375-6.140625 39.835937-54.429688 103.796875-86.925781 171.09375-86.925781 12.46875 0 25.007812 1.125 37.40625 3.351562zm0 0"></path>
                    <path d="m438.253906 219.820312c-6.347656-2.515624-13.589844-.421874-17.625 5.089844-39.832031 54.425782-103.792968 86.921875-171.089844 86.921875-12.46875 0-25.007812-1.125-37.40625-3.351562v-25.742188c0-22.089843-12.5-31.980469-24.882812-31.980469-8.117188 0-16.183594 3.953126-23.976562 11.746094l-85.023438 85.019532c-18.664062 18.667968-18.664062 49.042968.003906 67.714843l85.019532 85.019531c7.792968 7.792969 15.859374 11.742188 23.976562 11.742188 12.382812 0 24.882812-9.890625 24.882812-31.980469v-23.378906c120.714844-7.128906 220.640626-99.765625 235.492188-221.046875.832031-6.777344-3.019531-13.261719-9.371094-15.773438zm0 0"></path>
                  </svg>
                </span>
              </i>
              <p className="font-semibold">Replace</p>
            </button>
          </div>
        </div>

        <div className="my-4 max-md:flex-col md:flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">
            DeAs Products
          </span>
          <div className="text-base max-md:mt-2 xl:text-lg flex justify-end gap-3">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename4(e.target.files[0])}
                className="flex h-[32px] md:h-[38px] max-md:w-full w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-1.5 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[235px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
              onClick={() => Upload_Excel("ExcelC", filename4)}
            >
              <i className="text-lg md:text-xl">
                <span>
                  <svg
                    className="fill-white w-4 h-4"
                    viewBox="-32 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_1372840"
                  >
                    <path d="m235.605469 203.519531v25.742188c0 8.175781 1.632812 15.035156 4.855469 20.378906 4.445312 7.375 11.742187 11.601563 20.027343 11.601563 8.117188 0 16.183594-3.953126 23.972657-11.742188l85.023437-85.023438c18.667969-18.667968 18.667969-49.042968 0-67.710937l-85.023437-85.023437c-7.789063-7.792969-15.855469-11.742188-23.972657-11.742188-12.386719 0-24.882812 9.890625-24.882812 31.980469v23.378906c-120.710938 7.128906-220.640625 99.765625-235.492188 221.050781-.832031 6.773438 3.019531 13.257813 9.367188 15.769532 1.796875.710937 3.667969 1.054687 5.515625 1.054687 4.679687 0 9.21875-2.191406 12.109375-6.140625 39.835937-54.429688 103.796875-86.925781 171.09375-86.925781 12.46875 0 25.007812 1.125 37.40625 3.351562zm0 0"></path>
                    <path d="m438.253906 219.820312c-6.347656-2.515624-13.589844-.421874-17.625 5.089844-39.832031 54.425782-103.792968 86.921875-171.089844 86.921875-12.46875 0-25.007812-1.125-37.40625-3.351562v-25.742188c0-22.089843-12.5-31.980469-24.882812-31.980469-8.117188 0-16.183594 3.953126-23.976562 11.746094l-85.023438 85.019532c-18.664062 18.667968-18.664062 49.042968.003906 67.714843l85.019532 85.019531c7.792968 7.792969 15.859374 11.742188 23.976562 11.742188 12.382812 0 24.882812-9.890625 24.882812-31.980469v-23.378906c120.714844-7.128906 220.640626-99.765625 235.492188-221.046875.832031-6.777344-3.019531-13.261719-9.371094-15.773438zm0 0"></path>
                  </svg>
                </span>
              </i>
              <p className="font-semibold">Replace</p>
            </button>
          </div>
        </div>

        <div className="my-4 max-md:flex-col md:flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">
            Collateral Price-data
          </span>
          <div className="text-base max-md:mt-2 xl:text-lg flex justify-end gap-3">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename5(e.target.files[0])}
                className="flex h-[32px] md:h-[38px] max-md:w-full w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-1.5 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[235px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
              onClick={() => Upload_Excel("Custodian", filename5)}
            >
              <i className="text-lg md:text-xl">
                <span>
                  <svg
                    className="fill-white w-4 h-4"
                    viewBox="-32 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_1372840"
                  >
                    <path d="m235.605469 203.519531v25.742188c0 8.175781 1.632812 15.035156 4.855469 20.378906 4.445312 7.375 11.742187 11.601563 20.027343 11.601563 8.117188 0 16.183594-3.953126 23.972657-11.742188l85.023437-85.023438c18.667969-18.667968 18.667969-49.042968 0-67.710937l-85.023437-85.023437c-7.789063-7.792969-15.855469-11.742188-23.972657-11.742188-12.386719 0-24.882812 9.890625-24.882812 31.980469v23.378906c-120.710938 7.128906-220.640625 99.765625-235.492188 221.050781-.832031 6.773438 3.019531 13.257813 9.367188 15.769532 1.796875.710937 3.667969 1.054687 5.515625 1.054687 4.679687 0 9.21875-2.191406 12.109375-6.140625 39.835937-54.429688 103.796875-86.925781 171.09375-86.925781 12.46875 0 25.007812 1.125 37.40625 3.351562zm0 0"></path>
                    <path d="m438.253906 219.820312c-6.347656-2.515624-13.589844-.421874-17.625 5.089844-39.832031 54.425782-103.792968 86.921875-171.089844 86.921875-12.46875 0-25.007812-1.125-37.40625-3.351562v-25.742188c0-22.089843-12.5-31.980469-24.882812-31.980469-8.117188 0-16.183594 3.953126-23.976562 11.746094l-85.023438 85.019532c-18.664062 18.667968-18.664062 49.042968.003906 67.714843l85.019532 85.019531c7.792968 7.792969 15.859374 11.742188 23.976562 11.742188 12.382812 0 24.882812-9.890625 24.882812-31.980469v-23.378906c120.714844-7.128906 220.640626-99.765625 235.492188-221.046875.832031-6.777344-3.019531-13.261719-9.371094-15.773438zm0 0"></path>
                  </svg>
                </span>
              </i>
              <p className="font-semibold">Replace</p>
            </button>
          </div>
        </div>

        <div className="my-4 max-md:flex-col md:flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">
            Margin Assets Holding
          </span>
          <div className="text-base max-md:mt-2 xl:text-lg flex justify-end gap-3">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename6(e.target.files[0])}
                className="flex h-[32px] md:h-[38px] max-md:w-full w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-1.5 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[235px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
              onClick={() => Upload_Excel("Retail Debt List", filename6)}
            >
              <i className="text-lg md:text-xl">
                <span>
                  <svg
                    className="fill-white w-4 h-4"
                    viewBox="-32 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_1372840"
                  >
                    <path d="m235.605469 203.519531v25.742188c0 8.175781 1.632812 15.035156 4.855469 20.378906 4.445312 7.375 11.742187 11.601563 20.027343 11.601563 8.117188 0 16.183594-3.953126 23.972657-11.742188l85.023437-85.023438c18.667969-18.667968 18.667969-49.042968 0-67.710937l-85.023437-85.023437c-7.789063-7.792969-15.855469-11.742188-23.972657-11.742188-12.386719 0-24.882812 9.890625-24.882812 31.980469v23.378906c-120.710938 7.128906-220.640625 99.765625-235.492188 221.050781-.832031 6.773438 3.019531 13.257813 9.367188 15.769532 1.796875.710937 3.667969 1.054687 5.515625 1.054687 4.679687 0 9.21875-2.191406 12.109375-6.140625 39.835937-54.429688 103.796875-86.925781 171.09375-86.925781 12.46875 0 25.007812 1.125 37.40625 3.351562zm0 0"></path>
                    <path d="m438.253906 219.820312c-6.347656-2.515624-13.589844-.421874-17.625 5.089844-39.832031 54.425782-103.792968 86.921875-171.089844 86.921875-12.46875 0-25.007812-1.125-37.40625-3.351562v-25.742188c0-22.089843-12.5-31.980469-24.882812-31.980469-8.117188 0-16.183594 3.953126-23.976562 11.746094l-85.023438 85.019532c-18.664062 18.667968-18.664062 49.042968.003906 67.714843l85.019532 85.019531c7.792968 7.792969 15.859374 11.742188 23.976562 11.742188 12.382812 0 24.882812-9.890625 24.882812-31.980469v-23.378906c120.714844-7.128906 220.640626-99.765625 235.492188-221.046875.832031-6.777344-3.019531-13.261719-9.371094-15.773438zm0 0"></path>
                  </svg>
                </span>
              </i>
              <p className="font-semibold">Replace</p>
            </button>
          </div>
        </div>
      </div>
      {message !== "" &&
        (message.includes("not") || message.includes("Invalid") ? (
          <AlertRed
            message={message}
            onClose={() => {
              setMessage("");
            }}
          />
        ) : message.includes("successfully") ? (
          <AlertGreen
            message={message}
            onClose={() => {
              setMessage("");
            }}
          />
        ) : (
          <AlertYellow
            message={message}
            onClose={() => {
              setMessage("");
            }}
          />
        ))}
      {message2 !== "" &&
        (message2.includes("Invalid") ? (
          <AlertRed
            message={message2}
            onClose={() => {
              setMessage2("");
            }}
          />
        ) : message2.includes("successfully") ? (
          <AlertGreen
            message={message2}
            onClose={() => {
              setMessage2("");
            }}
          />
        ) : (
          <AlertYellow
            message={message2}
            onClose={() => {
              setMessage2("");
            }}
          />
        ))}
    </>
  );
}

export default ManageDocument;
