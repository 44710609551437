import React from "react";
import "./Extras.css";

function Coming() {
  return (
    <div
      className="coming-container bg-style3"
      style={{ backgroundImage: "url(./images/bg-coming.webp)" }}
    >
      <div className="comingsoon">Coming Soon...</div>
      <div className="comingsoon2">
        We are building, testing and finalizing this Page for your
        Optimal Experience.
      </div>
      <button className="p-2 mt-4 coming-btn">
        <span
          className="bg-light p-1 px-2"
          style={{
            borderRadius: "30px",
          }}
        >
          Notify Me
        </span>
      </button>
    </div>
  );
}

export default Coming;
