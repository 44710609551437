import React from "react";
import Header from "../../components/Header/Header";
import { TbPointFilled } from "react-icons/tb";

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="after-header pb-5 max-w-[1800px] mx-auto">
        <div className="w-[90%] mx-auto">
          <div className="w-full flex flex-wrap gap-4 justify-start mt-4 ">
            <div className="justify-center border rounded-lg shadow-md w-full p-[1%]">
              <div className="col-12">
                <div className="row justify-content-center p-3 text-black">
                  <h4 className="text-center fw-bold mb-2 text-2xl flex items-center justify-center gap-2">
                    <span>
                      <img src="/images/privacy-policy.webp" className="w-9" alt="" />
                    </span>
                    <span>Privacy Policy</span>
                  </h4>
                  <h6 className="fw-bold mt-2">
                    WHAT DO WE DO WITH YOUR INFORMATION?
                  </h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      When you purchase decentralized assets from our portal or
                      subscribe to any of our value-added services, as part of
                      the buying and selling process, we collect and store the
                      personal information you give us such as your full name,
                      mobile number and email address.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      When you browse our store, we also automatically receive
                      your computer’s basic device info in order to provide us
                      with information that helps us learn about your
                      screen-dimensions, browser configuration and operating
                      system.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      Email marketing (if applicable): With your permission, we
                      may send you emails about our newsletter, new products,
                      offers and other updates.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      All information provided by Customer are preserved on
                      SCDND Estates database in an encrypted way. We take pride
                      in our responsibility to safeguard this information,
                      ensure its protection within our database and enable
                      secure authentication by valid Users.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      SCDND Estates is dedicated to enhancing your experience by
                      collecting, analysing, and leveraging your personal and
                      transaction data that you explicitly permit us to use and
                      is permitted under law. This strategic approach allows us
                      to design and develop products that align with your
                      preferences and cater to the needs of our discerning
                      end-users.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      No data is shared with any third party or harnessed by
                      SCDND Estates, its employees/agents/partners without
                      explicit consent of the User on this portal. User
                      transactional and financial data is treated as an
                      exclusive property of the Users themselves and SCDND
                      Estates database is merely its temporary repository.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      To manage substantial datasets, we may engage with
                      reputable data analysis firms but only after undergoing a
                      meticulous masking and/or redaction process to safeguard
                      sensitive and identifiable information.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      SCDND Estates may employ the collected User information to
                      suggest accessory products and services, ensuring a
                      personalized and seamless experience.
                    </p>
                  </p>
                  <h6 className="fw-bold mt-4">GOOGLE PERSONAL INFO</h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      The data collected from Google during Sign-in/Registration
                      through Google APIs are only in the nature of your primary
                      Google account email address and personal info that you've
                      made publicly available, including but not limited to you
                      verified mobile number, full name and date of birth.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      No sensitive or restricted API scopes provided by Google
                      are used on SCDND Estates portal and no data is shared by
                      Google or collected on behalf of the User. Any changes to
                      this privacy policy or the scope of sensitive data
                      collection will be enforced after explicitly permission by
                      the User and on a case-by-case basis only.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      User data collected from Google APIs is not shared or sold to any third-party for any purposes whatsoever other than User authentication or enhancing User experience on SCDND Estates website.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      User data collected from Google APIs are retained only as long as User's financial relationship exists with SCDND Estates or User's
                      subscription to our services remain active, or until data usage
                      consent is explicitly revoked by the User.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      Users financial, personal or transactional data might also
                      be retained after withdrawal of User's consent if mandated
                      to do so as per India's financial regulators for the
                      purpose of Know-Your-Customer/Anti-Money-Laundering.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      Personal and sensitive User data collected from Google, such as full Name, Mobile Number and primary Email address of Google account are used only for User Authentication purposes, and for sending OTPs or Alerts/notifications related to User-initiated transactions performed on SCDND Estates website.
                    </p>
                  </p>
                  <h6 className="fw-bold mt-4">CONSENT</h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <h6 className="fw-bold mt-2">How do you get my consent?</h6>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      When you provide us with personal information to
                      register/login or verification of KYC (as mandated by
                      financial regulatory authority), verify your bank account
                      information, top-up your Wallet, place a buy/sell order
                      for the synthetic ETFs, or authenticate your PAN for
                      tax-deduction at source, we imply that you consent to our
                      collecting, storing and using it for that specific reason
                      only.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      All consents provided by the User will be via explicit
                      opt-in, whereby the default option of the consent form
                      will be set to unselected/not consenting. Furthermore, the
                      entire legal text of the terms and conditions will be
                      clearly visible without any redactions or truncations.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      If we ask for your personal information for a secondary
                      reason, like marketing, we will either ask you directly
                      for your expressed consent or provide you with an
                      opportunity to say no.
                    </p>
                    <h6 className="fw-bold mt-2">
                      How do I withdraw my consent?
                    </h6>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      <span>
                        If after you opt-in, you change your mind, you may
                        withdraw your consent for us to contact you, use your
                        personal and transactional data for authentication and
                        analysis purposes, or for the continued collection of
                        further identifiable information at any time, by
                        contacting us at{" "}
                        <span>
                          <a
                            href="mailto:helpdesk@scdndestates.com"
                            className="text-blue-700 font-semibold"
                          >
                            helpdesk@scdndestates.com
                          </a>
                        </span>{" "}
                        or
                        <span>
                          <a
                            href="tel:+911203180111"
                            className="text-blue-700 font-semibold"
                          >
                            +911203180111
                          </a>
                        </span>
                        .
                      </span>
                    </p>
                  </p>
                  <h6 className="fw-bold mt-4">DISCLOSURE</h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      We may disclose your personal information if we are
                      required by law to do so or if you violate our Terms of
                      Service.
                    </p>
                  </p>
                  <h6 className="fw-bold mt-4">THIRD-PARTY SERVICES</h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      In general, the third-party providers used by us will only
                      collect, disclose, and use your information to the extent
                      necessary to allow them to perform the services they
                      provide to us.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      However, certain third-party service providers, such as
                      cloud communication platform, banks and other payment
                      processors, have their own privacy policies in respect to
                      the information we are required to provide to them for the
                      User-initiated transactions on our portal.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      For these providers, we recommend that you read their
                      privacy policies so you can understand the manner in which
                      your personal information will be handled by these
                      providers.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      In particular, please note that certain providers may be
                      located in or have facilities that are located in a
                      different jurisdiction than either you or us. So, if you
                      elect to proceed with a transaction that involves the
                      services of a third-party service provider, then your
                      information may become subject to the laws of the
                      jurisdiction(s) in which that service provider or its
                      facilities are located.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      Once you leave our store’s website or are redirected to a
                      third-party website or application, you are no longer
                      governed by this Privacy Policy or our website’s Terms of
                      Service.
                    </p>
                  </p>
                  <h6 className="fw-bold mt-4">SECURITY</h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      To protect your personal information, we take reasonable
                      precautions and follow industry best practices to make
                      sure it is not inappropriately lost, misused, accessed,
                      disclosed, altered, or destroyed.
                    </p>
                  </p>
                  <h6 className="fw-bold mt-4">COOKIES</h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>{" "}
                      We use encrypted cookies and tokens to maintain the
                      session of your device. It is not used to personally
                      identify you on other websites and not shared with any
                      third party whatsoever.
                    </p>
                  </p>
                  <h6 className="fw-bold mt-4">AGE OF CONSENT</h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      By using this site, you represent that you are the age of
                      majority in your state or province of residence and you
                      have given us your consent to allow any of your minor
                      dependents to use this site.
                    </p>
                  </p>
                  <h6 className="fw-bold mt-4">
                    CHANGES TO THIS PRIVACY POLICY
                  </h6>
                  <p className="text-justify text-sm leading-relaxed ">
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      We reserve the right to modify this privacy policy at any
                      time, so please review it frequently. Changes and
                      clarifications will take effect immediately upon their
                      posting on the website. If we make material changes to
                      this policy, we will notify you here that it has been
                      updated, so that you are aware of what information we
                      collect, how we use it, and under what circumstances, if
                      any, we use and/or disclose it.
                    </p>
                    <p className="flex items-start gap-1.5 mt-1.5">
                      <span className="mt-1">
                        <TbPointFilled />
                      </span>
                      If our Company is acquired or merged with another company,
                      your information may be transferred to the new owners so
                      that we may continue to sell our financial products to
                      you.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
