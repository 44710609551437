import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader1 from "../Loaders/Loader1";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import secureLocalStorage from "react-secure-storage";
import AlertRed from "../Alerts/AlertRed.jsx";
import AlertYellow from "../Alerts/AlertYellow.jsx";
import AlertGreen from "../Alerts/AlertGreen.jsx";

function User_Details() {
  const { isAuthenticated, email, pan, phone } = CheckAuth();
  const { loader, statusB } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });
  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);
  const fullName = userKycData?.fullName;
  const [pincode, setPincode] = useState();
  const [state, setState] = useState("");
  const [pan2, setPan2] = useState("");
  const [dob, setDob] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [district, setDistrict] = useState("");
  const [locality, setLocality] = useState([]);
  const [panTypeValues, setPanTypeValues] = useState([]);
  const [area, setArea] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [title, setTitle] = useState("");
  const [userName, setUserName] = useState(fullName);
  const [x, setX] = useState("pan");
  const [nameStatus, setNameStatus] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [response2, setresponse2] = useState(null);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const navigateTimeout = useRef(null);

  const handleNavigate = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    if (response2["path"] !== undefined) {
      secureLocalStorage.setItem("kycData", JSON.stringify(response2.data));
      if (response2["path"] === "Edit") {
        navigate("/profile");
      } else {
        navigate("/UserKycForm", {
          state: { selectedCard: response2["path"] },
        });
      }
    }
  };

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message]);

  const handleX = (e) => {
    const value = e.target.value;
    setX(value);
    if (value === "aadhaar") {
      setSelectedType("Individual");
      setPanTypeValues(["Individual"]);
    }
  };

  const verifyPan = () => {
    if (statusB !== 5 || statusB !== 15) {
      setLoader2(true);
      fetch(`https://api-scdndestates.com/api/verify-pan/`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          phone: phone,
          Kyc_method: x,
          fullName: userName,
          pan: pan2,
          panType: selectedType,
          title: title,
          dob: dob,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.data === "verified") {
            secureLocalStorage.setItem(
              "kycData",
              JSON.stringify(response.data2)
            );
            setShowModal1(true);
            setShowModal2(false);
            setShowModal3(false);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal1(false);
            }, 5000);

            if (response["path"] === "Client Verification") {
              navigate("/UserKycForm", {
                state: { selectedCard: response["path"] },
              });
            }
            setNameStatus(true);
            setLoader2(false);
          } else if (response.data === "not verified") {
            setShowModal1(false);
            setShowModal2(true);
            setShowModal3(false);
            setShowModal4(false);
            setShowModal5(false);
            setTimeout(() => {
              setShowModal2(false);
            }, 5000);

            setNameStatus(false);
            setLoader2(false);
          } else {
            setMessage2(response.data);
            setTimeout(() => {
              setMessage2("");
            }, 5000);
            setNameStatus(false);
            setLoader2(false);
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    } else {
      setShowModal1(false);
      setShowModal2(false);
      setShowModal3(true);
      setShowModal4(false);
      setShowModal5(false);
      setTimeout(() => {
        setShowModal3(false);
      }, 5000);
    }
  };

  const navigate = useNavigate();

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    setSelectedType(newType);
    setTitle(
      newType === "Partnership" || newType === "Proprietorship" ? "M/s" : ""
    );
  };

  const handleRegister = (e) => {
    setLoader3(true);
    e.preventDefault();
    if (!isAuthenticated) {
      setShowModal1(false);
      setShowModal2(false);
      setShowModal3(false);
      setShowModal4(true);
      setShowModal5(false);
      setTimeout(() => {
        setShowModal4(false);
      }, 5000);
      setLoader3(false);
    } else if (userKycData?.["KYC_status"]?.["B"] < 5) {
      setShowModal1(false);
      setShowModal2(false);
      setShowModal3(false);
      setShowModal4(false);
      setShowModal5(true);
      setTimeout(() => {
        setShowModal3(false);
      }, 5000);
      setLoader3(false);
    } else {
      fetch(`https://api-scdndestates.com/api/user-kyc/`, {
        method: "POST",
        body: JSON.stringify({
          form_name: "userDetails",
          Kyc_method: x,
          title: title,
          fullName: userName,
          dob: dob,
          pan: pan2,
          aadhaar: aadhaar,
          panType: selectedType,
          email: email,
          phone: phone,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          area: area,
          pincode: pincode,
          district: district,
          state: state,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          setMessage(response["error"]);
          setresponse2(response);
          setLoader3(false);
        })
        .catch((error) => {
          setLoader3(false);
          // console.error("Error: ", error);
        });
    }
  };

  const handlePanType = (value) => {
    setPan2(value ? value.toUpperCase() : "");
    if (value.length === 10) {
      const typeMap = {
        p: ["Individual", "Proprietorship"],
        c: ["Private Company", "Public Company", "One Person Company (OPC)"],
        h: ["Hindu Undivided Family (HUF)"],
        t: ["Trust"],
        f: ["Partnership", "Limited Liability Partnership (LLP)"],
        a: ["(AOP)"],
      };
      const panType = typeMap[value[3].toLowerCase()];
      setSelectedType(panType ? panType[0] : "");
      setPanTypeValues(panType);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setAadhaar(userKycData?.aadhaar);
    setAddressLine1(userKycData?.addressLine1);
    setAddressLine2(userKycData?.addressLine2);
    setPincode(userKycData?.pincode);
    setDistrict(userKycData?.district);
    setState(userKycData?.state);
    setDob(userKycData?.dob);
    setLocality([userKycData?.area]);
    setArea(userKycData?.area);
    setUserName(userKycData?.fullName);
    setPanTypeValues([userKycData?.panType]);
    setSelectedType(userKycData?.panType);
    setPan2(userKycData?.pan);
    setTitle(userKycData?.title);
    setX(userKycData?.Kyc_method);
  }, [fullName]);

  const fetchPincodeData = (value) => {
    setPincode(value);
    if (/^\d{6}$/.test(value)) {
      fetch(`https://api.postalpincode.in/pincode/${value}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((PostOffice) => {
          setState(PostOffice[0]?.PostOffice[0]?.State || "");
          setDistrict(PostOffice[0]?.PostOffice[0]?.District || "");
          const area = PostOffice[0]?.PostOffice.map((post) => post.Name) || [];
          setLocality(area);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  return (
    <>
      {loader ? (
        <Loader1 />
      ) : (
        <>
          <form action="" onSubmit={handleRegister} className="font-size1">
            <h6 className="text-sm font-bold mb-2">
              How do you want to complete your KYC?
              <span className="text-danger">*</span>
            </h6>
            <div className="form-check flex items-center form-check-inline">
              <input
                className="form-check-input "
                type="radio"
                name="kyc_type"
                id="pan"
                value="pan"
                checked={x === "pan"}
                onChange={handleX}
                disabled={statusB === 5 || statusB === 10 || statusB === 15}
              />
              <label className="form-check-label" htmlFor="pan">
                PAN
              </label>
            </div>
            <div className="form-check flex items-center form-check-inline">
              <input
                className="form-check-input "
                type="radio"
                name="kyc_type"
                id="aadhaar"
                value="aadhaar"
                checked={x === "aadhaar"}
                onChange={handleX}
                disabled
                // disabled={statusB === 1 || statusB === 2 || statusB === 3}
              />
              <label className="form-check-label " htmlFor="aadhaar">
                Aadhaar
              </label>
            </div>
            <div className="border rounded-xl p-0 md:p-3 mt-3">
              <div className="flex flex-wrap justify-between px-3 py-1.5 mb-2 mx-auto">
                {/* 1 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    htmlFor="fullname"
                    className="font-semibold text-[#212121]"
                  >
                    Name<span className="text-danger">*</span>
                  </label>
                  <div className="border  ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-1 rounded-lg cursor-pointer flex justify-between items-center overflow-hidden ">
                    <div className="input-group-text m-0 p-0 rounded-none border-none text-sm select-border font-semibold  cursor-pointer px-1">
                      <select
                        className="text-sm select-border font-semibold m-0 cursor-pointer px-1"
                        name="types"
                        id="types"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        disabled={
                          statusB === 5 || statusB === 15 || nameStatus === true
                        }
                      >
                        <option value="">Title</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="M/s">M/s</option>
                      </select>
                    </div>
                    <input
                      className="form-control text-sm py-2 px-2 rounded-none border-none cursor-pointer font-semibold"
                      type="text"
                      id="fullname"
                      name="fullname"
                      value={userName}
                      required
                      onChange={(e) => {
                        if (statusB === 10 || statusB === 0) {
                          setUserName(e.target.value);
                        }
                      }}
                      disabled={
                        statusB === 5 || statusB === 15 || nameStatus === true
                      }
                    />
                  </div>
                </div>
                {/* 2 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label className="font-semibold text-[#212121]" htmlFor="dob">
                    DOB<span className="text-danger">*</span>
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="date"
                    name="dob"
                    id="dob"
                    value={dob}
                    disabled={
                      statusB === 5 || statusB === 15 || nameStatus === true
                    }
                    onChange={(e) => setDob(e.target.value)}
                  />
                </div>
                {/* 3 */}
                <div className="w-full lg:w-[49%] mt-2">
                  {x === "aadhaar" ? (
                    <>
                      <label
                        className="font-semibold text-[#212121]"
                        htmlFor="aadhaar"
                      >
                        Aadhaar No.<span className="text-danger">*</span>
                      </label>
                      <div className="input-group m-0 p-0 mt-1 ">
                        <input
                          className="  w-9/12 bg-[#eeeeee] text-sm px-2   rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center m-0 py-2 tracking-wider"
                          type="text"
                          id="aadhaar"
                          disabled={
                            statusB === 5 ||
                            statusB === 15 ||
                            nameStatus === true
                          }
                          name="aadhaar"
                          value={aadhaar}
                          onChange={(e) => setAadhaar(e.target.value)}
                          placeholder="XXXXXXXXXXXX"
                          required
                        />
                        <button className="bg-dark w-3/12 text-sm px-2 text-[#fff] rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center m-0 py-2">
                          Verify
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <label
                        className="font-semibold text-[#212121]"
                        htmlFor="pan"
                      >
                        PAN No.<span className="text-danger">*</span>
                      </label>
                      <div className="input-group m-0 p-0 mt-1">
                        <input
                          className="w-9/12 bg-[#eeeeee] text-sm px-2   rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center m-0 py-2 tracking-wider"
                          type="text"
                          id="pan"
                          name="pan"
                          value={pan2}
                          onChange={(e) => handlePanType(e.target.value)}
                          placeholder="XXXXXXXXXXXX"
                          disabled={
                            statusB === 5 ||
                            statusB === 15 ||
                            nameStatus === true
                          }
                        />
                        {statusB === 5 || statusB === 15 || nameStatus ? (
                          <span className="bg-green-500 w-3/12 text-sm px-2 text-[#fff] rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center m-0 py-2">
                            Verified
                          </span>
                        ) : loader2 ? (
                          <span className="bg-dark w-3/12 text-sm px-2 text-[#fff] rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center m-0 py-2">
                            <video
                              key="1"
                              className="userkyc-submit-btn remove-white-bg "
                              src="/images/1491.webm"
                              alt={""}
                              autoPlay
                              loop
                              muted
                              height={30}
                            />
                          </span>
                        ) : (
                          <span
                            className="bg-dark w-3/12 text-sm px-2 text-[#fff] rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center justify-center m-0 py-2"
                            onClick={() => verifyPan()}
                          >
                            Verify
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* 4 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="types"
                  >
                    Type<span className="text-danger">*</span>
                  </label>
                  <div>
                    <select
                      className="text-sm py-1.5 w-full px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1 "
                      name="types"
                      id="types"
                      value={selectedType}
                      required
                      onChange={handleTypeChange}
                      disabled={
                        statusB === 5 || statusB === 15 || nameStatus === true
                      }
                    >
                      {/* <option value="">Select from dropdown</option> */}
                      {panTypeValues.map((pantype, index) => (
                        <option key={index} value={pantype}>
                          {pantype}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* 5 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="email"
                  >
                    Email<span className="text-danger">*</span>
                  </label>
                  <div className="input-group m-0 p-0">
                    <input
                      className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      disabled={
                        statusB === 5 || statusB === 10 || statusB === 15
                      }
                      aria-label="email"
                    />
                  </div>
                </div>
                {/* 6 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="Phone"
                  >
                    Mobile<span className="text-danger">*</span>
                  </label>
                  <div className="border  ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-1 rounded-lg cursor-pointer flex justify-between items-center overflow-hidden ">
                    <span className="input-group-text m-0 p-0 rounded-none border-none text-sm select-border font-semibold  cursor-pointer px-1">
                      +91
                    </span>
                    <input
                      className="form-control text-sm py-2 px-2 rounded-none border-none cursor-pointer font-semibold "
                      type="text"
                      id="Phone"
                      name="Phone"
                      value={phone}
                      disabled={
                        statusB === 5 || statusB === 10 || statusB === 15
                      }
                      aria-label="mobile"
                    />
                  </div>
                </div>
                {/* 7 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="address1"
                  >
                    Address Line 1<span className="text-danger">*</span>
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="text"
                    id="address1"
                    name="address1"
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                    placeholder="House No./Block/Landmark"
                    disabled={statusB === 10 || statusB === 15}
                  />
                </div>
                {/* 8 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="address2"
                  >
                    Address Line 2
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="text"
                    id="address2"
                    name="address2"
                    value={addressLine2}
                    disabled={statusB === 10 || statusB === 15}
                    onChange={(e) => setAddressLine2(e.target.value)}
                    placeholder="House No./Block/Landmark"
                  />
                </div>
                {/* 9 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="pincode"
                  >
                    Pincode<span className="text-danger">*</span>
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="text"
                    id="pincode"
                    name="pincode"
                    value={pincode}
                    onChange={(e) => fetchPincodeData(e.target.value)}
                    required
                    disabled={statusB === 10 || statusB === 15}
                  />
                </div>
                {/* 10 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="locality"
                  >
                    Locality<span className="text-danger">*</span>
                  </label>
                  <div>
                    <select
                      className="text-sm py-1.5 w-full px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1 "
                      name="locality"
                      id="locality"
                      value={area}
                      disabled={statusB === 10 || statusB === 15}
                      onChange={(e) => setArea(e.target.value)}
                    >
                      <option value="">Select Locality</option>
                      {locality.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* 11 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="district"
                  >
                    District<span className="text-danger">*</span>
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="text"
                    id="district"
                    name="district"
                    placeholder="East Delhi"
                    value={district}
                    disabled={statusB === 10 || statusB === 15}
                    onChange={(e) => setDistrict(e.target.value)}
                    required
                  />
                </div>
                {/* 12 */}
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor="state"
                  >
                    State<span className="text-danger">*</span>
                  </label>
                  <input
                    className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    type="text"
                    id="state"
                    name="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    placeholder="New Delhi"
                    required
                    disabled={statusB === 10 || statusB === 15}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center py-3 ">
              <button
                className={` ${
                  loader3 || statusB === 15
                    ? "bg-[#717171] cursor-not-allowed"
                    : "cursor-pointer bg-[#5ab2ff] hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                } flex h-10 w-[120px] items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm   `}
                type="submit"
                disabled={loader3 || statusB === 15}
              >
                {loader3 ? (
                  <video
                    key="1"
                    className="userkyc-submit-btn remove-white-bg "
                    src="/images/btn-transparent.gif"
                    alt={""}
                    autoPlay
                    loop
                    muted
                  />
                ) : (
                  <>Save</>
                )}
              </button>
            </div>
          </form>
        </>
      )}

      {showModal1 && (
        <AlertGreen
          message="Your PAN is verified. You cannot change the KYC method."
          onClose={() => setShowModal1(false)}
        />
      )}
      {showModal2 && (
        <AlertRed
          message="Your PAN is not verified. Try again with correct credentials."
          onClose={() => setShowModal2(false)}
        />
      )}
      {showModal3 && (
        <AlertRed
          message="You cannot change your PAN."
          onClose={() => setShowModal3(false)}
        />
      )}
      {showModal4 && (
        <AlertYellow
          message="Please login again!"
          onClose={() => setShowModal4(false)}
        />
      )}
      {showModal5 && (
        <AlertYellow
          message="Please verify your PAN first!"
          onClose={() => setShowModal5(false)}
        />
      )}

      {message !== "" &&
        (message.includes("already submitted") ? (
          <AlertRed
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ) : message.includes("successfully") ? (
          <AlertGreen
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ) : (
          <AlertYellow
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ))}
      {message2 !== "" &&
        (message2 === "not verified" ? (
          <AlertRed
            message={message2}
            onClose={() => {
              setMessage2("");
            }}
          />
        ) : message2 === "verified" ? (
          <AlertGreen
            message={message2}
            onClose={() => {
              setMessage2("");
            }}
          />
        ) : (
          <AlertYellow
            message={message2}
            onClose={() => {
              setMessage2("");
            }}
          />
        ))}
    </>
  );
}
export default User_Details;
