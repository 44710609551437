import React, { useState, useEffect } from "react";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import BidBox from "../../components/PrimaryMarketComponents/BidBox";
import BidConfirmationBox from "./BidConfirmationBox";
import secureLocalStorage from "react-secure-storage";
import { index } from "d3";

function CompleteOrderTable({ head, tabHead1, tabHead2 }) {
  const { isAuthenticated, pan } = CheckAuth();
  const [showOrder, setShowOrder] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState(false);
  const [index2, setIndex2] = useState(null);
  const [bidConfirm, setBidConfirm] = useState(-1);
  const [showBidBox, setShowBidBox] = useState(-1);
  const [bidBoxStatus, setBidBoxStatus] = useState("");
  const [timestamp, setTimestamp] = useState();
  const [orderId, setOrderId] = useState("");
  const [name, setName] = useState("");
  const [unit, setUnit] = useState(0);
  const [price, setPrice] = useState(0);
  const [cover, setCover] = useState("");
  const [action2, setAction2] = useState("");
  const [btn, setBtn] = useState(-1);
  const [coverPrice, setCoverPrice] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = startIndex + entriesPerPage;
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [filteredTransactions2, setFilteredTransactions2] = useState([]);
  const [series, setSeries] = useState([]);
  const [cities, setCities] = useState([]);
  const storedData = secureLocalStorage.getItem("blockchainData");
  const storedData2 = secureLocalStorage.getItem("tempData");
  const storedData3 = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData3);
  const storedData1 = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData1);
  const blockchainData = JSON.parse(storedData);
  const relationshipDate = kycData?.["Relationship_start"];
  const storedData4 = secureLocalStorage.getItem("ownershipData");
  const ownershipData = JSON.parse(storedData4);

  function getHoldingPeriod(productName, category) {
    if (category === "Index") {
      var Days = [];
      var residex_units_calculated = [];
      const sortedCouponData = { pan: couponData?.pan };
      if (couponData) {
        const dateKeys = Object.keys(couponData).filter((key) => key !== "pan");
        const updatedDateKeys = dateKeys.map((key) => {
          const date = new Date(key);
          const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
          const formattedDate = date.toLocaleDateString("en-IN", options);
          sortedCouponData[formattedDate] = couponData[key];
          return formattedDate;
        });
        updatedDateKeys.sort((a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          return dateA - dateB;
        });
        Object.entries(sortedCouponData).forEach(([key, value], index) => {
          if (key !== "pan") {
            let totalBuy = 0;
            let totalSell = 0;
            let totalSum = 0;
            let totalFutures = 0;
            let totalIndexes = 0;
            if (value && value.RESIDEX) {
              totalFutures += value.RESIDEX.Futures || 0;
              totalIndexes += value.RESIDEX.Index || 0;
            }
            const startDate = new Date(relationshipDate);
            const [day, month, year] = key.split("/");
            const endDate = new Date(`20${year}-${month}-${day}`);
            const today = new Date();
            //  calculating days
            const timeDifference = today.getTime() - endDate.getTime();
            const daysDifference =
              timeDifference > 0
                ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
                : 0;

            Days.push({ [`D${index}`]: daysDifference / 91 });
            //
            function isDateInRange(date, startDate, endDate) {
              const dateStart = new Date(date.setHours(0, 0, 0, 0));
              const start = new Date(startDate.setHours(0, 0, 0, 0));
              const end = new Date(endDate.setHours(0, 0, 0, 0));

              return dateStart >= start && dateStart <= end;
            }

            blockchainData.filter((item, index) => {
              const itemDate = new Date(item.Timestamp);
              if (
                item.Buyer === couponData.pan &&
                item["Security"] === "RESIDEX City " + productName &&
                isDateInRange(itemDate, startDate, endDate)
              ) {
                totalBuy += item["Units"];
              }
            });

            blockchainData.filter((item, index) => {
              const itemDate = new Date(item.Timestamp);
              if (
                item.Seller === couponData.pan &&
                item["Security"] === "RESIDEX City " + productName &&
                isDateInRange(itemDate, startDate, endDate)
              ) {
                totalSell += item["Units"];
              }
            });
            totalSum = totalBuy - totalSell;
            if (totalSum !== 0) {
              residex_units_calculated.push({ [`X${index}`]: totalSum });
            }
          }
        });
      }

      let sumProd = 0;
      if (Days.length > 0 && Days.length === residex_units_calculated.length) {
        for (let i = 0; i < Days.length; i++) {
          const dayValue = Object.values(Days[i])[0];
          const residexValue = Object.values(residex_units_calculated[i])[0];
          sumProd += dayValue * residexValue;
        }
        return sumProd / ownershipData?.RESIDEX?.[productName]?.["Index"];
      } else {
        return 0;
      }
    } else {
      const today = new Date();
      const currentYear = today.getFullYear();
      let endDate;
      const currentMonth = today.getMonth() + 1;

      if (currentMonth >= 4 && currentMonth <= 6) {
        // Q1: April to June
        endDate = new Date(currentYear, 5, 30); // June 30
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        // Q2: July to September
        endDate = new Date(currentYear, 8, 30); // September 30
      } else if (currentMonth >= 10 && currentMonth <= 12) {
        // Q3: October to December
        endDate = new Date(currentYear, 11, 31); // December 31
      } else {
        // Q4: January to March (of the next year)
        endDate = new Date(currentYear + 1, 2, 31); // March 31 (next year)
      }

      // Calculate the time difference
      const timeDifference = endDate.getTime() - today.getTime();
      const daysDifference =
        timeDifference > 0
          ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
          : 0;
      return daysDifference / 91;
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetch(`https://api-scdndestates.com/api/primary-bid-box/`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          setSeries(data.data);
          setCities(data.data2);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });

      if (storedData && storedData2) {
        const blockchainData = JSON.parse(storedData);
        const tempData = JSON.parse(storedData2);
        let filtered = blockchainData
          .filter((row) => row.Order_id?.includes("PMY-SCD"))
          .sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
        setFilteredTransactions(filtered);
        const filtered2 = tempData.filter((row) =>
          row.Order_id?.includes("PMY-SCD")
        );
        setFilteredTransactions2(filtered2);
      }
    }
  }, [pan, showBidBox, bidConfirm, isAuthenticated]);

  const currentTransactions = filteredTransactions.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredTransactions.length / entriesPerPage);

  const currentTransactions2 = filteredTransactions2.slice(
    startIndex,
    endIndex
  );
  const totalPages2 = Math.ceil(filteredTransactions2.length / entriesPerPage);

  const handleNextPage = () => {
    if (head === "Open Orders")
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages2));
    else setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const modifyOrder = (
    action,
    names,
    units,
    prices,
    orderNo,
    time,
    val,
    index,
    status
  ) => {
    if (action === "Edit Order") {
      if (showBidBox === index) {
        setShowBidBox(-1);
      } else {
        setShowBidBox(index);
      }
      setUnit(units);
    }
    if (action === "Delete Order") {
      const now = new Date();
      const currentHours = now.getHours();
      const currentMinutes = now.getMinutes();
      const cutoffHours = 10;
      const cutoffMinutes = 30;
      const cutoffHours2 = 18;
      const cutoffMinutes2 = 30;
      const isBeforeFirstCutoff =
        currentHours < cutoffHours ||
        (currentHours === cutoffHours && currentMinutes < cutoffMinutes);
      const isAfterSecondCutoff =
        currentHours > cutoffHours2 ||
        (currentHours === cutoffHours2 && currentMinutes > cutoffMinutes2);

      setShowBidBox(-1);
      if (isBeforeFirstCutoff || isAfterSecondCutoff) {
        setErrorMessage2(true);
      } else {
        setBidConfirm(index);
        setIndex2(index);
        setUnit(units);
      }
    }
    setBidBoxStatus(status);
    setAction2(action);
    setOrderId(orderNo);
    setName(names);
    setBtn(val);
    setTimestamp(time);
    if (names.slice(0, 4) === "WALR") {
      setPrice(1000);
      if (prices > 1000) {
        setCover("yes");
        setCoverPrice(prices - 1000);
      } else {
        setCover("no");
        setCoverPrice(0);
      }
    } else {
      if (prices > 1000) {
        setPrice(0);
        setCover("futures");
        setCoverPrice(prices);
      } else {
        setPrice(prices);
        setCover("index");
        setCoverPrice(prices);
      }
    }
  };

  const popOrder = (name, unit, price) => {
    setShowOrder(true);
    setName(name);
    setUnit(unit);
    setPrice(price);
  };

  function getMaturityDate(series, productName) {
    const p = productName.split(" ").pop();
    const productData = series.find((item) => Object.keys(item)[0] === p);
    return productData ? productData[p]["Maturity Date"] : "";
  }

  function getCouponDate(series, productName) {
    const p = productName.split(" ").pop();
    const productData = series.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["Coupon post reset"] : "";
    if (typeof couponIssue === "number") {
      return parseFloat(couponIssue.toFixed(2));
    }
    return couponIssue;
  }

  function getCouponIssue(series, productName) {
    const p = productName.split(" ").pop();
    const productData = series.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["Coupon at Issue"] : "";
    if (typeof couponIssue === "number") {
      return parseFloat(couponIssue.toFixed(2));
    }
    return couponIssue;
  }

  function getCAGR(cities, productName) {
    // Extract the product name without spaces
    const p = productName.slice(13);
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    const cagr = productData ? productData[p]["CAGR"] : "";

    if (typeof cagr === "number") {
      const formattedCAGR = cagr.toFixed(2);
      const [integerPart, decimalPart] = formattedCAGR.split(".");
      if (parseInt(decimalPart) === 0) {
        return integerPart;
      }
      return formattedCAGR;
    }
    return cagr;
  }

  function getLatestNav(cities, productName) {
    const p = productName.slice(13);
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    const cagr = productData ? productData[p]["Price"] : "";
    return cagr;
  }

  const handleCloseBidConfirmation = () => {
    setBidConfirm(-1);
    setShowBidBox(-1);
  };

  return (
    <>
      <div className="lg:flex gap-4 py-2 table-media lg:pl-5">
        <div className="w-full lg:w-[65%] border p-3 shadow-3 rounded-xl lg:mb-0 ">
          <div className="flex items-center justify-between flex-wrap gap-2 m-0 p-0 ">
            <span className="font-bold text-black text-lg 2xl:text-xl">
              {head}
            </span>
            <div className="d-flex justify-content-around align-content-center ">
              <select
                className="border rounded-md m-0 p-0 text-sm lg:text-xs 2xl:text-base w-fit"
                value={entriesPerPage}
                onChange={(e) => {
                  setEntriesPerPage(parseInt(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option value="5">5 entries</option>
                <option value="10">10 entries</option>
                <option value="20">20 entries</option>
                <option value="50">50 entries</option>
              </select>
              {currentPage > 1 ? (
                <>
                  <div
                    className="btn btn-sm p-0 btn-secondary "
                    onClick={() => handlePrevPage()}
                  >
                    <BiSkipPrevious size={25} color="#000" />
                  </div>
                  <div className="mx-3 my-0 ">{currentPage - 1}</div>
                </>
              ) : (
                <>
                  <div className="">
                    <BiSkipPrevious size={25} color="#fff" />
                  </div>
                  <div className="mx-3 my-0 text-light ">{currentPage}</div>
                </>
              )}
              <div
                className="mx-1 my-0 border px-2 py-0 rounded "
                style={{ backgroundColor: "#d8e5f9" }}
              >
                {currentPage}
              </div>
              {currentPage <
              (head === "Open Orders" ? totalPages2 : totalPages) ? (
                <>
                  <div className="mx-3 my-0 ">{currentPage + 1}</div>

                  <div
                    className="btn p-0 btn-sm btn-secondary"
                    onClick={() => handleNextPage(1)}
                  >
                    <BiSkipNext size={25} color="#000" />
                  </div>
                </>
              ) : (
                <>
                  <div className="mx-3 my-0 text-light">{currentPage}</div>

                  <div className="">
                    <BiSkipNext size={25} color="#fff" />
                  </div>
                </>
              )}
            </div>
          </div>
          <hr className="m-0 p-0 my-2" />
          <div className="row m-0 p-0 overflow-scroll">
            <table className="table-responsive table min-w-[990px] table-hover table-borderless table-sm border-dark">
              <thead className="">
                <tr
                  className="text-dark"
                  style={{ backgroundColor: "#cbdcf7bd" }}
                >
                  <th
                    scope="col"
                    className="text-xs 2xl:text-sm fw-bolder text-center"
                  >
                    {tabHead1}
                  </th>
                  <th
                    scope="col"
                    className="text-xs 2xl:text-sm fw-bolder text-center"
                  >
                    Timestamp
                  </th>
                  <th
                    scope="col"
                    className="text-xs 2xl:text-sm fw-bolder text-center"
                  >
                    Security Name
                  </th>
                  <th
                    scope="col"
                    className="text-xs 2xl:text-sm fw-bolder text-center"
                  >
                    Units
                  </th>
                  <th
                    scope="col"
                    className="text-xs 2xl:text-sm fw-bolder text-center"
                  >
                    Price/Unit (₹)
                  </th>
                  <th
                    scope="col"
                    className="text-xs 2xl:text-sm fw-bolder text-center"
                  >
                    Total amount (₹)
                  </th>
                  <th
                    scope="col"
                    className="text-xs 2xl:text-sm fw-bolder text-center"
                  >
                    Action
                  </th>
                  {tabHead2 !== "" && (
                    <th
                      scope="col"
                      className="text-xs 2xl:text-sm fw-bolder text-center"
                    >
                      {tabHead2}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="">
                {head === "Open Orders" ? (
                  <>
                    {Array.isArray(currentTransactions2) &&
                    currentTransactions2.length > 0 ? (
                      currentTransactions2.map((data, index) => (
                        <>
                          <tr key={index} className="shadow-sm h-10 ">
                            <td className="text-center text-xs 2xl:text-sm py-2">
                              {data["Status"] === "Revoked" ? (
                                ""
                              ) : (
                                <span className="flex">
                                  <span
                                    className="btn btn-sm btn-danger p-1 me-1"
                                    onClick={() => {
                                      data["Security"] &&
                                        modifyOrder(
                                          "Delete Order",
                                          data["Security"],
                                          data["Units"],
                                          data["Price_per_unit"],
                                          data["Order_id"],
                                          data["Timestamp"],
                                          data["Seller"] === pan ? 0 : 1,
                                          index,
                                          data["Status"]
                                        );
                                    }}
                                  >
                                    <RiDeleteBin6Fill size={18} />
                                  </span>
                                  <span
                                    className="btn btn-sm btn-primary p-1 ms-1"
                                    onClick={() => {
                                      data["Security"] &&
                                        modifyOrder(
                                          "Edit Order",
                                          data["Security"],
                                          data["Units"],
                                          data["Price_per_unit"],
                                          data["Order_id"],
                                          data["Timestamp"],
                                          data["Seller"] === pan ? 0 : 1,
                                          index,
                                          data["Status"]
                                        );
                                    }}
                                  >
                                    <MdEdit size={18} />
                                  </span>
                                </span>
                              )}
                            </td>
                            <td className="text-center text-xs 2xl:text-sm py-2">
                              {}
                              {data["Timestamp"]
                                ? data["Timestamp"].replace("T", " ")
                                : ""}
                            </td>
                            <td
                              className="text-center text-xs 2xl:text-sm text-primary cursor-pointer-style"
                              onClick={() => {
                                data["Security"] &&
                                  popOrder(
                                    data["Security"],
                                    data["Units"],
                                    data["Price_per_unit"]
                                  );
                              }}
                            >
                              {data["Security"] ? data["Security"] : ""}
                            </td>
                            <td className="text-center text-xs 2xl:text-sm">
                              {data["Units"] ? data["Units"] : ""}
                            </td>
                            <td className="text-center text-xs 2xl:text-sm">
                              {data["Price_per_unit"]
                                ? data["Price_per_unit"].toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : ""}
                            </td>
                            <td className="text-center text-xs 2xl:text-sm">
                              {data["Price_per_unit"]
                                ? (
                                    data["Units"] * data["Price_per_unit"]
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </td>
                            <td
                              className={`text-center text-xs 2xl:text-sm ${
                                data["Seller"] === pan
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {data["Seller"] === pan ? "Redeem" : "Purchased"}
                            </td>
                            <td
                              className={`text-center text-xs 2xl:text-sm ${
                                data["Status"] === "Processing"
                                  ? "text-primary"
                                  : data["Status"] === ("Revised" || "Modified")
                                  ? "text-warning"
                                  : data["Status"] ===
                                    ("Revoked" || "Cancelled")
                                  ? "text-danger"
                                  : data["Status"] === "Executed"
                                  ? "text-success"
                                  : data["Status"] === "Pending" &&
                                    "text-secondary"
                              }`}
                            >
                              {data["Status"] !== "Pending"
                                ? data["Status"]
                                : "Pending"}
                            </td>
                          </tr>

                          {bidConfirm === index && (
                            <BidConfirmationBox
                              formName={action2}
                              Index={index}
                              BidUnit={unit}
                              OldBidUnit={unit}
                              Time={timestamp}
                              OrderNo={orderId}
                              PerUnit={price}
                              Product={name}
                              Cover={cover}
                              CoverPrice={parseFloat(coverPrice.toFixed(2))}
                              Btn={btn}
                              onClose={() => {
                                setIndex2(null);
                                handleCloseBidConfirmation();
                              }}
                              bidStatus={bidBoxStatus}
                            />
                          )}
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">
                          <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden ">
                            <img
                              className="w-32 h-32 "
                              src="/images/no-info.svg"
                              alt=""
                            />
                            <span className="text-center text-xs md:text-sm   font-semibold">
                              {" "}
                              You don't have any Coupon Statements.
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <>
                    {Array.isArray(currentTransactions) &&
                    currentTransactions.length > 0 ? (
                      currentTransactions.map((data, index) => (
                        <>
                          <tr key={index} className="shadow-sm">
                            <td className="text-center text-xs 2xl:text-sm">
                              {data["Order_id"]
                                ? "xxxxxxxxx" + data["Order_id"]?.slice(-3)
                                : ""}
                            </td>
                            <td className="text-center text-xs 2xl:text-sm">
                              {data["Timestamp"]
                                ? data["Timestamp"].replace("T", " ")
                                : ""}
                            </td>
                            <td
                              className="text-center text-xs 2xl:text-sm text-primary cursor-pointer-style"
                              onClick={() =>
                                data["Security"] &&
                                popOrder(
                                  data["Security"],
                                  data["Units"],
                                  data["Price_per_unit"]
                                )
                              }
                            >
                              {data["Security"] ? data["Security"] : ""}
                            </td>
                            <td className="text-center text-xs 2xl:text-sm">
                              {data["Units"] ? data["Units"] : ""}
                            </td>
                            <td className="text-center text-xs 2xl:text-sm">
                              {data["Price_per_unit"]
                                ? data["Price_per_unit"].toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : ""}
                            </td>
                            <td className="text-center text-xs 2xl:text-sm">
                              {data["Price_per_unit"]
                                ? (
                                    data["Units"] * data["Price_per_unit"]
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </td>
                            <td
                              className={`text-center text-xs 2xl:text-sm ${
                                data["Seller"] === pan
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {data["Seller"] === pan ? "Redeem" : "Purchased"}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">
                          <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden">
                            <img
                              className="w-32 h-32 "
                              src="/images/no-info.svg"
                              alt=""
                            />
                            <span className="text-center text-xs md:text-sm   font-semibold">
                              {" "}
                              You don't have any Coupon Statements.
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {head === "Open Orders" && showBidBox !== -1 && (
          <div className="w-full lg:max-w-[38%] xl:max-w-[400px] max-lg:mt-5">
            <BidBox
              Action2={action2}
              timestamp={timestamp}
              orderId={orderId}
              product={name}
              cover={cover}
              oldUnit={unit}
              option={parseFloat(coverPrice.toFixed(2))}
              btn={btn}
              head={head}
              onClose2={() => {
                setShowBidBox(false);
              }}
              bidStatus={bidBoxStatus}
            />
          </div>
        )}
        {showOrder && (
          <>
            <div className="demo1-container">
              <div className="border bid-verify rounded bg-light border-primary pop-up-animation">
                <div className="row m-0 p-0 mb-2 bg-primary">
                  <h6 className="text-light p-2 pb-1 font-bold">Your Order </h6>
                </div>
                <div className="row m-0 px-4 py-2">
                  <table className="table-responsive table table-hover table-borderless table-sm">
                    <tbody>
                      <tr style={{ background: "#54b4d324" }}>
                        <td className="font-size1 font-semibold">
                          Security Name
                        </td>
                        <td className="font-size1 font-semibold">{name}</td>
                      </tr>
                      <tr>
                        {name.slice(0, 4) === "WALR" ? (
                          <>
                            <td className="font-size1 font-semibold">
                              Maturity Date
                            </td>
                            <td className="font-size1 font-semibold">
                              {getMaturityDate(series, name)}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="font-size1 font-semibold">
                              Holding Period
                            </td>
                            <td className="font-size1 font-semibold">
                              {getHoldingPeriod(
                                name.slice(13),
                                price > 1000 ? "Futures" : "Index"
                              ).toFixed(2)}
                            </td>
                          </>
                        )}
                      </tr>
                      <tr style={{ background: "#54b4d324" }}>
                        {name.slice(0, 4) === "WALR" ? (
                          <>
                            <td className="font-size1 font-semibold">
                              Coupon at Issue
                            </td>
                            <td className="font-size1 font-semibold">
                              {getCouponIssue(series, name)}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="font-size1 font-semibold">CAGR</td>
                            <td className="font-size1 font-semibold">
                              {getCAGR(cities, name)}
                            </td>
                          </>
                        )}
                      </tr>
                      <tr>
                        {name.slice(0, 4) === "WALR" ? (
                          <>
                            <td className="font-size1 font-semibold">
                              Coupon Post Reset
                            </td>
                            <td className="font-size1 font-semibold">
                              {getCouponDate(series, name)}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="font-size1 font-semibold">
                              LatestNav
                            </td>
                            <td className="font-size1 font-semibold">
                              {getLatestNav(cities, name)}
                            </td>
                          </>
                        )}
                      </tr>
                      <tr style={{ background: "#54b4d324" }}>
                        <td className="font-size1 font-semibold">Bid Unit</td>
                        <td className="font-size1 font-semibold">{unit}</td>
                      </tr>
                      <tr>
                        <td className="font-size1 font-semibold">Unit Price</td>
                        <td className="font-size1 font-semibold">
                          {name.slice(0, 4) === "WALR" ? (
                            <>{(1000).toLocaleString("en-In")}</>
                          ) : (
                            <>{price > 1000 ? 0 : price}</>
                          )}
                        </td>
                      </tr>
                      <tr style={{ background: "#54b4d324" }}>
                        {name.slice(0, 4) === "WALR" ? (
                          <>
                            <td className="font-size1 font-semibold">
                              Downside Cover
                            </td>
                            <td className="font-size1 font-semibold">
                              {price > 1000 ? "Yes" : "No"}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="font-size1 font-semibold">
                              Instrument Class
                            </td>
                            <td className="font-size1 font-semibold">
                              {price > 1000 ? "Futures" : "Index"}
                            </td>
                          </>
                        )}
                      </tr>
                      <tr>
                        {name.slice(0, 4) === "WALR" ? (
                          <>
                            <td className="font-size1 font-semibold">
                              Option Price
                            </td>
                            <td className="font-size1 font-semibold">
                              {(price - 1000).toLocaleString("en-In")}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="font-size1 font-semibold">
                              3 mth Premium
                            </td>
                            <td className="font-size1 font-semibold">
                              {(price > 1000 ? price / 1000 : 0).toLocaleString(
                                "en-In"
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                      <tr
                        className="border-bottom"
                        style={{ background: "#54b4d324" }}
                      >
                        <td className="font-size1 font-semibold">
                          Total Bid Amount
                        </td>
                        <td className="font-size1 font-semibold">
                          {(unit * price).toLocaleString("en-In")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end p-3 pt-0 align-items-center">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm font-bold"
                    data-mdb-ripple-init
                    data-mdb-ripple-color="dark"
                    onClick={() => setShowOrder(false)}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {errorMessage2 && (
          <>
            <div className="demo10-container">
              <div className="border flex-col justify-center p-4 items-center rounded bg-light border-primary">
                 <video
                    className="remove-white-bg h-52 w-52"
                    src="/images/overdue.webm"
                    alt={""}
                    autoPlay
                    loop
                    muted
                  />
                <div className="">
                  <h4 className="text-center">
                    Sorry you can only place or modify orders while the Auction
                    window is open. <br />
                    Kindly try again tomorrow between 10:30 am to 6:30 pm.
                  </h4>
                </div>
                <div className="row m-0 p-2">
                  <div className="d-flex justify-content-end p-3 align-items-center">
                    <button
                      className="btn-primary btn btn-sm"
                      onClick={() => {
                        setErrorMessage2(false);
                      }}
                    >
                      Go back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CompleteOrderTable;
