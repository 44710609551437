import React, { useEffect } from "react";
import Content from "../../components/Content/Content";
import Header from "../../components/Header/Header";
import FAQ from "./FAQ";
import { Helmet } from "react-helmet";

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Helmet>
        <title>Investment types we offer - Products in SCDND Estates</title>
        <meta
          name="description"
          content="WALR is the decentralized version of Fixed Income, and RESIDEX is the decentralized version of Real Estate. Read about the special features of our synthetic ETFs that makes it a huge upgrade over traditional and centralized investments."
        />
        <meta
          name="description"
          content="Discover the future of securities trading with Decentralized Assets. Our synthetic ETFs offer a groundbreaking alternative to traditional, centralized fixed-income investments with WALR, and real estate with RESIDEX."
        />
        <meta
          name="description"
          content="Take an overview of the two distinct types of Decentralized Assets (DeAs) and their historical performances, i.e. WALR and RESIDEX, which are the next-generation embodiments of fixed-income and real estate securities."
        />
        <meta
          name="description"
          content="Experience our innovative investment offerings: WALR for decentralized fixed-income, and RESIDEX for decentralized real estate powered by distributed ledger technology relacing old-school centralized trading infrastructure."
        />
        <link rel="canonical" href="https://scdndestates.com/products/" />

        {/* Open Graph */}
        <meta property="og:title" content="Investment types we offer - Products" />
        <meta
          property="og:description"
          content="Become a marketing professional for Decentralized Assets. Join our team and drive sales of the innovative investment products to your client-base, by leveraging your expertise and existing networks."
        />
        <meta
          property="og:site_name"
          content="scdndestates/products - walr and residex"
        ></meta>
        <meta
          property="og:url"
          content="https://scdndestates.com/products/"
        />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Investment types we offer - Products" />
        <meta name="twitter:domain" content="scdndestates.com" />
        <meta
          name="twitter:description"
          content="Become a marketing professional for Decentralized Assets. Join our team and drive sales of the innovative investment products to your client-base, by leveraging your expertise and existing networks."
        />
      </Helmet>
      <Header />
      <div className="after-header">
        <div className="container-fluid">
          <div className="row">
            <div className="max-w-[1800px] mx-auto">
              <Content />
              <FAQ />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
