import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { jsPDF } from "jspdf";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import Header from "../../components/Header/Header";
import PopUp1 from "../../components/PopUp1/PopUp1";
import "jspdf-autotable";
import secureLocalStorage from "react-secure-storage";

function StatementPage() {
  const { email, phone, pan, isAuthenticated, cash } = CheckAuth();
  const location = useLocation();

  const [fromDate, setFromDate] = useState(
    location.state?.StatementData?.fromDate
      ? new Date(location.state?.StatementData?.fromDate)
      : new Date() // default to today if not available
  );
  const [toDate, setToDate] = useState(
    location.state?.StatementData?.toDate
      ? new Date(location.state?.StatementData?.toDate)
      : new Date() // default to today if not available
  );

  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);
  const storedData2 = secureLocalStorage.getItem("blockchainData");
  const blockchainData = JSON.parse(storedData2);
  const fullName = userKycData?.fullName || "";
  let runningBalance = 0;

  const modifiedTransactions = blockchainData
    ?.sort((b, a) => new Date(b.Timestamp) - new Date(a.Timestamp))
    ?.map((data) => {
      let amount = data?.Units * data?.Price_per_unit;
      if (data?.Security === "Failure") {
        runningBalance = runningBalance + 0;
      } else {
        if (data?.Seller === pan) {
          runningBalance += amount;
        } else if (data?.Buyer === pan) {
          runningBalance -= amount;
        }
      }
      return {
        ...data,
        Balance: runningBalance,
      };
    });

  const totalTransactions = modifiedTransactions.sort(
    (a, b) => new Date(b.Timestamp) - new Date(a.Timestamp)
  );

  // Filter transactions before pagination
  const filteredTransactions = totalTransactions.filter((data) => {
    const transactionDate = new Date(data.Timestamp);
    const normalizeDate = (date) =>
      new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const normalizedTransactionDate = normalizeDate(transactionDate);
    const normalizedFromDate = normalizeDate(fromDate);
    const normalizedToDate = normalizeDate(toDate);
    return (
      normalizedTransactionDate >= normalizedFromDate &&
      normalizedTransactionDate <= normalizedToDate
    );
  });

  // Apply pagination after filtering
  const startIndex = (currentPage - 1) * entriesPerPage;
  const paginatedTransactions = filteredTransactions.slice(
    startIndex,
    startIndex + entriesPerPage
  );

  const handleNextPage = () => {
    const totalPages = Math.ceil(filteredTransactions.length / entriesPerPage);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleDownload = () => {
    const pdf = new jsPDF();
    const logo = "/images/logo01.webp";

    pdf.addImage(logo, "PNG", 15, 10, 17, 17);
    pdf.setTextColor("#212121");
    pdf.setFontSize(11);
    pdf.setFont("helvetica", "bold");
    pdf.text("SCDND ESTATES PVT LTD", 123, 15);
    pdf.setFontSize(8);
    pdf.setFont("helvetica", "normal");
    pdf.text("CIN: U70109WB2016PTC215755", 123, 19);
    const address =
      "Corporate office: C-267 Ground Floor 5th Cross Street Swarn Nagri Greater Noida Uttar Pradesh- 201310";
    const splitAddress = pdf.splitTextToSize(address, 70);
    pdf.text(splitAddress, 123, 23);

    pdf.setTextColor("#007ACC");
    pdf.setFontSize(16);
    pdf.setFont("helvetica", "bold");
    pdf.text("Statement", 14, 40);

    pdf.setDrawColor("#004080");
    pdf.setLineWidth(0.3);
    pdf.line(14, 42, 195, 42);

    pdf.setFontSize(9);
    pdf.setTextColor("#004080");
    pdf.setFont("helvetica", "normal");
    pdf.text("Account Holder's Name:", 14, 48);

    pdf.setFontSize(9);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor("#212121");
    pdf.text(fullName, 51, 48);

    // Set background color for the rectangle
    pdf.setFillColor(230, 230, 250); // Light lavender background

    // Draw a rounded rectangle around the text (x, y, width, height, radius)
    pdf.roundedRect(123, 45, 60, 6, 1, 3, "FD"); // 'FD' stands for Fill and Draw (border + background)

    // Set font and text color for "Wallet Balance"
    pdf.setFontSize(9);
    pdf.setTextColor("#004080");
    pdf.setFont("helvetica", "normal");
    pdf.text("Wallet Balance:", 125, 49);

    // Set font and text color for the actual balance
    pdf.setFontSize(9);
    pdf.setTextColor("#212121");
    pdf.setFont("helvetica", "bold");
    pdf.text(
      `Rs.${cash.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      148,
      49
    );
    pdf.setFontSize(9);
    pdf.setTextColor("#004080");
    pdf.setFont("helvetica", "normal");
    pdf.text("Email:", 14, 53);

    pdf.setFontSize(9);
    pdf.setTextColor("#212121");
    pdf.setFont("helvetica", "bold");
    pdf.text(email, 51, 53);

    pdf.setFontSize(9);
    pdf.setTextColor("#004080");
    pdf.setFont("helvetica", "normal");
    pdf.text("Phone:", 14, 58);

    pdf.setFontSize(9);
    pdf.setTextColor("#212121");
    pdf.setFont("helvetica", "bold");
    pdf.text(phone, 51, 58);

    const headers = [
      [
        { content: "Date", styles: { fillColor: "#D6EAF8" } },
        { content: "UTR NO./Transaction Id", styles: { fillColor: "#D6EAF8" } },
        { content: "Security", styles: { fillColor: "#D6EAF8" } },
        { content: "Credit (Rs.)", styles: { fillColor: "#D6EAF8" } },
        { content: "Debit (Rs.)", styles: { fillColor: "#D6EAF8" } },
        { content: "Balance (Rs.)", styles: { fillColor: "#D6EAF8" } },
      ],
    ];

    const rows = filteredTransactions?.map((data) => [
      data.Timestamp?.replace("T", " ")?.split(".")[0],
      data?.Order_id || "",
      data?.Security || "",
      data?.Seller === pan
        ? (data?.Price_per_unit * data?.Units).toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 0,
      data?.Buyer === pan
        ? (data?.Price_per_unit * data?.Units).toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 0,
      data.Balance.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || 0,
    ]);

    // Style the table with light shades of blue and thin borders
    pdf.autoTable({
      head: headers,
      body: rows,
      startY: 65,
      styles: {
        fontSize: 7.8,
        halign: "center",
        textColor: "#004080", // Deep blue text for table
      },
      headStyles: {
        fillColor: "#D6EAF8", // Light blue header background
        textColor: "#004080", // Dark blue text in headers
      },
      alternateRowStyles: {
        fillColor: "#F4F6F7", // Light grey for alternating rows
      },
      tableLineColor: "#BFC9CA", // Grey borders
      tableLineWidth: 0.1,
    });

    pdf.save("wallet_statement.pdf");
  };

  const formattedCash = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(cash);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  };

  return (
    <>
      {!isAuthenticated ? (
        <PopUp1 />
      ) : (
        <div className="mx-auto px-0 m-0 p-0">
          <Header />
          <div className="row m-0 "></div>
          <div className="container w-[90%] after-header">
            <div className="py-2 w-full flex mt-4">
              {/* All Links */}
              <div className="col-12">
                <h6 className=" m-0 p-0 mx-2">
                  <a href="/">
                    <span className="header-link ">Home</span>
                    <span className="text-secondary "> &gt; </span>
                  </a>
                  <a href="/moneyWallet">
                    <span className="header-link ">Money Wallet</span>
                    <span className="text-secondary "> &gt; </span>
                  </a>
                  <a href="#">
                    <span className=" underline text-black">
                      Statements
                    </span>
                  </a>
                </h6>
              </div>
            </div>
            <div className="mt-2 border rounded-lg p-3">
              <div className="flex flex-wrap justify-between items-center m-0 p-0 pb-3">
                <div>
                  <h4 className="font-bold text-xl ">Statement</h4>
                </div>
                <div>
                  <form className="flex justify-between">
                    <div className="border ps-2 py-0 border-1 rounded me-2">
                      <span className="me-2 font-semibold text-sm border-end pe-2">
                        From
                      </span>
                      <input
                        type="date"
                        value={fromDate.toISOString().split("T")[0]} // Format to YYYY-MM-DD
                        onChange={(e) => setFromDate(new Date(e.target.value))}
                        className="d-inline-block font-medium text-sm border-0"
                      />
                    </div>
                    <div className="border ps-2 py-0 border-1 rounded me-2">
                      <span className="me-2 font-semibold text-sm border-end pe-2">
                        To
                      </span>
                      <input
                        type="date"
                        value={toDate.toISOString().split("T")[0]} // Format to YYYY-MM-DD
                        onChange={(e) => setToDate(new Date(e.target.value))}
                        className="d-inline-block font-medium text-sm border-0"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <hr />
              <div className="row m-0 p-0 mt-4 flex gap-2 justify-between">
                <h6 className=" text-sm lg:text-base font-semibold flex items-center text-gray-700 bg-sky-200 p-2 rounded-md w-max">
                  Account Holder's Name:{" "}
                  <span className=" font-bold text-gray-700 ms-2">
                    {fullName}
                  </span>
                </h6>
                <h6 className=" text-sm lg:text-base font-semibold flex items-center text-gray-700 border-2 bg-sky-50 border-sky-200 p-2 rounded-md w-max">
                  Wallet Balance:{" "}
                  <span className=" font-bold text-gray-700 ms-2">
                    {formattedCash}/-
                  </span>
                </h6>
              </div>
              <div className="overflow-x-auto my-3">
                <table className="min-w-[580px] table table-sm border rounded table-borderless">
                  <thead className="bg-dark p-0 m-0 text-light text-center">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">UTR NO./Transaction Id</th>
                      <th scope="col">Security</th>
                      <th scope="col">Credit Amount</th>
                      <th scope="col">Debit Amount</th>
                      <th scope="col">Balance</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {paginatedTransactions.length > 0 ? (
                      paginatedTransactions.map((data, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "inherit" : "#0000001a",
                          }}
                        >
                          <td>
                            {data.Timestamp?.replace("T", " ")?.split(".")[0]}
                          </td>
                          <td>{data?.Order_id || ""}</td>
                          <td>{data?.Security || ""}</td>
                          <td>
                            {data?.Seller === pan
                              ? formatCurrency(
                                  data?.Price_per_unit * data?.Units
                                )
                              : formatCurrency(0)}
                          </td>
                          <td>
                            {data?.Buyer === pan
                              ? formatCurrency(
                                  data?.Price_per_unit * data?.Units
                                )
                              : formatCurrency(0)}
                          </td>
                          <td>{formatCurrency(data.Balance)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No Transactions Yet</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-between m-0 p-0">
                <div>
                  <button
                    className="btn d-flex justify-content-around align-items-center btn-sm btn-secondary"
                    onClick={handleDownload}
                  >
                    <FaFileDownload className="me-1" />
                    Download
                  </button>
                </div>
                <div className="d-flex justify-content-between m-0 p-0">
                  <select
                    className="border-0 m-0 p-0 me-3 font-size1"
                    value={entriesPerPage}
                    onChange={(e) =>
                      setEntriesPerPage(parseInt(e.target.value))
                    }
                  >
                    <option value="5">5 Entry</option>
                    <option value="10">10 Entry</option>
                    <option value="20">20 Entry</option>
                  </select>
                  <div className="d-flex justify-content-around align-items-center">
                    <BiSkipPrevious
                      size={20}
                      color="#000"
                      onClick={handlePrevPage}
                    />
                    <div className="mx-2 text-dark font-size1">
                      {currentPage}
                    </div>
                    <BiSkipNext
                      size={20}
                      color="#000"
                      onClick={handleNextPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default StatementPage;
