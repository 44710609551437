import React from "react";
import "./Loaders.css";

function Loader1() {
  return (
    <>
      <div className="loader-container">
        <video
          key="1"
          className="remove-white-bg"
          src="/images/btn-white.webm"
          alt={""}
          autoPlay
          loop
          muted
        />
        <h6 className="font-size2 mt-2">Loading...</h6>
      </div>
    </>
  );
}

export default Loader1;
