import React, { useState, useRef, useEffect } from "react";
import { MdOutlineBlock } from "react-icons/md";
import Header from "../../components/Header/Header.jsx";
import { Helmet } from "react-helmet";
import { RiArrowRightUpLine } from "react-icons/ri";
import { FaArrowCircleRight, FaDivide } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";

function Career() {
  const [storeIndex, setStoreIndex] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [select, setSelect] = useState(1);
  const [jobTitle, setJobTitle] = useState("Motion Graphics & Video Editor");
  const shadeRef = useRef();
  const [isBottom, setIsBottom] = useState(false);
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phone, setPhone] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState("");

  const Carrer_data = [
    {
      id: 1,
      category: "Animation & VFX",
      title: "Motion Graphics & Video Editor",
      type: ["Greater Noida (On-site)", "Full Time"],
    },
    {
      id: 2,
      category: "Marketing",
      title: "Social Media Marketing",
      type: ["Greater Noida (On-site)", "Internship"],
    },
  ];


  const handleSubmit = (e) => {
      console.log("Form submitted clicked"); 
      e.preventDefault();
      
      if (!resume) {
          console.log("Resume is missing");
          alert("Please upload a resume before submitting.");
          return;
      }
      
      console.log("Preparing FormData...");
      
      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("email", emailId);
      formData.append("phone", phone);
      formData.append("coverLetter", coverLetter);
      formData.append("resume", resume);
      
      fetch("https://api-scdndestates.com/api/career-application/", {
          method: "POST",
          body: formData,
      })
      .then((response) => {
          console.log("Response received:", response);
          if (!response.ok) {
              throw new Error("Failed to submit application");
          }
          return response.json();
      })
      .then((data) => {
          console.log("Success:", data);
          alert("Application sent successfully!");
      })
      .catch((error) => {
          console.error("Error:", error);
          alert("Failed to submit application");
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedExtensions = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!allowedExtensions.includes(file.type)) {
        alert("Only .pdf and .docx files are allowed to be uploaded");
        e.target.value = ""; // Reset file input
        return;
      }
      setResume(file);
      setResumeName(file.name); // Store filename
    }
  };

  // Hob description APi
  const Job_description = [
    {
      id: 1,
      title: "Motion Graphics & Video Editor",
      workMode: "Greater Noida, UP (On-site)",
      experience: "1 - 3 Years",
      ctc: "2.5 - 3.5 LPA",
      description: [
        "Find, import and record (using simple videography equipment) videos and audio files from various sources (cameras, stock libraries, YouTube, on-site etc.).",
        "Cut, trim, seamlessly combine and rearrange video clips from various sources to create compelling narratives reflecting the visions of the marketing team.",
        "Ensure precise synchronization of audio, from Avid Media Composer, with video tracks.",
        "Adjust colour, contrast, other visual parameters and add transition effects/simple animations to enhance the look and feel of the video, using Adobe Premier Pro.",
        "Design and animate titles, lower thirds, credits, and (on-screen) stylized running text using Adobe After Effects.",
        "Animate logos, icons, and other graphic elements (e.g. motion blurs, arrows, progress-indicators etc.) for superimposing on the video",
        "Be proficient in using all latest AI video creation software, analysing their limitations as well as their disrupting potential of old videography technologies.",
      ],
      essintial_skills: [
        "In-depth knowledge of content creation software like Adobe Premier Pro, After Effects and Avid Media Composer.",
        "Strong understanding of design principles, including typography, colour theory, layout, composition, theme consistency and ‘bread-crumb’ elements.",
        "Ability to interpret blogs, marketing briefs and understand the content’s intended core message, and translate them into visually compelling designs.",
        "Possess an innate understanding of the broad structure of social media reels/content, and knowledge of techniques to compress long written content into short clips.",
      ],
      extra_skills: [
        "Excellence in design software such as Adobe Photoshop and Illustrator.",
        "Excellent creativity and artistic skills to conceptualize and execute design concepts effectively, and attention to detail and accuracy.",
        "Script editing and manipulation capability to enhance the emotional resonance of the viewer, through the use of visual motifs and symbolism.",
      ],
      keywords: [
        "AI video creation",
        "Social Media content",
        "Video editing",
        "User-generated content",
        "Adobe PremierPro",
        "After Effects",
      ],
      requirements:
        "Bachelor's degree in Animation, Digital Media, Multimedia, Film studies or equivalent.",
    },
    {
      id: 2,
      title: "Social Media Marketing",
      workMode: "Greater Noida, UP (On-site)",
      experience: "1 - 3 Years",
      ctc: "2.5 - 3.5 LPA",
      description: [
        "Create regular posts (based on Headings and general content outline provided by the management) on LinkedIn, Twitter.",
        "Comment & engage on other influential people's posts (industry veterans, celebrities etc.) on topics related to Fintech/asset management and luxury Footwear business.",
        "Email influencers, analyse their content and audience base for quality and relevance, and initiate collaboration negotiations with them.",
        "Execute and optimize digital campaigns after analysing customer engagement data (on Meta and Google), and update/recirculate content regularly.",
        "Send DMs and Email (from contacts in Lusha/LinkedIn) AIFs for collaboration discussions, and to Buyers for lead generation.",
        "Google SEO through keyword performance indicators and bidding, recommend source-code maintenance and content updating (on website) to improve rank.",
      ],
      essintial_skills: [
        "Bachelor’s degree in Digital Marketing, Public Relations, MBA, or equivalent.",
        "Experience in Lead mining and data analysis, including Google/Amazon keyword data, and the ability to obtain filtered datasets from publicly available government databases.",
        "Good understanding of Instagram’s algorithm in showing Posts to Users and strategic vision for establishing digital presence organically.",
        "Deep understanding of User filtering parameters (based on keyword, interests, in-app activity etc.) available for running targeted campaigns on Instagram or YouTube.",
        "Strong networking skills and knowledge of fund-raising techniques & sources within the Indian Starup ecosystem.",
      ],
      extra_skills: [
        "Very strong verbal and written English communication skills.",
        "Email marketing and marketing/awareness content drafting skills.",
        "Experience in LinkedIn contacts & follower building, through requests to proper networks, participation in relevant groups and paid-promotion of Blogs/Posts.",
      ],
      keywords: [
        "Digital Marketing",
        "Social Media Campaigns",
        "SEM/SEO",
        "Content Creation",
        "Fintech Influencer",
        "Campaign Management",
      ],
      requirements:
        "Bachelor’s degree in Digital Marketing, Public Relations, MBA, or equivalent.",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (shadeRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = shadeRef.current;
        setIsBottom(scrollTop + clientHeight >= scrollHeight - 5);
      }
    };

    const jobContainer = shadeRef.current;
    if (jobContainer) {
      jobContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (jobContainer) {
        jobContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Job Openings at SCDND Estates</title>
        <meta
          name="description"
          content="A career at SCDND Estates means you’ll have the opportunity to contribute towards a disruptive change in a long-standing traditional industry, with tremendous potential of growth and a positive impact on society in general. Our Company firmly believes that India of 21st century deserves a revolutionary financial system that shifts power away from the hereditary/entitlement order to a more talent based one, and we will be pioneers in that field."
        />
        <meta
          name="description"
          content="Working with our Company will enable the applicant to learn a wide range of skills indispensable to the most niche parts of the investment industry, deal with influential clients first-hand and network with industry leaders, interact with the founders of both established and start-up firms and understand how businesses across a wide-range of sectors interact with each other. Our Company anticipates that your journey with us will ensure that you become the very best in the world in your own discipline/specialization and therefore an asset to the corporate world."
        />

        <link rel="canonical" href="https://scdndestates.com/Career" />
        {/* Open Graph */}
        <meta property="og:title" content="Job Opeanings at SCDND Estates" />
        <meta
          property="og:description"
          content="A career at SCDND Estates means you’ll have the opportunity to contribute towards a disruptive change in a long-standing traditional industry, with tremendous potential of growth and a positive impact on society in general. Our Company firmly believes that India of 21st century deserves a revolutionary financial system that shifts power away from the hereditary/entitlement order to a more talent based one, and we will be pioneers in that field."
        />

        <meta property="og:url" content="https://scdndestates.com/Career" />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Job Opeanings at SCDND Estates" />
        <meta
          name="twitter:description"
          content="A career at SCDND Estates means you’ll have the opportunity to contribute towards a disruptive change in a long-standing traditional industry, with tremendous potential of growth and a positive impact on society in general. Our Company firmly believes that India of 21st century deserves a revolutionary financial system that shifts power away from the hereditary/entitlement order to a more talent based one, and we will be pioneers in that field."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      <Header />
      {/* Opening */}
      <div className="max-w-[1800px] mx-auto">
        <div className="w-[90%] mx-auto">
          <div className="lg:flex flex-row sm:flex-col items-center justify-between gap-5 after-header">
            <div className="w-full lg:w-[49%]">
              <h1 className="mt-3 text-sm  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px] font-semibold text-black text-[30px] xl:text-[40px] leading-[37px] xl:leading-[50px] w-full md:w-[90%]">
                <span className="text-[#5AB2FF]">
                  If you believe in pushing boundaries,{" "}
                </span>{" "}
                we invite you to ascend with our team.
              </h1>
              <p className="text-sm xl:text-base mt-3 pl-3">
                <ul>
                  <li className="list-disc">
                    Our Startup unites brilliant minds to tackle finance's
                    toughest challenges. Join us in scaling new heights of
                    innovation.
                  </li>
                  <li className="list-disc">
                    Together, we're redefining what's possible in finance. Join
                    the climb at SCDND Estates, where brilliance meets ambition.
                  </li>
                </ul>
              </p>
            </div>
            <div className="w-full lg:w-[49%] flex items-center justify-center mt-5 lg:mt-0">
              <img
                src="/images/carrer-image.webp"
                className="w-full md:w-[70%] lg:w-[60%]"
                alt=""
              />
            </div>
          </div>

          {/* Job Listed */}
          <div
            id="go"
            className="flex flex-wrap justify-between gap-6 mt-[100px]"
          >
            <div className="max-w-3xl w-full md:w-[40%]">
              <h2 className="text-3xl font-bold text-[#000000]">
                Current Openings
              </h2>
              <p className="text-gray-500  mt-2">
                Available for Immediate Joining.
              </p>

              <div className="mt-6 space-y-5">
                {Carrer_data.map((job, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setStoreIndex(job.id);
                      setSelect(job.id);
                      setJobTitle(job.title);
                    }}
                    className={`${
                      select === job.id ? "bg-sky-50 border-b-[#84C7FF]" : ""
                    } group p-4 flex justify-between items-end shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl cursor-pointer duration-300 hover:bg-sky-50 group border-b-2 border-b-[#ffffff] hover:border-b-[#84C7FF] relative`}
                  >
                    <div>
                      <span
                        className={`${
                          select === job.id ? "bg-[#ffffff]" : "bg-zinc-100"
                        } text-xs font-bold  text-[#5AB2FF] px-2 py-1 rounded group-hover:bg-[#ffffff] duration-300`}
                      >
                        {job.category}
                      </span>
                      <h3 className="text-lg font-semibold mt-2 text-[#000000]">
                        {job.title}
                      </h3>
                      <p className="text-gray-500 text-sm">
                        <p className="text-gray-500 text-sm">
                          <span className="mr-2 whitespace-nowrap">
                            {job.type[0]}
                          </span>{" "}
                          {/* Location */}
                          <span className="absolute top-3 right-3 font-semibold whitespace-nowrap">
                            {job.type[1]}
                          </span>{" "}
                          {/* Job Type */}
                        </p>
                      </p>
                    </div>
                    <div
                      className={`${
                        select === job.id ? "arrow-move" : ""
                      }  ml-1`}
                    >
                      <svg
                        className=" fill-sky-400 group-hover:fill-sky-500"
                        xmlns="http://www.w3.org/2000/svg"
                        id="Outline"
                        viewBox="0 0 24 24"
                        width="30"
                        height="30"
                      >
                        <path d="M18,12h0a2,2,0,0,0-.59-1.4l-4.29-4.3a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L15,11H5a1,1,0,0,0,0,2H15l-3.29,3.29a1,1,0,0,0,1.41,1.42l4.29-4.3A2,2,0,0,0,18,12Z" />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Job Description */}
            <div className="relative w-full lg:w-[57%]  shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl lg:mt-[92px]">
              {Job_description.filter((data) => storeIndex === data.id).map(
                (job) => (
                  <div key={job.id}>
                    <div className="p-3 text-[#000000] border-b-2 flex flex-wrap gap-3 items-start justify-between">
                      <div className="flex flex-col gap-0.5">
                        <h2 className="text-xl font-semibold">{job.title}</h2>
                        <p className="text-gray-600 text-sm">
                          Experience Required: {job.experience}
                        </p>
                        <div className="flex items-center gap-1 lg:gap-3 text-[13px] font-medium pt-1.5">
                          <p className="text-gray-600 bg-gray-100 rounded-full px-2 py-0.5 whitespace-nowrap">
                            {job.workMode}
                          </p>
                          <p className="text-gray-600 bg-gray-100 rounded-full px-2 py-0.5 whitespace-nowrap">
                            CTC : {job.ctc}
                          </p>
                        </div>
                      </div>
                      {/* Apply Button */}

                      <div onClick={() => setOpenPopup(true)} className="slice">
                        <div className="cursor-pointer font-semibold rounded-md flex px-3 py-1.5 items-center shadow-sm gap-2.5 duration-200 text-sm 2xl:text-base">
                          Apply
                          <FaArrowCircleRight className="text-xs lg:text-sm" />
                        </div>
                      </div>
                    </div>
                    {/* Job Description */}
                    <div
                      className="p-6 max-h-[500px] overflow-y-scroll"
                      ref={shadeRef}
                    >
                      <h3 className="font-semibold mt-2 text-[#000000]">
                        Job Description
                      </h3>
                      <ul className="list-disc pl-5 text-gray-800 text-[13px]">
                        {job.description.map((desc, index) => (
                          <li key={index}>{desc}</li>
                        ))}
                      </ul>
                      {/* Key skills */}

                      <h3 className="font-semibold mt-2 text-[#000000]">
                        Mandatory Skills
                      </h3>
                      <ul className="list-disc pl-5 text-gray-800 text-[13px]">
                        {job.essintial_skills.map((res, index) => (
                          <li key={index}>{res}</li>
                        ))}
                      </ul>
                      <h3 className="font-semibold mt-2 text-[#000000]">
                        Good to Have
                      </h3>
                      <ul className="list-disc pl-5 text-gray-800 text-[13px]">
                        {job.extra_skills.map((req, index) => (
                          <li key={index}>{req}</li>
                        ))}
                      </ul>
                      <h3 className="font-semibold mt-2 text-[#000000]">
                        Qualification
                      </h3>
                      <ul className="list-disc pl-5 text-gray-800 text-[13px]">
                        {job.requirements}
                      </ul>
                    </div>
                  </div>
                )
              )}
              {!isBottom && (
                <div
                  style={{
                    background: "linear-gradient(360deg, #ffffff, #ffffff20)",
                  }}
                  className="absolute w-full h-[120px] bottom-0 z-[200] rounded-b-xl"
                ></div>
              )}
            </div>

            {openPopup === true && (
              <div className="demo3-container ">
                <div className="pop-up-animation bg-white w-[90%] md:w-[450px] flex justify-center shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl">
                  <div className="w-full">
                    <div className="flex justify-between items-center border-b-2 px-3 py-2">
                      <p className="text-[15px] font-bold text-[#000000]">
                        Apply for{" "}
                        <span className="text-[#59B2FF]">{jobTitle}</span>
                      </p>
                      <span></span>
                      <span className="scale-75 xl:scale-[0.6]">
                        <button
                          className="circle1 fromMiddle1 "
                          data-animation="magnify"
                          data-remove="3000"
                          onClick={() => setOpenPopup(false)}
                          aria-label="Close"
                        >
                          <span></span>
                        </button>
                      </span>
                    </div>

                    {/* <form className="flex flex-col p-6" onSubmit={handleSubmit}>
                      <div>
                        <label
                          htmlFor="fullname"
                          className="block text-sm font-semibold leading-4 text-[#212121]"
                        >
                          Full Name
                        </label>
                        <div className="mt-1 ">
                          <input
                            type="text"
                            name="fullName"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                            className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 "
                            id="fullName"
                            placeholder="s.g Chandan Kumar"
                          />
                        </div>
                      </div>
                      <div className="mt-3">
                        <label
                          htmlFor="emailid"
                          className="block text-sm font-semibold leading-4 text-[#212121]"
                        >
                          Email ID
                        </label>
                        <div className="mt-1">
                          <input
                            type="email"
                            name="emailid"
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                            required
                            className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 "
                            id="emailid"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="mt-3">
                        <label
                          htmlFor="phoneno"
                          className="block text-sm font-semibold leading-4 text-[#212121]"
                        >
                          Phone No
                        </label>
                        <div className="mt-1">
                          <input
                            type="number"
                            name="phoneno"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 "
                            id="phoneno"
                            placeholder="Phone no"
                          />
                        </div>
                      </div>

                      <div className="rounded-lg overflow-hidden mt-3">
                        <label
                          htmlFor="phoneno"
                          className="block text-sm font-semibold leading-4 text-[#212121]"
                        >
                          Upload Resume
                        </label>
                        <div className=" cursor-pointer flex items-center justify-between w-full hover:bg-sky-50/50 duration-300 p-1 rounded-md border-2 mt-1 ">
                          <div className="flex items-center justify-start w-full relative ">
                            <img
                              alt="File Icon"
                              className=" w-10"
                              src="/images/resume.png"
                            />
                            <span className="block text-gray-500 text-sm font-semibold whitespace-nowrap">
                              Upload your resume
                            </span>
                            <input
                              type="file"
                              accept=".pdf,.docx"
                              onChange={handleFileChange}
                              className="h-full opacity-0 cursor-pointer absolute w-full top-0"
                            />
                          </div>
                          <div className="text-2xl text-red-500 p-2 bg-red-50 rounded-md group">
                            <RiDeleteBin6Fill className="group-hover:scale-90 duration-300" />
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <label
                          htmlFor="phoneno"
                          className="block text-sm font-semibold leading-4 text-[#212121]"
                        >
                          Cover Letter
                        </label>
                        <div className="mt-1">
                          <textarea
                            name="textarea"
                            placeholder="Cover Letter"
                            id="textarea"
                            value={coverLetter}
                            onChange={(e) => setCoverLetter(e.target.value)}
                            required
                            className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6"
                            rows={3}
                          ></textarea>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="bg-[#5AB2FF] text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-[#3ea5ff] transition ease-in-out duration-150"
                      >
                        Submit Resume
                      </button>
                    </form> */}
                    <form className="flex flex-col p-6" onSubmit={handleSubmit}>
                    {/* Full Name */}
                    <div>
                      <label className="block text-sm font-semibold leading-4 text-[#212121]">Full Name</label>
                      <input
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                        className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] text-sm sm:leading-6"
                        placeholder="s.g Chandan Kumar"
                      />
                    </div>

                    {/* Email ID */}
                    <div className="mt-3">
                      <label className="block text-sm font-semibold leading-4 text-[#212121]">Email ID</label>
                      <input
                        type="email"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                        required
                        className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] text-sm sm:leading-6"
                        placeholder="Email"
                      />
                    </div>

                    {/* Phone No */}
                    <div className="mt-3">
                      <label className="block text-sm font-semibold leading-4 text-[#212121]">Phone No</label>
                      <input
                        type="number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] text-sm sm:leading-6"
                        placeholder="Phone no"
                      />
                    </div>

                    {/* Resume Upload */}
                    <div className="rounded-lg overflow-hidden mt-3">
                      <label className="block text-sm font-semibold leading-4 text-[#212121]">Upload Resume</label>
                      <div className="cursor-pointer flex items-center justify-between w-full hover:bg-sky-50/50 duration-300 p-1 rounded-md border-2 mt-1">
                        <div className="flex items-center justify-start w-full relative">
                          <img alt="File Icon" className="w-10" src="/images/resume.png" />
                          <span className="block text-gray-500 text-sm font-semibold">
                            {resumeName || "Upload your resume"}
                          </span>
                          <input
                            type="file"
                            accept=".pdf,.docx"
                            onChange={handleFileChange}
                            className="h-full opacity-0 cursor-pointer absolute w-full top-0"
                          />
                        </div>
                        {resume && (
                          <button
                            type="button"
                            className="text-2xl text-red-500 p-2 bg-red-50 rounded-md group"
                            onClick={() => {
                              setResume(null);
                              setResumeName("");
                            }}
                          >
                            <RiDeleteBin6Fill className="group-hover:scale-90 duration-300" />
                          </button>
                        )}
                      </div>
                    </div>

                    {/* Cover Letter */}
                    <div className="mt-3">
                      <label className="block text-sm font-semibold leading-4 text-[#212121]">Cover Letter</label>
                      <textarea
                        placeholder="Cover Letter"
                        value={coverLetter}
                        onChange={(e) => setCoverLetter(e.target.value)}
                        required
                        className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] text-sm sm:leading-6"
                        rows={3}
                      ></textarea>
                    </div>

                    {/* Submit Button */}
                    <button
                      type="submit"
                      disabled={!resume} // Disable if no resume is uploaded
                      className={`bg-[#5AB2FF] text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-[#3ea5ff] transition ease-in-out duration-150 ${
                        !resume ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      Submit Resume
                    </button>
                  </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
