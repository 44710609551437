import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import CheckAuth from "../components/ServiceFunctions/CheckAuth";

function SchedulePopup({ onReload, onClose, date }) {
  let eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));
  const { email, phone } = CheckAuth();
  const [loader, setLoader] = useState(false);
  const [isDropdownEnabled, setDropdownEnabled] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [tags, setTags] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [formData, setFormData] = useState(
    eventFormData !== null
      ? eventFormData
      : {
          title: "",
          date: date.toISOString().slice(0, 10),
          deputed: "",
          description: "",
          duration: "",
          budget: "",
        }
  );

  const handleSelectTitle = (title, Date) => {
    if (!selectedTitles.some((item) => item.title === title)) {
      setSelectedTitles([...selectedTitles, { title, Date }]);
    }
    setQuery(""); // Clear search input
    setResults([]); // Hide search results after selection
  };

  const handleRemoveTag = (title, date) => {
    setSelectedTitles(
      selectedTitles.filter((t) => t.title !== title || t.date !== date)
    );
  };

  const handleAddTag = (selectedTag = null) => {
    const newTags = selectedTag
      ? [selectedTag]
      : query
          .split(",")
          .map((tag) => tag.trim())
          .filter((tag) => tag !== "");

    if (newTags.length > 0) {
      setTags([...tags, ...newTags]);
      setResults([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (query.length >= 10) {
        handleAddTag();
      }
    }
  };

  useEffect(() => {
    if (query.length > 3) {
      const fetchResults = async () => {
        try {
          const response = await fetch(
            "https://api-scdndestates.com/api/admin_search_function/",
            {
              method: "POST", // Use POST method
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ query }), // Send the query in the body
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          setResults(data);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      };
      const delayDebounceFn = setTimeout(() => {
        fetchResults();
      }, 300); // debounce by 300ms

      return () => clearTimeout(delayDebounceFn);
    } else {
      setResults([]);
    }
  }, [query]);

  const validateForm = () => {
    const { title, date, deputed, description, duration } = formData;
    if (!title || !date || !deputed || !description || !duration) {
      alert("All fields are required.");
      return false;
    }
    return true;
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    secureLocalStorage.setItem("eventFormData", JSON.stringify(formData));
    eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));
  };

  const handleSave = async () => {
    setLoader(true);
    if (validateForm()) {
      try {
        const formData2 = new FormData();
        formData2.append("email", email);
        formData2.append("phone", phone);
        formData2.append("formData", JSON.stringify(formData)); // Convert form data to JSON string
        formData2.append("selectedTitles", JSON.stringify(selectedTitles));
        formData2.append("selectedOptions", JSON.stringify(selectedOptions));

        // Append uploaded files
        uploadedFiles.forEach((file) => {
          formData2.append("uploadedFiles", file);
        });
        const response = await fetch(
          "https://api-scdndestates.com/api/set-event-and-reminders/",
          {
            method: "POST",
            body: formData2,
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save event/reminder");
        }

        const data = await response.json();
        setSelectedTitles(
          Array.isArray(data.selectedTitles) ? data.selectedTitles : []
        );

        if (data.message === "Success") {
          secureLocalStorage.setItem(
            "admin-calendar",
            JSON.stringify(data.dates)
          );
          onReload(data.dates);
        }
        onClose();

        setFormData({
          title: "",
          date: new Date().toISOString().slice(0, 10),
          deputed: "",
          description: "",
          duration: "",
          budget: "",
        });

        secureLocalStorage.removeItem("eventFormData");
        setLoader(false);
      } catch (error) {
        console.error("Error during save:", error);
        setLoader(false);
      }
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((prev) => [...prev, ...files]);
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const options = [
    ".ppt",
    ".pptx",
    ".docx",
    ".py",
    ".json",
    ".txt",
    ".xlsx",
    ".jsx",
    ".css",
    ".pdf",
    ".xml",
    ".html",
    ".js",
    "img",
    "vid",
    "voice",
  ];

  const [showAll, setShowAll] = useState(false);

  const toggleOption = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const visibleOptions = showAll ? options : options.slice(0, 10);

  // Mobile Dropdown View
  const mobileView = (
    <div className="relative w-48" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-3 py-2 border rounded-lg bg-white"
      >
        <span className="truncate">
          {selectedOptions.length
            ? selectedOptions.join(", ")
            : "Select file types"}
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto">
          {options.map((option) => (
            <div
              key={option}
              className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => toggleOption(option)}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => {}}
                className="w-4 h-4 mr-2"
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  // Tablet and Desktop View
  const desktopView = (
    <div className="w-full">
      <div className="flex flex-wrap max-md:gap-1 md:gap-3">
        {visibleOptions.map((option) => (
          <label
            key={option}
            className={`text-sm md:text-base w-[45px] md:w-16 flex border-[1px] border-[#5ab2ff] relative items-center justify-center cursor-pointer rounded-lg max-md:px-2 md:px-3 py-1 transition-all duration-300 ease-in-out ${
              selectedOptions.includes(option)
                ? "bg-[#5ab2ff] text-white"
                : "hover:bg-blue-50"
            }`}
          >
            <input
              type="checkbox"
              checked={selectedOptions.includes(option)}
              onChange={() => toggleOption(option)}
              className="w-4 h-4 mr-2 opacity-0 absolute cursor-pointer"
            />
            <span>{option}</span>
          </label>
        ))}
        {!showAll && options.length > 10 && (
          <button
            onClick={() => setShowAll(true)}
            className="text-sm py-1 underline underline-offset-2 text-[#5ab2ff] flex items-end rounded-lg hover:text-blue-500 transition-colors duration-300"
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className="w-full h-full  shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] bg-white rounded-lg overflow-hidden">
      <div className="flex items-center justify-between px-4 py-2 border-b-2 ">
        <div className="text-black font-semibold text-lg">Add Events</div>
        <div onClick={onClose} className=" flex justify-end  rounded-full">
          <button
            className="circleadmin bg-white"
            data-animation="showShadow"
            data-remove="3000"
          ></button>
        </div>
      </div>

      <div className="max-lg:max-h-[80vh] lg:max-h-[75vh] overflow-y-auto">
        <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base mt-2">
          <img className="w-8 h-8" src="/images/title-admin.webp" alt="" />
          <input
            type="text"
            value={formData.title}
            className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Add Title"
            name="title"
            onChange={handleInputChange}
          />
        </div>

        <div className="flex items-center justify-center max-md:flex-col md:flex-row-reverse">
          <div className="w-full md:w-1/2 flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8 " src="/images/calen-admin.webp" alt="" />
            <input
              type="date"
              value={formData.date}
              className="border rounded-lg px-3 py-1.5 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              name="date"
              onChange={handleInputChange}
            />
          </div>

          <div className="w-full md:w-1/2 flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8" src="/images/assign-admin.webp" alt="" />
            <input
              type="text"
              value={formData.deputed}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Assigned to"
              name="deputed"
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex items-start px-4 py-2 gap-2 text-[#212121] font-medium text-base">
          <img className="w-8 h-8" src="/images/desc-admin.webp" alt="" />

          <textarea
            type="text"
            value={formData.description}
            className="w-full border rounded-lg text-xs md:text-sm xl:text-base max-md:px-2 md:px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400 min-h-[150px] text-left align-text-top"
            placeholder="Describe here..."
            name="description"
            onChange={handleInputChange}
            rows={12}
          />
        </div>

        <div className="flex max-md:flex-col md:flex-row items-center justify-center">
          <div className="w-full md:w-1/2 flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8" src="/images/clock-needles.svg" alt="" />
            <select
              value={formData.duration}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
              name="duration"
              onChange={handleInputChange}
            >
              <option value="Select">Select</option>
              <option value="2 hours">2 hours</option>
              <option value="4 hours">4 hours</option>
              <option value="1 day">1 day</option>
              <option value="1.5 day">1.5 day</option>
              <option value="2 days">2 days</option>
              <option value="3 days">3 days</option>
              <option value="4 days">4 days</option>
              <option value="1 week">1 week</option>
              <option value="1.5 weeks">1.5 weeks</option>
              <option value="2 weeks">2 weeks</option>
            </select>
          </div>

          <div className="w-full md:w-1/2 flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8" src="/images/cash.svg" alt="" />

            <div className="relative w-full">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                ₹
              </span>
              <input
                type="number"
                value={formData.budget}
                className="w-full border rounded-lg pl-7 pr-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter Budget..."
                name="budget"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="flex items-start px-4 py-2 gap-2 text-[#212121] font-medium text-base relative">
          <img className="w-8 h-8" src="/images/assign-admin.webp" alt="" />
          <div className="relative w-full">
            {/* Search Input */}
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Type at least 4 characters to search dependency"
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />

            {/* Dropdown search results */}
            {results.length > 0 && (
              <ul className="absolute left-0 z-10 bg-gray-100 border mt-2 w-full  max-h-40 rounded-md overflow-y-auto ">
                {results.map((item) => (
                  <li
                    key={item._id}
                    onClick={() =>
                      handleSelectTitle(item._source.title, item._source.Date)
                    }
                    className="p-2 border-b flex items-center   overflow-hidden  hover:bg-gray-200 cursor-pointer"
                  >
                    {console.log(item._source)}
                    <div className="w-full text-xs md:text-sm flex ">
                      <span className="w-24 font-semibold border-r-2 border-[#5ab2ff]">
                        {" "}
                        {item._source.Date}{" "}
                      </span>
                      <div className="font-bold  truncate pl-2 w-full">
                        {item._source.title}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {/* Selected Tags */}
            <div
              className={`flex w-full max-md:flex-col md:flex-row items-center gap-2 ${
                selectedTitles.length > 0 ? "my-2" : ""
              }`}
            >
              {selectedTitles.map(({ title, date }, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between max-md:max-w-64 w-full md:w-[32%] bg-gray-100 px-3 py-1 rounded-md"
                >
                  <div className="w-[90%] truncate  text-xs md:text-sm">
                    {" "}
                    {title} ({date})
                  </div>
                  <button
                    onClick={() => handleRemoveTag(title, date)}
                    className=" w-[10%] flex justify-end text-red-500"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex items-start px-4 py-2 gap-2 text-[#212121] font-medium text-base mb-2">
          <img className="w-8 h-8" src="/images/upload.svg" alt="" />

          {/* Checkbox & Dropdown Section */}
          <div className="hidden ">{mobileView}</div>
          <div className="block ">{desktopView}</div>
        </div>
        {/* File Upload Section */}
        <div className=" flex  max-md:items-start md:items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
          <img className="w-8 h-8" src="/images/up-arrow.svg" alt="" />
          <div className="max-md:w-full flex items-center max-md:flex-col max-md:items-center md:flex-row gap-2">
            <div className="text-xs max-md:w-full md:w-40 md:text-sm flex items-center justify-center gap-x-2 group relative overflow-hidden max-md:border-[1px] md:border-2 px-2 py-1 rounded-md border-[#5ab2ff] bg-sky-50 cursor-pointer">
              <div class=" cursor-pointer outline-none  duration-300 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="md:w-7 w-5 h-5 md:h-7 stroke-blue-400 fill-none group-hover:fill-blue-200 group-active:stroke-blue-500 group-active:fill-blue-200 group-active:duration-0 duration-300 group-hover:rotate-90"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke-width="1.5"
                  ></path>
                  <path d="M8 12H16" stroke-width="1.5"></path>
                  <path d="M12 16V8" stroke-width="1.5"></path>
                </svg>
              </div>
              Add file
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="absolute opacity-0 cursor-pointer w-full"
              />
            </div>
            {/* Display Selected Files */}

            {uploadedFiles.length > 0 && (
              <ul className="rounded-lg max-md:left-0 max-md:w-full flex   flex-wrap gap-2 items-center justify-center">
                {uploadedFiles.map((file, index) => (
                  <li
                    key={index}
                    className="max-md:w-full md:w-40 lg:w-52 xl:w-72 relative   flex justify-between items-center py-2 border px-2 rounded-md"
                  >
                    <span className="text-xs md:text-sm w-full md:w-36 lg:w-48 xl:w-64 overflow-hidden truncate ">
                      {file.name}
                    </span>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="text-red-500 text-xs font-bold ml-2"
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="flex justify-end px-4 py-2 mb-2">
          {!loader ? (
            <button
              className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue"
              onClick={() => {
                if (isDropdownEnabled && selectedOptions.length === 0) {
                  alert("What file type(s) do you want uploaded?");
                  return;
                }
                handleSave();
              }}
            >
              <i className="text-base md:text-lg">
                <span>
                  <img
                    src="/images/post.svg"
                    className="icon w-3 h-3 fill-white"
                    alt=""
                  />
                </span>
              </i>
              <p className="font-semibold">Post</p>
            </button>
          ) : (
            <button className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue">
              <img
                key="1"
                className="h-6"
                src="/images/btn-transparent.gif"
                alt={""}
                autoPlay
                loop
                muted
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SchedulePopup;
