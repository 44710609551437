import React, { useState, useEffect, useRef } from "react";
import Loader1 from "../Loaders/Loader1";
import { useNavigate } from "react-router-dom";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import secureLocalStorage from "react-secure-storage";
import AlertRed from "../Alerts/AlertRed.jsx";
import AlertYellow from "../Alerts/AlertYellow.jsx";
import AlertGreen from "../Alerts/AlertGreen.jsx";

function Nominee() {
  const { isAuthenticated, email, pan, phone } = CheckAuth();
  const { loader, statusF } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });
  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);
  const [locality, setLocality] = useState([]);
  const [checked2, setChecked2] = useState();
  const [status, setStatus] = useState(0);
  const [loader2, setLoader2] = useState(false);
  const [response2, setresponse2] = useState(null);
  const [message, setMessage] = useState("");
  const navigateTimeout = useRef(null);
  const paraRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);

  const handleScroll = () => {
    const element = paraRef.current;
    if (element) {
      const isScrolledToBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;
      setIsAtBottom(isScrolledToBottom);
    }
  };

  useEffect(() => {
    const element = paraRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const handleNavigate = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    if (response2["path"] !== undefined) {
      secureLocalStorage.setItem("kycData", JSON.stringify(response2.data));
      if (response2["path"] === "Edit") {
        navigate("/profile");
      } else {
        navigate("/UserKycForm", {
          state: { selectedCard: response2["path"] },
        });
      }
    }
  };

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message]);

  const navigate = useNavigate();

  const fetchPincodeData = (index, pincode) => {
    handleInputChange(index, "address_pincode", pincode);
    if (pincode > 99999 && pincode < 1000000) {
      fetch(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((PostOffice) => {
          handleInputChange(
            index,
            "address_state",
            PostOffice[0].PostOffice[0].State
          );
          handleInputChange(
            index,
            "address_district",
            PostOffice[0].PostOffice[0].District
          );
          const area = PostOffice[0].PostOffice?.map((office) => office.Name);
          setLocality(area);
        })
        .catch((error) => {});
    }
  };

  const initialNominee = {
    isMinor: "",
    title: "",
    name: "",
    dob: "",
    relation: "",
    percentage: "",
    guardian_title: "",
    guardian_name: "",
    guardian_relation: "",
    address_line1: "",
    address_line2: "",
    address_pincode: "",
    address_locality: "",
    address_district: "",
    address_state: "",
  };

  const [nominees, setNominees] = useState([
    {
      isMinor: "",
      title: "",
      name: "",
      dob: "",
      relation: "",
      percentage: "",
      guardian_title: "",
      guardian_name: "",
      guardian_relation: "",
      address_line1: "",
      address_line2: "",
      address_pincode: "",
      address_locality: "",
      address_district: "",
      address_state: "",
    },
  ]);

  const handleAddNominee = () => {
    setNominees([...nominees, { ...initialNominee }]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedNominees = [...nominees];
    updatedNominees[index][field] = value;
    setNominees(updatedNominees);
  };

  const handleRemoveNominee = (index) => {
    const updatedNominees = [...nominees];
    updatedNominees.splice(index, 1);
    setNominees(updatedNominees);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setLoader2(true);
    const requestData = {
      nominees: nominees,
    };
    fetch("https://api-scdndestates.com/api/user-kyc/", {
      method: "POST",
      body: JSON.stringify({
        requestData,
        form_name: "nominee",
        user_consent: checked2,
        email,
        phone,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to submit data");
        }
        return response.json();
      })
      .then((response) => {
        setMessage(response["error"]);
        setresponse2(response);
        setLoader2(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let fetchedNominees = [];

    if (
      Array.isArray(userKycData?.["nominees"]) &&
      userKycData?.["nominees"].length > 0
    ) {
      fetchedNominees = userKycData?.["nominees"]?.map((nominee) => ({
        isMinor: nominee.isMinor || "",
        title: nominee.title || "",
        name: nominee.name || "",
        dob: nominee.dob || "",
        relation: nominee.relation || "",
        percentage: nominee.percentage || "",
        guardian_title: nominee.Guardian?.title || "",
        guardian_name: nominee.Guardian?.name || "",
        guardian_relation: nominee.Guardian?.["relation with Nominee"] || "",
        address_line1: nominee.Address?.line1 || "",
        address_line2: nominee.Address?.line2 || "",
        address_pincode: nominee.Address?.pincode || "",
        address_locality: nominee.Address?.locality || "",
        address_district: nominee.Address?.district || "",
        address_state: nominee.Address?.state || "",
      }));
    } else {
      fetchedNominees = [{ ...initialNominee }];
    }
    setNominees(fetchedNominees);

    if (userKycData?.["KYC_status"]["F"] === 10) {
      setStatus(1);
      setChecked2(true);
    } else if (userKycData?.["KYC_status"]["F"] === 5) {
      setStatus(2);
    } else {
      setStatus(0);
    }
  }, [statusF]);

  const handleA = (index, value) => {
    handleInputChange(index, "isMinor", value);
  };

  const renderNomineeFields = () => {
    return nominees?.map((nominee, index) => (
      <div key={index}>
        <div className="border  rounded-lg py-2 mt-3 font-size1">
          {index !== 0 && (
            <>
              <div className="flex justify-end m-0 p-0 mb-2">
                <button
                  disabled={status === 1}
                  className="flex w-[120px] justify-center rounded-md bg-[#717171] hover:bg-[#212121] px-2 py-1 text-sm font-semibold leading-6 text-white shadow-sm  cursor-pointer mt-2"
                  onClick={() => handleRemoveNominee(index)}
                >
                  {/* <MdDeleteForever size={20} color="#fff" /> */}
                  Delete Nominee
                </button>
              </div>
            </>
          )}
          <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
            <div className="w-full lg:w-[49%] mt-2">
              <h6 className="text-sm font-bold mb-2">
                Is the Nominee a Minor?
              </h6>
              <div className="flex items-center">
                <div className="form-check form-check-inline flex items-center ">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="yes"
                    checked={nominee.isMinor === "yes"}
                    disabled={status === 1}
                    value="yes"
                    onChange={(e) => handleA(index, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="yes">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline flex items-center">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`nomineeMinor-${index}`}
                    id="no"
                    value="no"
                    checked={nominee.isMinor === "no"}
                    disabled={status === 1}
                    onChange={(e) => handleA(index, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="no">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`nomineeName-${index}`}
              >
                Nominee's Name<span className="text-danger">*</span>
              </label>
              <div className="border  ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-1 rounded-lg cursor-pointer flex justify-between items-center overflow-hidden">
                <div className="input-group-text m-0 p-0 rounded-none border-none ">
                  <select
                    className="text-sm select-border font-semibold m-0 cursor-pointer px-1"
                    name={`nomineeTitle-${index}`}
                    id={`nomineeTitle-${index}`}
                    value={nominee.title}
                    disabled={status === 1}
                    onChange={(e) =>
                      handleInputChange(index, "title", e.target.value)
                    }
                    required
                  >
                    <option className="text-secondary">Title</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Mrs.">Mrs.</option>
                  </select>
                </div>
                <input
                  className="form-control text-sm py-2 px-2 rounded-none border-none cursor-pointer font-semibold"
                  id={`nomineeName-${index}`}
                  name={`nomineeName-${index}`}
                  placeholder="Name"
                  value={nominee.name}
                  disabled={status === 1}
                  onChange={(e) =>
                    handleInputChange(index, "name", e.target.value)
                  }
                  required
                />
              </div>
            </div>
            {nominee.isMinor === "yes" && (
              <div className="w-full lg:w-[49%] mt-2">
                <label
                  className="font-semibold text-[#212121]"
                  htmlFor={`nomineeDob-${index}`}
                >
                  DOB<span className="text-danger">*</span>
                </label>
                <input
                  className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                  type="date"
                  name={`nomineeDob-${index}`}
                  value={nominee.dob}
                  disabled={status === 1}
                  id={`nomineeDob-${index}`}
                  onChange={(e) =>
                    handleInputChange(index, "dob", e.target.value)
                  }
                />
              </div>
            )}
          </div>
          <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`nomineeRelation-${index}`}
              >
                Relation with Nominee<span className="text-danger">*</span>
              </label>
              <div>
                <select
                  className="text-sm py-2 px-2 w-full rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1 "
                  name={`nomineeRelation-${index}`}
                  id={`nomineeRelation-${index}`}
                  value={nominee.relation}
                  disabled={status === 1}
                  onChange={(e) =>
                    handleInputChange(index, "relation", e.target.value)
                  }
                >
                  <option>Select from dropdown</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Sister">Sister</option>
                  <option value="Son">Son</option>
                  <option value="Brother">Brother</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Others">Others (Please specify)</option>
                </select>
              </div>
            </div>
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`nomineePercentage-${index}`}
              >
                Percentage<span className="text-danger">*</span>
              </label>
              <div>
                <select
                  className="text-sm py-2 px-2 w-full rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1 "
                  name={`nomineePercentage-${index}`}
                  id={`nomineePercentage-${index}`}
                  value={nominee.percentage}
                  disabled={status === 1}
                  onChange={(e) =>
                    handleInputChange(index, "percentage", e.target.value)
                  }
                >
                  <option>Select from dropdown</option>
                  <option value="25%">25%</option>
                  <option value="33.33%">33.33%</option>
                  <option value="50%">50%</option>
                  <option value="66.66%">66.66%</option>
                  <option value="75%">75%</option>
                  <option value="100%">100%</option>
                </select>
              </div>
            </div>
          </div>
          {nominee.isMinor === "yes" && (
            <>
              <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
                <h6 className="font-size1 fw-bold text-dark mt-2 mb-0 text-decoration-underline">
                  Guardian Details
                </h6>
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor={`GuardianName-${index}`}
                  >
                    Name<span className="text-danger">*</span>
                  </label>
                  <div className="border  ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-1 rounded-lg cursor-pointer flex justify-between items-center overflow-hidden ">
                    <div className="input-group-text m-0 p-0 rounded-none border-none ">
                      <select
                        className="text-sm select-border font-semibold m-0 cursor-pointer px-1"
                        name={`GuardianTitle-${index}`}
                        id={`GuardianTitle-${index}`}
                        value={nominee.guardian_title}
                        disabled={status === 1}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "guardian_title",
                            e.target.value
                          )
                        }
                      >
                        <option className="text-secondary">Title</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mrs.">Mrs.</option>
                      </select>
                    </div>
                    <input
                      className="form-control text-sm py-2 px-2 rounded-none border-none cursor-pointer font-semibold"
                      id={`GuardianName-${index}`}
                      name={`GuardianName-${index}`}
                      placeholder="Guardian's Name"
                      value={nominee.guardian_name}
                      disabled={status === 1}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "guardian_name",
                          e.target.value
                        )
                      }
                      required
                    />
                  </div>
                </div>
                <div className="w-full lg:w-[49%] mt-2">
                  <label
                    className="font-semibold text-[#212121]"
                    htmlFor={`GuardianRelation-${index}`}
                  >
                    Guardian's Relation with Nominee
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1 "
                    id={`GuardianRelation-${index}`}
                    name={`GuardianRelation-${index}`}
                    placeholder="Guardian's Name"
                    value={nominee.guardian_relation}
                    disabled={status === 1}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "guardian_relation",
                        e.target.value
                      )
                    }
                  >
                    <option>Select from dropdown</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Sister">Sister</option>
                    <option value="Son">Son</option>
                    <option value="Brother">Brother</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Others">Others (Please specify)</option>
                  </select>
                </div>
              </div>
            </>
          )}
          <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`AdressAdd1-${index}`}
              >
                Address Line 1<span className="text-danger">*</span>
              </label>
              <input
                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                type="text"
                id={`AdressAdd1-${index}`}
                name={`AdressAdd1-${index}`}
                value={nominee.address_line1}
                disabled={status === 1}
                onChange={(e) =>
                  handleInputChange(index, "address_line1", e.target.value)
                }
                placeholder="House No./Block/Landmark"
              />
            </div>
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`AddressAdd2-${index}`}
              >
                Address Line 2
              </label>
              <input
                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                type="text"
                id={`AddressAdd2-${index}`}
                name={`AddressAdd2-${index}`}
                value={nominee.address_line2}
                disabled={status === 1}
                onChange={(e) =>
                  handleInputChange(index, "address_line2", e.target.value)
                }
                placeholder="House No./Block/Landmark"
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`AddressPin-${index}`}
              >
                Pincode<span className="text-danger">*</span>
              </label>
              <input
                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                type="text"
                id={`AddressPin-${index}`}
                name={`AddressPin-${index}`}
                value={nominee.address_pincode}
                disabled={status === 1}
                onChange={(e) => fetchPincodeData(index, e.target.value)}
                required
              />
            </div>
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`AddressLocality-${index}`}
              >
                Locality<span className="text-danger">*</span>
              </label>
              <div>
                <select
                  className="text-sm py-2 px-2 w-full rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1 "
                  id={`AddressLocality-${index}`}
                  name={`AddressLocality-${index}`}
                  value={nominee.address_locality}
                  disabled={status === 1}
                  onChange={(e) =>
                    handleInputChange(index, "address_locality", e.target.value)
                  }
                >
                  <option>
                    {nominee?.address_locality || "Select Locality"}
                  </option>
                  {locality?.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between mx-auto px-3 mb-2">
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`AddressDistrict-${index}`}
              >
                District<span className="text-danger">*</span>
              </label>
              <input
                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                type="text"
                id={`AddressDistrict-${index}`}
                name={`AddressDistrict-${index}`}
                value={nominee.address_district}
                disabled={status === 1}
                placeholder="East Delhi"
                required
              />
            </div>
            <div className="w-full lg:w-[49%] mt-2">
              <label
                className="font-semibold text-[#212121]"
                htmlFor={`AddressState-${index}`}
              >
                State<span className="text-danger">*</span>
              </label>
              <input
                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                type="text"
                id={`AddressState-${index}`}
                name={`AddressState-${index}`}
                value={nominee.address_state}
                disabled={status === 1}
                placeholder="New Delhi"
                required
              />
            </div>
          </div>
        </div>
      </div>
    ));
  };
  // Scroll disable on book section
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const scrollDivRef = useRef(null);

  const handleScrolldisable = () => {
    if (scrollDivRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current;
      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsCheckboxEnabled(true);
      } else {
        setIsCheckboxEnabled(false);
      }
    }
  };
  return (
    <>
      {loader ? (
        <Loader1 />
      ) : (
        <>
          {renderNomineeFields()}
          <div className="flex justify-start py-3">
            <button
              className="flex w-[120px] justify-center rounded-md bg-[#717171] hover:bg-[#212121] px-2 py-1 text-sm font-semibold leading-6 text-white shadow-sm  cursor-pointer "
              onClick={handleAddNominee}
              disabled={status === 1}
            >
              Add Nominee
            </button>
          </div>
          <div className="row m-0 p-0">
            <div className="w-full mt-2 border rounded-xl p-0">
              <div ref={scrollDivRef || paraRef} onScroll={handleScrolldisable} className="rounded-lg"
               
                style={{ maxHeight: "150px", overflowY: "auto" }}
              >
                <h6 className="px-2 pt-2 font-bold text-sm">
                  User Consent and Agreement
                </h6>
                <p className="m-0 px-2 font-medium text-xs leading-4 my-1">
                  By submitting Personal Information through our Platform or
                  Services, you agree to the terms of this Consent Form and you
                  expressly give your voluntary consent to the collection, use
                  and disclosure of the Personal Information in accordance with
                  this Privacy Policy.
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">
                  Information you provided to us
                </h6>
                <p className="m-0 px-2 font-medium text-xs leading-4 my-1">
                  <ol className="font-size2 mb-1" type="I">
                    <li>
                      If you provide us feedback or contact us via e-mail, we
                      will collect your name and e-mail address, as well as any
                      other content included in the e-mail, in order to send you
                      a reply.
                    </li>
                    <li>
                      When you participate in one of our surveys, we may collect
                      additional information.
                    </li>
                    <li>
                      We may collect Personal and financial information provided
                      by you, such as your first and last name, phone, e-mail
                      address, age, investments, PAN, Aadhaar etc. while you
                      access services on the platform
                    </li>
                    <li>
                      We may also collect Personal Information on the Platform
                      or through the Services when we make clear that we are
                      collecting it and you voluntarily provide the same, for
                      example when you submit an application for employment.
                    </li>
                    <li>
                      You must ensure to recheck the information or data being
                      provided to us. In case any of the data/information is
                      found to be inaccurate or incorrect, the same shall be
                      informed to us for immediate amendment. We shall not be
                      responsible for the authenticity of any information
                      including personal information submitted to us.
                    </li>
                  </ol>
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">
                  Information collected via technology
                </h6>
                <p className=" m-0 px-2 font-medium text-xs leading-4 my-1">
                  <ol className="font-size2 mb-1" type="I">
                    <li>
                      To make our Platform and Services more useful to you, our
                      servers (which may be hosted by a third-party service
                      provider) collect information from you, including your
                      browser type, operating system, Internet Protocol (IP)
                      address (a number that is automatically assigned to your
                      computer when you use the Internet, which may vary from
                      session to session), and domain name.
                    </li>
                    <li>
                      We may use third party service providers to help us
                      analyze certain online activities. For example, these
                      service providers may help us analyze visitor activity on
                      the Platform. We may permit these service providers to use
                      cookies and other technologies to perform these services
                      for us.
                    </li>
                  </ol>
                  We use authorized third-party service providers to power
                  payment transactions on our platform. Further, as per the
                  applicable regulations, we may use tokenization services from
                  certified third-party service providers to facilitate
                  transactions on our platform. Such third- party service
                  providers have the necessary rights to use/process/transfer
                  such information to provide tokenization services in
                  accordance with the applicable regulations. Such third- party
                  service providers are PCI-DSS compliant. We do not store any
                  information that is provided by you to facilitate such online
                  transactions on our Platform.
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">
                  Use of personal information
                </h6>
                <p className="m-0 px-2 font-medium text-xs leading-4 my-1">
                  In general, personal Information you submit to us regarding
                  you or your company is used either to respond to requests that
                  you make, or to aid us in serving you better. We use such
                  Personal Information in the following ways:
                  <ul className="font-semibold text-xs leading-5 mb-2">
                    <li>To identify you as a user in our system;</li>
                    <li>
                      To provide improved administration of our Platform and
                      Services;
                    </li>
                    <li>To provide the Services you request;</li>
                    <li>
                      To improve the quality of experience when you interact
                      with our Platform and Services;
                    </li>
                    <li>To send you e-mail and other notifications;</li>
                    <li>
                      To send newsletters, surveys, offers, and other
                      promotional materials related to our Services and for
                      other marketing purposes.
                    </li>
                    <li>Protect our services and our users.</li>
                    <li>
                      Market and customer analysis, market research and
                      statistics.
                    </li>
                    <li>
                      To prevent and detect fraud or abuse of our services as
                      well as to prevent, detect and investigate any potential
                      unlawful or prohibited activities.
                    </li>
                  </ul>
                  Information like your demographic information like Name, DOB
                  and Address, financial information like bank account details,
                  Email ID and Mobile No., and other information like
                  PAN/Aadhaar etc. will be utilized for and shared with
                  regulators and/or government authorities like Investment
                  Advisors, Research Analysts, Brokers, NBFCs, Banks etc. for
                  various regulatory purposes (KYC, AML etc.) as required by
                  applicable MCA, SEBI and RBI regulations. Your investment
                  information (such as value of holdings, transaction patterns,
                  returns etc.) will also be utilized and shared with
                  managers/agents of SCDND Estates or other regulated entities
                  (Investment Advisors, Research Analysts Brokers, NBFCs, Banks
                  etc.) after proper masking of personal identifiable details,
                  for the aforesaid and tracking purposes.
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">
                  Disclosure to third parties
                </h6>
                <p className=" m-0 px-2 font-medium text-xs leading-4 my-1">
                  We may be required from time to time to disclose any personal
                  and KYC information collected by us to governmental or
                  judicial bodies or agencies or our regulators based on their
                  requirement to comply with the applicable laws including cyber
                  laws. We may share your information including personal
                  information to third parties including group companies to
                  enable the provision of services to you and/ or to carry out
                  technical, logistical and other functions on our behalf. (e.g.
                  Sending emails or newsletters to users).
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">
                  Third party websites
                </h6>
                <p className=" m-0 px-2 font-medium text-xs leading-4 my-1">
                  When you click on a link to any other website or location, you
                  will leave our Platform and go to another site and another
                  entity may collect Personal Information or Anonymous
                  Information from you. We have no control over, do not review,
                  and cannot be held responsible for, these outside websites or
                  their content. Please be aware that the terms of this Privacy
                  Policy do not apply to these outside websites or content, or
                  to any collection of information after you click on links to
                  such outside websites.
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">Cookies</h6>
                <p className=" m-0 px-2 font-medium text-xs leading-4 my-1">
                  &quot;Cookies&quot; are pieces of information that a browser
                  can record after visiting a website. We may use cookies for
                  technical purposes such as to enable better navigation through
                  our site, or to store user preferences for interacting with
                  the site. If you turn off the option to have cookies stored on
                  your browser, it will affect your experience on smallcase.com
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">
                  Security of your information
                </h6>
                <p className=" m-0 px-2 font-medium text-xs leading-4 my-1">
                  SCDND Estates is committed to protecting the security and
                  privacy of your Personal Information. We endeavor to have
                  suitable technical, operational and physical security controls
                  and measures to protect information collected by us that are
                  commensurate with the nature of our business at all times. We
                  use a variety of industry-standard security technologies and
                  procedures to help protect your Personal Information from
                  unauthorized access, use, or disclosure, and periodically
                  review the same. In the event of any breach of our security
                  control measures resulting in unauthorized access to your
                  information, we will endeavor to inform you of the extent of
                  such breach, subject to applicable law and cooperation with
                  the relevant authorities. Further, despite our efforts to
                  protect your information, if unauthorized persons breach
                  security control measures and illegally use such
                  sensitive/personal data or information, the Company, its
                  affiliates and Directors shall not be held responsible/liable.
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">
                  Retention of information/data
                </h6>
                <p className=" m-0 px-2 font-medium text-xs leading-4 my-1">
                  It may be noted that we may retain the data as may be provided
                  to us till such time as may be provided under the law and as
                  required to efficiently provide service to our users.
                </p>
                <h6 className="px-2 pt-2 font-bold text-sm">
                  Amendments/Modifications
                </h6>
                <p className=" m-0 px-2 font-medium text-xs leading-4 my-1">
                  This CONSENT PTOLICY available on our website is current and
                  valid. However, SCDND Estates reserves the right to
                  amend/modify any of the sections of this policy at any time
                  and the general public are requested to keep themselves
                  updated for changes by reading the same from time to time.
                </p>
              </div>
              <div className="mb-0 border-t px-2 py-1">
                <input
                  type="checkbox"
                  name=""
                  className="checkbox-style"
                  id="check2"
                  checked={checked2 === true}
                  onChange={(e) => setChecked2(!checked2)}
                  disabled={status === 1 || !isAtBottom || !isCheckboxEnabled}
                  required
                />
                <label htmlFor="check2" className="text-sm ">
                  &nbsp; I have read the term and conditions of the CONSENT
                  POLICY and agree to follow them.
                  <span className="text-danger">*</span>
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-center py-3">
            <button
              className={` ${
                loader2 || status === 1
                  ? "bg-[#717171] cursor-not-allowed"
                  : "cursor-pointer  bg-[#5ab2ff] hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              } flex h-10 w-[120px] items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm `}
              type="submit"
              onClick={handleRegister}
              disabled={loader2 || status === 1}
            >
              {loader2 ? (
                <video
                key="1"
                className="userkyc-submit-btn remove-white-bg"
                src="/images/btn-transparent.gif"
                alt={""}
                autoPlay
                loop
                muted
                height={30}
              />
              ) : (
                <>Save</>
              )}
            </button>
          </div>
        </>
      )}
      {message !== "" &&
        (message.includes("already submitted") ? (
          <AlertRed
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ) : message.includes("successfully") ? (
          <AlertGreen
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ) : (
          <AlertYellow
            message={message}
            onClose={() => {
              setMessage("");
              handleNavigate();
            }}
          />
        ))}
    </>
  );
}
export default Nominee;
