import React from "react";
import Header from "../../components/Header/Header";

function Error404() {
  return (
    <>
      <Header />
      <section className="bg-white dark:bg-gray-900 after-header ">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:pt-20 lg:px-6 flex flex-col items-center">
          <div className="mb-4 max-w-lg flex justify-center">
            <img src="/images/Error404img.webp" alt="" />
          </div>
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-3 text-xl tracking-tight font-extrabold lg:text-3xl text-[#00A4E2] ">
              404 Not Found
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">
              Whoops! That page doesn’t exist.
            </p>
            <p className="mb-2 text-lg font-light text-gray-500 ">
              You'll find lots to explore on the home page.{" "}
            </p>
            <a
              href="/"
              className="inline-flex justify-center rounded-md bg-[#5ab2ff] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Back to Homepage
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Error404;
