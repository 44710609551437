import React, { useState } from "react";
import PopUp1 from "../../components/PopUp1/PopUp1";
import AnnualReportsapi from "../../API/AnnualReportsApi.js";
import Header from "../../components/Header/Header.jsx";
import { FaFilePdf } from "react-icons/fa6";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import { Helmet } from "react-helmet";

function AnnualReports() {
  const [selectedCard, setSelectedCard] = useState(AnnualReportsapi[0]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(""); // State for dropdown value
  const { isAuthenticated } = CheckAuth();

  const handleSelectCard = (card) => {
    setSelectedCard(card);
    setSelectedDropdownValue("");
  };

  const handleDropdownChange = (e) => {
    const selectedId = e.target.value;
    const card = AnnualReportsapi.find(
      (c) => c.id === parseInt(selectedId, 10)
    );
    if (card) {
      setSelectedCard(card);
    }
    setSelectedDropdownValue(selectedId);
  };

  return (
    <>
      <Helmet>
        <title>Reports - Annual Reports | SCDND Estates</title>
        <meta
          name="description"
          content="Earnings Snapshot, Directors Report."
        />
        <link rel="canonical" href="https://scdndestates.com/annualReports" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Reports and regulatory filings - Annual Reports"
        />
        <meta
          property="og:description"
          content="Earnings Snapshot, Directors Report."
        />

        <meta
          property="og:url"
          content="https://scdndestates.com/annualReports"
        />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Reports and regulatory filings - Annual Reports"
        />
        <meta
          name="twitter:description"
          content="Earnings Snapshot, Directors Report."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      <div>
        <Header />
        {!isAuthenticated ? (
          <PopUp1 />
        ) : (
          <div className="container-fluid after-header pb-5 max-w-[1800px] mx-auto ">
            {/* Path Navigation */}
            <div className="flex w-[90%] mx-auto">
              <h6 className="mt-4 w-full text-sm md:text-base">
                <a href="/">
                  <span className="header-link">Home</span>
                  <span className="text-secondary"> &gt; </span>
                </a>
                <a href="#">
                  <span className="text-dark text-decoration-underline">
                    Annual Reports
                  </span>
                </a>
                <hr className="text-dark mb-0" />
              </h6>
            </div>
            {/* Earning */}
            <h2 className="w-[90%] mx-auto text-center my-4 py-2 text-base lg:text-2xl font-bold text-black bg-gradient-to-r from-white via-[#DBEDF9] to-white">
              Earning Snapshots
            </h2>

            <div className="w-[90%] mx-auto flex flex-wrap gap-4 justify-start">
              {/* Left Section */}
              <div className="py-4 px-4 w-full lg:min-w-[235px] lg:w-[17%] h-fit border hover:shadow-sm rounded-lg">
                <div className="font-bold">
                  <h2 className="text-black">Recent Reports</h2>
                  <hr className="my-1" />
                  <div className="flex flex-wrap justify-center lg:flex-col mt-3 gap-2">
                    {AnnualReportsapi.sort((a, b) => b.id - a.id)
                      .slice(0, 3)
                      .map((card, index) => (
                        <div
                          onClick={() => handleSelectCard(card)}
                          key={index}
                          className={`${
                            selectedCard.id === card.id ? "bg-[#DBEDF9]" : ""
                          } button-year border p-2 min-w-full md:min-w-[200px] lg:min-w-[150px] rounded-md flex items-center justify-center cursor-pointer hover:bg-[#DBEDF9] duration-200 text-black`}
                        >
                          {card.startYear} - {card.endYear}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="md:flex flex-wrap lg:flex-col justify-between w-full">
                  <div className="font-bold mt-4 md:w-[47%] lg:w-full">
                    <h2 className="text-black">More Reports</h2>
                    <hr className="my-1" />
                    <div className="select-container">
                      <select
                        name="reports"
                        id="reports"
                        onChange={handleDropdownChange}
                        value={selectedDropdownValue}
                        className="mt-2 p-2 border rounded-md flex items-center justify-center w-full"
                      >
                        <option value="">Explore</option>
                        {AnnualReportsapi.sort((a, b) => b.id - a.id)
                          .slice(3)
                          .map((card) => (
                            <option
                              key={card.id}
                              value={card.id}
                              className="text-black"
                            >
                              {card.startYear} - {card.endYear}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="font-bold mt-4 md:w-[47%] lg:w-full">
                    <h2 className="text-black">Director Report</h2>
                    <hr className="my-1" />
                    <a
                      href={selectedCard.link}
                      download={`Directors_Report_${selectedCard.endYear}.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="mt-2 hover:text-black duration-200 flex w-full items-center gap-2 relative p-2 rounded-md bg-white isolation-auto z-10 border-2 border-[#D9EBF7] before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-[#D9EBF7] before:-z-10 before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-700">
                        Download Report{" "}
                        <span className="text-red-700">
                          <FaFilePdf />
                        </span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className="justify-center border rounded-lg shadow-md w-full lg:w-[70%] xl:w-[74%] p-[1%] overflow-scroll ">
                {selectedCard && (
                  <div className="table-responsive  min-w-[500px] w-full rounded-lg overflow-x-scroll">
                    <table className="table-auto w-full text-sm text-black">
                      <thead className="bg-blue-500 text-white">
                        <tr>
                          <th className="p-2 text-left w-1/2">Particulars</th>
                          <th className="p-2 text-center w-1/4">
                            As at the end of 31 March {selectedCard.endYear}
                            <br />
                            (in Rs. Hundred)
                          </th>
                          <th className="p-2 text-center w-1/4">
                            As at the end of 31 March {selectedCard.startYear}
                            <br />
                            (in Rs. Hundred)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">Total Revenue</td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.a0[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.a0[1]}
                          </td>
                        </tr>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">Total Expenses</td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.a1[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.a1[1]}
                          </td>
                        </tr>
                        <tr className="bg-gray-200 text-blue-900">
                          <td className="p-2 font-bold">
                            Profit or Loss before Exceptional and Extraordinary
                            items and Tax
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.B[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.B[1]}
                          </td>
                        </tr>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">
                            Less: Exceptional Items
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.b0[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.b0[1]}
                          </td>
                        </tr>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">
                            Less: Extraordinary Items
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.b1[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.b1[1]}
                          </td>
                        </tr>
                        <tr className="bg-gray-200 text-blue-900">
                          <td className="p-2 font-bold">
                            Profit or Loss before Tax
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.C[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.C[1]}
                          </td>
                        </tr>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">Less: Current Tax</td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.c0[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.c0[1]}
                          </td>
                        </tr>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">Less: Deferred Tax</td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.c1[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.c1[1]}
                          </td>
                        </tr>
                        <tr className="bg-gray-200 text-blue-900">
                          <td className="p-2 font-bold">
                            Profit or Loss After Tax
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.D[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.D[1]}
                          </td>
                        </tr>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">
                            Less: Transfer to Reserves
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.d0[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.d0[1]}
                          </td>
                        </tr>
                        <tr className="bg-gray-200 text-blue-900">
                          <td className="p-2 font-bold">
                            Balance Transferred to Balance Sheet
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.E[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.E[1]}
                          </td>
                        </tr>
                        <tr className="bg-gray-200 text-blue-900">
                          <td className="p-2 font-bold">
                            Earnings Per Equity Share (in Rs)
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.F[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.F[1]}
                          </td>
                        </tr>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">Basic</td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.f0[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.f0[1]}
                          </td>
                        </tr>
                        <tr className="hover:bg-blue-100 border-b">
                          <td className="p-2 font-bold">Diluted</td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.f1[0]}
                          </td>
                          <td className="p-2 text-center font-bold">
                            {selectedCard.f1[1]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AnnualReports;
