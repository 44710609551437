import React, { useState, useEffect, useRef } from "react";
import LineGraph from "../Products/LineGraph";
import { useLocation } from "react-router-dom";
import { IoCalendar } from "react-icons/io5";
import {
  BsGraphUpArrow,
  BsGraphDownArrow,
  BsArrowBarRight,
} from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Performance.css";
import Header from "../../components/Header/Header";
import Loader2 from "../../components/Loaders/Loader2";
import AlertYellow from "../../components/Alerts/AlertYellow.jsx";
import { Helmet } from "react-helmet";

function WALRPerformance() {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [sortingChanged, setSortingChanged] = useState(false);
  const [loader, setLoader] = useState(false);
  const [calAmount, setCalAmount] = useState("100000");
  const [result, setResult] = useState(0);
  const [duration, setDuration] = useState("");
  const [showModal1, setShowModal1] = useState(false);
  const [fromDate, setFromDate] = useState(
    location.state?.graphInput.fromDate || "2013-11"
  );
  const [toDate, setToDate] = useState(
    location.state?.graphInput.toDate || "2024-04"
  );
  const [fromDate2, setFromDate2] = useState(
    location.state?.graphInput.fromDate || "2013-11"
  );
  const [toDate2, setToDate2] = useState(
    location.state?.graphInput.toDate || "2024-04"
  );
  const [amount, setAmount] = useState(
    location.state?.graphInput.amount2 || "100000"
  );
  const [graphData, setGraphData] = useState({});
  const [graphXAxis, setGraphXAxis] = useState({});

  function calculateXaxis(fromDate, toDate) {
    const months = []; // Array to store month names
    const startDate = new Date(fromDate + "-01"); // Convert to JS Date (assuming day is always 01)
    const endDate = new Date(toDate + "-01"); // Convert to JS Date (assuming day is always 01)
    let currentDate = startDate;

    while (currentDate <= endDate) {
      const monthName = currentDate.toLocaleString("default", {
        month: "short",
      }); // Get month name (e.g., Jan, Feb)
      const year = currentDate.getFullYear().toString().slice(-2); // Get last two digits of year (e.g., 22 for 2022)

      months.push(`${monthName}-${year}`); // Push formatted month name into the array (e.g., Jan-22)

      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  }

  const fetchGraphData = (fundName, startDate, endDate, amount) => {
    fetch(
      `https://api-scdndestates.com/api/get-data/?fund_name=${fundName}&start_date=${startDate}&end_date=${endDate}&amount=${amount}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setGraphData(data["data"]);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const fetchCalData = (fundName, duration, calAmount) => {
    fetch(
      `https://api-scdndestates.com/api/get-walr-cal-data/?fund_name=${fundName}&duration=${duration}&calAmount=${calAmount}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setResult(data.data);
        setDuration("");
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  useEffect(() => {
    if (calAmount && duration !== "") {
      fetchCalData("WALR", duration, calAmount);
    } else if (!calAmount) {
      setShowModal1(true);
      setTimeout(() => {
        setShowModal1(false);
      }, 5000);
    }
  }, [duration]);

  const fetchTableData = (fundName, startDate, endDate, amount) => {
    setSortingChanged(false);
    fetch(
      `https://api-scdndestates.com/api/get-table-data/?fund_name=${fundName}&start_date=${startDate}&end_date=${endDate}&amount=${amount}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch tabledata");
        }
        return response.json();
      })
      .then((response) => {
        setTableData(response["result"]);
        const sortedValues = response["result"].sort((a, b) => b[0] - a[0]);
        setSortedTableData(sortedValues);
        setSortingChanged(true);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const rowAnimationClass = (row) => {
    const currentIndex = sortedTableData.findIndex((r) => r === row);
    const originalIndex = tableData.findIndex((r) => r === row);
    if (
      originalIndex - currentIndex === 1 ||
      originalIndex - currentIndex === -1
    )
      return "move1";
    else if (
      originalIndex - currentIndex === 2 ||
      originalIndex - currentIndex === -2
    )
      return "move2";
    else if (
      originalIndex - currentIndex === 3 ||
      originalIndex - currentIndex === -3
    )
      return "move3";
    else if (
      originalIndex - currentIndex === 4 ||
      originalIndex - currentIndex === -4
    )
      return "move4";
    else return "no-change";
  };

  const submitForm = (e) => {
    e.preventDefault();
    fetchTableData("WALR", fromDate2, toDate2, amount);
    fetchGraphData("WALR", fromDate2, toDate2, amount);
    setGraphXAxis(calculateXaxis(fromDate2, toDate));
  };

  useEffect(() => {
    fetch(
      `https://api-scdndestates.com/api/get-start-and-end-date/?product=WALR`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        const endDate = data?.end || "2024-04"; // Use a fallback date if end date is invalid
        setToDate(endDate);
        fetchTableData("WALR", fromDate, endDate, amount);
        fetchGraphData("WALR", fromDate, endDate, amount);
        setGraphXAxis(calculateXaxis(fromDate, endDate));
      })
      .catch((error) => {
        // Handle error
      });
  }, []);

  const getTextColorClass = (value) => {
    const riskValues = tableData.map((data) => data[1]); // Extract "Risk" values
    const sortedRiskValues = riskValues.slice().sort((a, b) => a - b);
    if (value === sortedRiskValues[0]) {
      return "text-danger4";
    } else if (value === sortedRiskValues[1]) {
      return "text-danger3";
    } else if (value === sortedRiskValues[2]) {
      return "text-danger2";
    } else if (value === sortedRiskValues[3]) {
      return "text-danger1";
    } else {
      return "text-danger0";
    }
  };

  const getTextColorClass2 = (value) => {
    const riskValues = tableData.map((data) => data[0]); // Extract "Risk" values
    const sortedRiskValues = riskValues.slice().sort((a, b) => a - b);

    if (value === sortedRiskValues[0]) {
      return "text-success4";
    } else if (value === sortedRiskValues[1]) {
      return "text-success3";
    } else if (value === sortedRiskValues[2]) {
      return "text-success2";
    } else if (value === sortedRiskValues[3]) {
      return "text-success1";
    } else {
      return "text-success0";
    }
  };

  let data = [];

  data = [
    // Append each comment as a new entry
    ...sortedTableData?.map((data, index) => ({
      id: 0 + index,
      name: data?.[2],
      growth: data?.[0],
      risk: data?.[1],
    })),
  ];
  const ListContainer = ({ initialData }) => {
    const [listData, setListData] = useState(initialData);

    const mixItUp = () => {
      setListData((prevData) => {
        const array = [...prevData];
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      });
    };

    const ripItUp = (key) => {
      setListData((prevData) => prevData.filter((item) => item.key !== key));
    };

    const resetIt = () => {
      setListData(initialData);
    };
  };

  return (
    <>
      <Helmet>
        <title>WALR Performance history | SCDND Estates</title>
        <meta
          name="description"
          content="A glimpse into the performance history of WALR bonds in comparison with centralized fixed income investments. Also view the growth (CAGR) and the risk (standard deviation) of money market, gilt funds, corporate bonds, fixed deposits and see how they stack up against each other in terms of risk-return favourability."
        />
        <meta
          name="description"
          content="Discover how WALR bonds compare to traditional fixed-income investments in terms of risk-return analysis. Compare the growth rate (CAGR) and value-at-risk (standard deviation) of WALR bonds alongside money market funds, gilt funds, corporate bonds, and fixed deposits."
        />
        <link rel="canonical" href="https://scdndestates.com/walrbonds/performance" />
        {/* Open Graph */}
        <meta property="og:title" content="WALR Performance history - SCDND Estates" />
        <meta
          property="og:description"
          content="A glimpse into the performance history of WALR bonds in comparison with centralized fixed income investments. Also view the growth (CAGR) and the risk (standard deviation) of money market, gilt funds, corporate bonds, fixed deposits and see how they stack up against each other in terms of risk-return favourability."
        />

        <meta
          property="og:url"
          content="https://scdndestates.com/walrbonds/performance"
        />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="WALR Performance history - SCDND Estates" />
        <meta
          name="twitter:description"
          content="A glimpse into the performance history of WALR bonds in comparison with centralized fixed income investments. Also view the growth (CAGR) and the risk (standard deviation) of money market, gilt funds, corporate bonds, fixed deposits and see how they stack up against each other in terms of risk-return favourability."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      {loader ? (
        <Loader2 />
      ) : (
        <>
          <Header />
          <div className="max-w-[1800px] mx-auto">
            <div className=" after-header w-[90%] mx-auto ">
              {/* Path Natigation */}
              <div className="col-12 py-2">
                <h6 className="font-size1 m-0 p-0">
                  <a href="/">
                    <span className="header-link text-sm">Home</span>
                    <span className="text-secondary text-sm"> &gt; </span>
                  </a>
                  <a href="/products">
                    <span className="header-link text-sm">Products</span>
                    <span className="text-secondary text-sm"> &gt; </span>
                  </a>
                  <a href="#">
                    <span className="text-sm underline text-gray-500">
                      WALR Performance
                    </span>
                  </a>
                </h6>
              </div>

              <div className="lg:flex justify-between pt-3">
                {/* Left Section */}
                <div className="w-full lg:w-[65%] overflow-scroll">
                  <div className="min-w-[600px]">
                    {/* Graph Head */}
                    <form
                      className="rounded px-3 py-1 bg-[#0590F8]"
                      onSubmit={submitForm}
                    >
                      <div className="flex flex-wrap items-end justify-between px-2 py-1.5 gap-2 ">
                        {/* From Div */}
                        <div className="font-semibold ">
                          <label
                            for="from"
                            className="text-light flex flex-col text-sm"
                          >
                            From&nbsp;
                            <span className="flex gap-1">
                              <span className="flex items-center ">
                                <DatePicker
                                  className="px-3 py-0.5 rounded-md text-black bg-white w-40 text-sm outline-none"
                                  style={{ backgroundColor: "#623eba" }}
                                  name="from"
                                  id="from"
                                  selected={
                                    fromDate
                                      ? new Date(fromDate2)
                                      : new Date(fromDate)
                                  }
                                  onChange={(date) => {
                                    setFromDate2(
                                      date.toISOString().slice(0, 7)
                                    );
                                  }}
                                  minDate={new Date("2013-11")}
                                  maxDate={new Date(toDate)}
                                  dateFormat="MMMM yyyy"
                                  showMonthYearPicker
                                />
                              </span>
                              <IoCalendar size={25} className="text-white" />
                            </span>
                          </label>
                        </div>

                        {/* To Div */}
                        <div className="font-semibold flex">
                          <label
                            for="to"
                            className="text-light  flex flex-col text-sm"
                          >
                            To&nbsp;
                            <span className="flex gap-1">
                              <span className="flex items-center">
                                <DatePicker
                                  className="px-3 py-0.5 rounded-md text-black bg-white w-40 text-sm outline-none"
                                  style={{ backgroundColor: "#623eba" }}
                                  name="to"
                                  id="to"
                                  selected={
                                    toDate2
                                      ? new Date(toDate2)
                                      : new Date(toDate)
                                  }
                                  onChange={(date) =>
                                    setToDate2(date.toISOString()?.slice(0, 7))
                                  }
                                  minDate={new Date("2013-11")}
                                  maxDate={new Date(toDate)}
                                  dateFormat="MMMM yyyy"
                                  showMonthYearPicker
                                />
                              </span>
                              <IoCalendar size={25} className="text-white" />
                            </span>
                          </label>
                        </div>

                        {/* Investment */}
                        <div className="flex flex-col">
                          <label
                            for="amount"
                            className="text-light font-semibold text-sm"
                          >
                            Investment
                          </label>
                          <select
                            name="amount"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="m-0 btn btn-secondary btn-sm font-bold bg-white w-40 rounded-md text-sm px-2 py-0.5 text-left"
                          >
                            <option value="100000">₹ 1,00,000</option>
                            <option value="200000">₹ 2,00,000</option>
                            <option value="300000">₹ 3,00,000</option>
                            <option value="400000">₹ 4,00,000</option>
                            <option value="500000">₹ 5,00,000</option>
                          </select>
                        </div>

                        {/* Check Buttom */}

                        <div className="w-fit slice hover:scale-95 duration-200 ">
                          <input
                            type="submit"
                            value="Check"
                            className="btn btn-sm  animated-check text-black font-bold px-4"
                          />
                        </div>
                      </div>
                    </form>

                    <LineGraph
                      fundName="WALR"
                      graphData={graphData}
                      tableData={tableData}
                      graphXAxis={graphXAxis}
                    />
                  </div>
                </div>

                {/* Right Section */}
                <div className="w-full lg:w-[32%] h-fit border-[#0590F8] text-xs md:text-sm pt-3 lg:-mt-4 rounded-lg">
                  <table className="w-full table-borderless text-sm table-sm">
                    <thead className="text-white bg-[#0590F8]">
                      <tr className="font-bold flex justify-between items-center text-xs md:text-sm 2xl:text-base">
                        <td className="w-1/4">Growth (%)</td>
                        <td className="w-1/4">Risk (%)</td>
                        <td className="w-2/4">Products</td>
                      </tr>
                    </thead>
                    <tbody className="flex flex-col gap-2 font-semibold mt-2">
                      {data.map((row) => (
                        <tr
                          key={row.id}
                          className={`rounded-lg shadow-[0px_0px_1px_1px_#00000024] flex justify-between cursor-pointer text-xs md:text-sm 2xl:text-base ${
                            sortingChanged ? "fade-in-out" : ""
                          } ${sortingChanged && rowAnimationClass(row)}`}
                        >
                          <td
                            className={`${getTextColorClass2(
                              row.growth
                            )} flex items-center gap-1 w-1/4`}
                          >
                            <span>
                              <BsGraphUpArrow />
                            </span>
                            <span>{row.growth}</span>
                          </td>
                          <td
                            className={`${getTextColorClass(
                              row.risk
                            )} flex items-center gap-1 w-1/4`}
                          >
                            <span>
                              <BsGraphDownArrow />
                            </span>
                            <span>{row.risk}</span>
                          </td>
                          <td className="flex items-center w-2/4">
                            <span>{row.name}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="flex flex-col items-center justify-center p-2 mt-4">
                    <div className="my-1 flex items-center">
                      <span className="font-size2">Low Risk &nbsp;</span>
                      <span
                        className="px-3 py-2 "
                        style={{ backgroundColor: "#FDC625" }}
                      ></span>
                      <span
                        className="px-3 py-2"
                        style={{ backgroundColor: "#ffad09f2" }}
                      ></span>
                      <span
                        className="px-3 py-2"
                        style={{ backgroundColor: "#ff7f00" }}
                      ></span>
                      <span
                        className="px-3 py-2"
                        style={{ backgroundColor: "#ff4001" }}
                      ></span>
                      <span
                        className="px-3 py-2"
                        style={{ backgroundColor: "#cb212c" }}
                      ></span>
                      <span className="font-size2">&nbsp; High Risk</span>
                    </div>
                  </div>
                  {/* Investment Calculation */}
                  <div className="relative p-3 mt-5 rounded-lg text-sm border shadow-md">
                    <h2 className="absolute -top-3 font-bold rounded-md flex gap-2 border bg-white text-black items-center">
                      <p className="py-1 items-center flex">
                        <marquee>Calculate your return on WALR bonds</marquee>
                      </p>
                    </h2>
                    <div className="flex flex-wrap justify-between mt-3 px-2">
                      <div>
                        <p className="font-semibold text-sm">If you invest</p>
                        <div className="py-2 flex">
                          <div className="bg-[#0590F8] text-white font-bold px-2 py-0.5 rounded-l-md flex items-center text-sm">
                            ₹
                          </div>
                          <input
                            type="number"
                            name="calAmount"
                            id="calAmount"
                            value={calAmount}
                            onChange={(e) => {
                              setCalAmount(e.target.value);
                            }}
                            placeholder="Enter Amount..."
                            className="custom-select1 border-1 border-[#CCCCCC] rounded-r-md px-2 py-1 md:w-40 w-full placeholder:text-sm placeholder:font-semibold"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <p className="py-1 font-semibold text-sm">
                          for duration
                        </p>
                        <select
                          className="custom-select md:w-fit w-full m-0 py-1 px-2 text-sm"
                          value={duration}
                          onChange={(e) => {
                            setDuration(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select duration
                          </option>
                          <option value="6 Months">6 Months</option>
                          <option value="1 Year">1 Year</option>
                          <option value="2 Years">2 Years</option>
                          <option value="3 Years">3 Years</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-4 px-2 pb-2 text-sm">
                      you would have{" "}
                      <span className="font-bold text-[#0590F8] text-sm">
                        {result !== 0 ? (
                          <>₹{parseInt(result).toLocaleString("en-In")}</>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Reference */}

              <div className="w-full border rounded-lg p-3 mt-5">
                <h2 className="text-xl font-bold px-1.5 relative -top-8 bg-white w-fit">
                  References
                </h2>
                <p className="text-sm 2xl:text-base">
                  <ul className="leading-6">
                    <li className="para-style2">
                      <span className="font-black">WALR :</span>{" "}
                      <span className="font-semibold">
                        'Monthly Fresh Weighted Average Lending Rate of
                        Scheduled Commercial Banks', as published monthly by
                        Reserve Bank of India under Press Releases.
                      </span>
                      <br />
                      <span className="font-bold">
                        <span className="font-bold">URL</span>
                      </span>{" "}
                      :{" "}
                      <a
                        href="https://www.rbi.org.in/Scripts/BS_PressReleaseDisplay.aspx"
                        target="blank"
                        className="text-blue-600 hover:font-semibold duration-200 hover:text-blue-700"
                      >
                        https://www.rbi.org.in/Scripts/BS_PressReleaseDisplay.aspx
                      </a>
                    </li>
                    <br />
                    <li className="para-style2">
                      <span className="font-black">Corporate</span>{" "}
                      <span className="font-semibold">
                        : 'S&P BSE India Corporate Bond Index', as published
                        daily (averaged to monthly) by S&P Dow Jones Indices
                        (S&P Global).
                      </span>
                      <br />
                      <span className="font-bold">URL</span> :{" "}
                      <a
                        href="https://www.spglobal.com/spdji/en/indices/fixed-income/sp-bse-india-corporate-bond-index/#overview"
                        target="blank"
                        className="text-blue-600 hover:font-semibold duration-200 hover:text-blue-700"
                      >
                        https://www.spglobal.com/spdji/en/indices/fixed-income/sp-bse-india-corporate-bond-index/#overview
                      </a>
                    </li>
                    <br />
                    <li className="para-style2">
                      <span className="font-black">Gilt Fund : </span>{" "}
                      <span className="font-semibold">
                        {" "}
                        'Nifty 10-year Benchmark G-sec Index', as published
                        daily (averaged to monthly) by NSE indices- Fixed
                        Income.
                      </span>
                      <br />
                      <span className="font-bold">URL</span> :{" "}
                      <a
                        href="https://niftyindices.com/reports/historical-data"
                        target="blank"
                        className="text-blue-600 hover:font-semibold duration-200 hover:text-blue-700"
                      >
                        {" "}
                        https://niftyindices.com/reports/historical-data
                      </a>
                    </li>
                    <br />
                    <li className="para-style2">
                      <span className="font-black">Fixed Deposit :</span>{" "}
                      <span>
                        'Outstanding Rupee Term Deposits (WADTDR) of Scheduled
                        Commercial Banks', as published monthly by Reserve Bank
                        of India under Press Releases.
                      </span>
                      <br />
                      <span className="font-bold">URL</span> :{" "}
                      <a
                        href="https://www.rbi.org.in/Scripts/BS_PressReleaseDisplay.aspx"
                        target="blank"
                        className="text-blue-600 hover:font-semibold duration-200 hover:text-blue-700"
                      >
                        https://www.rbi.org.in/Scripts/BS_PressReleaseDisplay.aspx
                      </a>
                    </li>
                    <br />
                    <li className="para-style2">
                      <span className="font-black">Money Market :</span>{" "}
                      <span className="font-semibold">
                        'Daily Weighted Average Call/Notice Money Rates', as
                        published under Statistics- Money Market by Database on
                        Indian Economy, Reserve Bank of India.
                      </span>
                      <br />
                      <span className="font-bold">URL</span> :{" "}
                      <a
                        href="https://dbie.rbi.org.in/#/dbie/statistics"
                        target="blank"
                        className="text-blue-600 hover:font-semibold duration-200 hover:text-blue-700"
                      >
                        {" "}
                        https://dbie.rbi.org.in/#/dbie/statistics
                      </a>
                    </li>
                    <br />
                    <li className="para-style2 font-bold flex flex-wrap">
                      Want to know about our products? &nbsp;
                      <a
                        href="/walrbonds/overview/"
                        className="text-black bg-[#DBEDF9] rounded-md px-2 py-1 flex gap-1 items-center font-semibold shadow-md hover:scale-95 duration-200 text-sm"
                      >
                        Go to WALR Overview <BsArrowBarRight />
                      </a>
                    </li>
                    <br />
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Modal for showing alert */}
      {showModal1 && (
        <AlertYellow
          message="Enter the Amount!"
          onClose={() => setShowModal1(false)}
        />
      )}
    </>
  );
}

export default WALRPerformance;
