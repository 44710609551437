import React, { useState } from "react";
import "./PopUp.css";
import { useNavigate } from "react-router-dom";

function PopUp1() {
  const navigate = useNavigate();
  return (
    <>
      <div className="demo1-container">
        <div className="pop2-content shadow-[inset_0px_0px_7px_0px_#4299e1] pop-up-animation">
          <img src="/images/blocked.webp" alt="scdnd" className="text-center" />
          <h6 className="p-4 text-md text-center font-semibold leading-5 text-[#212121]">
            You do not have access to navigate to this page. Please
            Login or Register
          </h6>
          <div className="flex justify-center gap-4">
            <button
              className="rounded-md bg-[#6cbaff] px-7 py-2.5 text-sm font-semibold text-white  hover:bg-[#4da9fa] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3698ee] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login Now
            </button>
            <button
              className="rounded-md bg-[#fff] px-7 py-2.5 text-sm font-semibold text-[#212121]  hover:bg-[#f5f5f5]  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3698ee] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] "
              data-mdb-ripple-init
              data-mdb-ripple-color="dark"
              onClick={() => {
                window.history.back();
              }}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopUp1;