import React, { useState, useEffect } from "react";
import { BsArrowBarRight } from "react-icons/bs";
import Chart from "react-apexcharts";

const casesData = {
  "Oct 16": {
    title: "Demonetization",
    date: "November, 2016",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/demn.webp",
    link: "walrbonds/performance/",
  },
  "Oct 18": {
    title: "ILFS Crisis",
    date: "October, 2018",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/ilfs.webp",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
  "Jan 22": {
    title: "Russia-Ukraine WAR",
    date: "February, 2022",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/rus-ukr.webp",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
  "Jan 20": {
    title: "Covid-19",
    date: "March, 2020",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/covid.webp",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
  "Apr 22": {
    title: "US Inflationary 9 Care",
    date: "May, 2022",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/us-9.webp",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
  "Jul 23": {
    title: "Israel-Palestine War",
    date: "August, 2023",
    points: [
      "Oil crosses 100$",
      "Food prices jumped 20-30%",
      "Rubel crashes by 50%",
      "Gold Rises 4%",
    ],
    imageUrl: "/images/Isr-pal-war.webp",
    link: "https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/",
  },
};

const CollateralChart = () => {
  const [tableContent, setTableContent] = useState(
    <a
      href={casesData["Jul 23"].link}
      className="w-full md:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 md:flex justify-between transition duration-300 ease-in-out"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="w-full md:w-2/3 relative">
        <h5 className="text-lg font-bold">Case: {casesData["Jul 23"].title}</h5>
        <ul className="text-sm pl-2 mt-2">
          {casesData["Jul 23"].points.map((point, index) => (
            <div className="flex items-center justify-start gap-x-1">
              <svg
                fill="none"
                height="14"
                viewBox="0 0 24 24"
                width="14"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_9347219"
              >
                <linearGradient
                  id="paint0_linear_3397_3138"
                  gradientUnits="userSpaceOnUse"
                  x1="12"
                  x2="12"
                  y1="2"
                  y2="22"
                >
                  <stop offset="0" stop-color="#138bd7"></stop>
                  <stop offset="1" stop-color="#62aafb"></stop>
                </linearGradient>
                <path
                  d="m12 2c-5.51 0-10 4.49-10 10s4.49 10 10 10 10-4.49 10-10-4.49-10-10-10zm2.79 10.53-3.53 3.53c-.15.15-.34.22-.53.22s-.38-.07-.53-.22c-.29-.29-.29-.77 0-1.06l3-3-3-3c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l3.53 3.53c.3.29.3.77 0 1.06z"
                  fill="url(#paint0_linear_3397_3138)"
                ></path>
              </svg>
              <li key={index} className="font-semibold">
                {point}
              </li>
            </div>
          ))}
        </ul>
        <div className=" bottom-0 absolute text-sm font-bold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center gap-x-1 ">
          <svg
            id="fi_4023104"
            className="w-4 h-4 fill-gray-700"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
          >
            <path d="m12 1a11 11 0 1 0 11 11 10.994 10.994 0 0 0 -11-11zm1.41 15.24-.7.71a4 4 0 0 1 -5.66-5.66l.71-.7a1 1 0 0 1 1.41 1.41l-.71.71a2 2 0 0 0 2.83 2.83l.71-.71a1 1 0 0 1 1.41 1.41zm.36-4.59-2.12 2.12a1 1 0 0 1 -1.42-1.42l2.12-2.12a1 1 0 0 1 1.42 1.42zm3.18 1.06-.71.7a1 1 0 0 1 -1.41-1.41l.71-.71a2 2 0 0 0 -2.83-2.83l-.71.71a1 1 0 0 1 -1.41-1.41l.7-.71a4 4 0 0 1 5.66 5.66z"></path>
          </svg>{" "}
          Read more
        </div>
      </div>
      <div className="object-cover md:w-1/3 h-full rounded-xl  flex flex-col items-end justify-center ">
        <div className="text-xs font-bold mb-1 text-[#313131] tracking-wide">
          {casesData["Jul 23"].date}
        </div>
        <img
          className="rounded-lg"
          src={casesData["Jul 23"].imageUrl}
          alt={casesData["Jul 23"].title}
        />
      </div>
    </a>
  );

  let lastScrollPosition = 0;

  const maintainScrollPosition = (event) => {
    const chartContainer = document.getElementById("chart");
    if (chartContainer) {
      chartContainer.scrollLeft = lastScrollPosition;
    }
  };

  const updateScrollPosition = () => {
    const chartContainer = document.getElementById("chart");
    if (chartContainer) {
      lastScrollPosition = chartContainer.scrollLeft;
    }
  };

  // Attach event listeners
  useEffect(() => {
    const chartContainer = document.getElementById("chart");
    if (chartContainer) {
      chartContainer.addEventListener("scroll", updateScrollPosition);
      chartContainer.addEventListener("mouseenter", maintainScrollPosition);
    }
    return () => {
      if (chartContainer) {
        chartContainer.removeEventListener("scroll", updateScrollPosition);
        chartContainer.removeEventListener(
          "mouseenter",
          maintainScrollPosition
        );
      }
    };
  }, []);

  const handleClick = (event, chartContext, config) => {
    event.preventDefault(); // Prevent default event behavior
    event.stopPropagation(); // Stop propagation to parent elements
    if (config.dataPointIndex !== -1) {
      const clickedLabel = options.labels[config.dataPointIndex];
      const caseInfo = casesData[clickedLabel];

      if (caseInfo) {
        setTableContent(
          <a
            href={caseInfo.link}
            className="w-full md:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 md:flex justify-between transition duration-300 ease-in-out"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="w-full md:w-2/3 relative">
              <h5 className="text-lg font-bold">Case: {caseInfo.title}</h5>
              <ul className="text-sm pl-2 mt-2">
                {caseInfo.points.map((point, index) => (
                  <div className="flex items-center justify-start gap-x-1">
                    <svg
                      fill="none"
                      height="14"
                      viewBox="0 0 24 24"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_9347219"
                    >
                      <linearGradient
                        id="paint0_linear_3397_3138"
                        gradientUnits="userSpaceOnUse"
                        x1="12"
                        x2="12"
                        y1="2"
                        y2="22"
                      >
                        <stop offset="0" stop-color="#138bd7"></stop>
                        <stop offset="1" stop-color="#62aafb"></stop>
                      </linearGradient>
                      <path
                        d="m12 2c-5.51 0-10 4.49-10 10s4.49 10 10 10 10-4.49 10-10-4.49-10-10-10zm2.79 10.53-3.53 3.53c-.15.15-.34.22-.53.22s-.38-.07-.53-.22c-.29-.29-.29-.77 0-1.06l3-3-3-3c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l3.53 3.53c.3.29.3.77 0 1.06z"
                        fill="url(#paint0_linear_3397_3138)"
                      ></path>
                    </svg>
                    <li key={index} className="font-semibold">
                      {point}
                    </li>
                  </div>
                ))}
              </ul>
              <div className="-bottom-4 md:bottom-0 absolute text-xs md:text-sm font-bold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center gap-x-1 ">
                <svg
                  id="fi_4023104"
                  className="w-3 h-3 md:w-4 md:h-4 fill-gray-700"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                >
                  <path d="m12 1a11 11 0 1 0 11 11 10.994 10.994 0 0 0 -11-11zm1.41 15.24-.7.71a4 4 0 0 1 -5.66-5.66l.71-.7a1 1 0 0 1 1.41 1.41l-.71.71a2 2 0 0 0 2.83 2.83l.71-.71a1 1 0 0 1 1.41 1.41zm.36-4.59-2.12 2.12a1 1 0 0 1 -1.42-1.42l2.12-2.12a1 1 0 0 1 1.42 1.42zm3.18 1.06-.71.7a1 1 0 0 1 -1.41-1.41l.71-.71a2 2 0 0 0 -2.83-2.83l-.71.71a1 1 0 0 1 -1.41-1.41l.7-.71a4 4 0 0 1 5.66 5.66z"></path>
                </svg>{" "}
                Read more
              </div>
            </div>
            <div className="object-cover md:w-1/3 h-full rounded-xl  flex flex-col items-end justify-center ">
              <div className="text-xs font-bold mb-1 text-[#313131] tracking-wide">
                {caseInfo.date}
              </div>
              <img
                className="rounded-lg "
                src={caseInfo.imageUrl}
                alt={caseInfo.title}
              />
            </div>
          </a>
        );
      }
    }
  };

  function generateDateLabels(startYear, startMonth, endYear, endMonth) {
    const labels = [];
    const months = ["Apr", "Jul", "Oct", "Jan"];

    for (let year = startYear; year <= endYear; year++) {
      for (
        let month = year === startYear ? startMonth : 0;
        month <= (year === endYear ? endMonth : 3);
        month++
      ) {
        labels.push(`${months[month]} ${year.toString().slice(-2)}`);
      }
    }

    return labels;
  }

  // Generate labels from April 2016 (2016, 3) to June 2024 (2024, 5)
  const labels = generateDateLabels(2016, 0, 2024, 2);
  const series = [
    {
      name: "WALR",
      data: [
        100.0, 100.28, 96.74, 98.4, 98.79, 97.35, 97.14, 96.75, 96.53, 97.08,
        97.87, 96.22, 96.6, 96.79, 96.92, 96.75, 96.04, 95.74, 96.24, 95.96,
        95.53, 93.57, 93.8, 93.57, 93.15, 94.01, 94.69, 96.26, 96.12, 99.68,
        98.91, 98.41, 103.24, 104.74, 106.39,
      ].reverse(),
    },
    {
      name: "RESIDEX",
      data: [
        100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0,
        100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0,
        100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0,
        100.0, 100.0, 100.0, 100.0, 100.0,
      ].reverse(),
    },
  ];

  const options = {
    legend: { position: "top" },
    colors: ["#0590F8", "#009688"],
    chart: {
      type: "line",
      id: "areachart-2",
      events: {
        mouseMove: (event, chartContext, config) => {
          handleClick(event, chartContext, config);
        },
        click: (event, chartContext, config) => {
          handleClick(event, chartContext, config);
        },
      },
      animations: { enabled: false },
    },

    responsive: [
      {
        breakpoint: 2300,
        options: {
          chart: {
            width: 900,
            height: 500,
          },
        },
      },
      {
        breakpoint: 1300,
        options: {
          chart: {
            width: 900,
            height: 450,
          },
        },
      },
      {
        breakpoint: 976,
        options: {
          chart: {
            width: 900,
            height: 350,
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 900,
            height: 350,
          },
        },
      },
    ],
    // it will modify the y-axis according to need
    yaxis: {
      min: 80,
      max: 160,
      tickAmount: 4,
      labels: { formatter: (value) => value + "%" },
    },
    annotations: {
      yaxis: [
        {
          y: 100,
          borderColor: "red",
          label: {
            borderColor: "red",
            style: {
              pointerEvents: "none", // Prevent hover events
            },
          },
        },
      ],
      xaxis: Object.keys(casesData).map((label) => ({
        x: label,
        borderColor: "orange",
        strokeDashArray: 5,
        label: {
          borderColor: "orange",
          text: casesData[label].title,
          style: {
            pointerEvents: "none", // Prevent hover events
          },
        },
      })),
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    labels: labels.reverse(),
    xaxis: {
      tickAmount: 12,
      type: "date",
      labels: { rotate: -45, rotateAlways: true },
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: false,
      y: {
        formatter: function (value) {
          return value + "%"; // Format the tooltip values
        },
      },
    },
  };

  return (
    <div className="m-0 p-2 w-full lg:min-w-[700px] xl:max-w-[970px] border-2 rounded-xl bg-[#f5fbff] overflow-hidden">
      <div className="flex flex-col w-full px-2">
        <div id="chart" className="p-2 w-full overflow-x-scroll">
          <div className="flex justify-center">
            <span className="text-danger">*</span>
            <span className="font-size1">
              Hover on the Graph to see the details.
            </span>
          </div>
          <Chart
            className="w-max h-full"
            options={options}
            series={series}
            type="line"
            width="1000"
            height="400"
          />
        </div>
        <div className="mt-2 w-full flex-col items-center justify-center text-sm font-semibold bg-[#f5fbff]">
          <div className="w-full flex justify-center items-center transition duration-300 ease-in-out overflow-x-scroll">
            {tableContent && tableContent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollateralChart;
