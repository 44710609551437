import React, { useState, useEffect, useRef } from "react";
import "./Blogs.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const App = () => {
  const sectionRef = useRef(null);
  const triggerRef = useRef(null);
  const stepRefs = useRef([]);
  const stepHead = useRef([]);
  const [month, setMonth] = useState("April, 2023");
  const [icon, setIcon] = useState(null);
  const textContainerRefHead = useRef(null);
  const textContainerRef1 = useRef(null);
  const textContainerRef2 = useRef(null);
  const textContainerRef3 = useRef(null);
  const textContainerRef3Head = useRef(null);
  const textContainerRef4 = useRef(null);
  const textContainerRef4Head = useRef(null);
  const textContainerRef5 = useRef(null);
  const textContainerRef5Head = useRef(null);
  const textContainerRef6 = useRef(null);
  const textContainerRef6Head = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const pin = (widthLength) => {
      gsap.fromTo(
        sectionRef.current,
        { translateX: 0 },
        {
          translateX: widthLength,
          ease: "none",
          duration: 1,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top top",
            end: "2000 top", // Set the end point based on the width
            scrub: 1,
            pin: true,
            pinSpacing: false,
            onUpdate: (self) => {
              // Calculate the scroll progress and set the month accordingly
              const totalScrollDistance = Math.abs(
                parseInt(widthLength.replace("vw", ""))
              ); // Total width of scrollable area
              const currentScroll = self.scroll(); // Get current scroll position
              const progress = currentScroll / totalScrollDistance; // Calculate progress (0 to 1)

              // Define breakpoints for changing months based on scroll progress
              if (progress < 0.1) {
                setMonth("April, 2023");
              } else if (progress >= 0.1 && progress < 0.2) {
                setMonth("June, 2023");
              } else if (progress >= 0.2 && progress < 0.3) {
                setMonth("August, 2023");
              } else if (progress >= 0.3 && progress < 0.4) {
                setMonth("October, 2023");
              } else if (progress >= 0.4 && progress < 0.5) {
                setMonth("December, 2023");
              } else if (progress >= 0.5 && progress < 0.6) {
                setMonth("March, 2024");
              } else if (progress >= 0.6 && progress < 0.7) {
                setMonth("June, 2024");
              } else if (progress >= 0.7 && progress < 0.8) {
                setMonth("August, 2024");
              } else if (progress >= 0.8 && progress < 0.9) {
                setMonth("September, 2024");
              } else if (progress >= 0.9 && progress < 1.0) {
                setMonth("November, 2024");
              } else if (progress >= 1.0 && progress < 1.1) {
                setMonth("January, 2025");
              } else if (progress >= 1.1 && progress < 1.2) {
                setMonth("April, 2025");
              } else if (progress >= 1.2 && progress < 1.3) {
                setMonth("May, 2025");
              } else if (progress >= 1.3 && progress < 1.4) {
                setMonth("August, 2025");
              } else if (progress >= 1.4 && progress < 1.5) {
                setMonth("October, 2025");
              } else if (progress >= 1.5 && progress < 1.6) {
                setMonth("December, 2025");
              } else if (progress >= 1.6 && progress < 1.7) {
                setMonth("December, 2025");
              } else if (progress >= 1.7 && progress < 1.8) {
                setMonth("December, 2025");
              }
            },
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 768px) ": () => {
        pin("-650vw");
      },

      "(max-width: 765px)": () => {
        pin("-1250vw");
      },
    });

    // Create animations for each step
    stepRefs.current.forEach((step, stepHead, index) => {
      gsap.fromTo(
        step,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: step,
            start: "top 80%",
            end: "top 60%",
            scrub: 1,
            markers: false,
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    const fade = (headingFadeIn, headingFadeOut) => {
      gsap.fromTo(
        stepHead.current,
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          y: -100,
          duration: 15,
          ease: "power2.in",
          scrollTrigger: {
            trigger: stepHead.current,
            start: headingFadeIn,
            end: headingFadeOut,
            scrub: 1,
            markers: false,
            toggleActions: "play none none reverse",
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        fade("top -80%", "top -100%");
      },

      "(min-width: 1500px) and (max-width: 1736px)": () => {
        fade("top -140%", "top -160%");
      },

      "(min-width: 1440px) and (max-width: 1500px)": () => {
        fade("top -140%", "top -160%");
      },

      "(min-width: 1280px) and (max-width: 1440px)": () => {
        fade("top -130%", "top -150%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        fade("top -130%", "top -150%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        fade("top -150%", "top -170%");
      },

      "(max-width: 765px)": () => {
        fade("top -190%", "top -210%");
      },
    });

    const createAnimationsp1 = (
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef1.current.querySelectorAll(".p1"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef1.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef1.current.querySelectorAll(".p1"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef1.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp1("top 90%", "top 75%", "top 75%", "top 55%");
      },

      "(min-width: 1500px) and (max-width: 1736px)": () => {
        createAnimationsp1("top 80%", "top 60%", "top 60%", "top 40%");
      },

      "(min-width: 1440px) and (max-width: 1500px)": () => {
        createAnimationsp1("top 80%", "top 60%", "top 60%", "top 40%");
      },

      "(min-width: 1280px) and (max-width: 1440px)": () => {
        createAnimationsp1("top 80%", "top 60%", "top 60%", "top 40%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp1("top 75%", "top 50%", "top 50%", "top 30%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp1("top 80%", "top 50%", "top 50%", "top 30%");
      },

      "(max-width: 765px)": () => {
        createAnimationsp1("top 75%", "top 45%", "top 45%", "top 15%");
      },
    });

    const createAnimationsp2 = (
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef2.current.querySelectorAll(".p2"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef2.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef2.current.querySelectorAll(".p2"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef2.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp2("top 75%", "top 60%", "top 60%", "top 40%");
      },

      "(min-width: 1500px) and (max-width: 1736px)": () => {
        createAnimationsp2("top 60%", "top 40%", "top 40%", "top 20%");
      },

      "(min-width: 1440px) and (max-width: 1500px)": () => {
        createAnimationsp2("top 55%", "top 35%", "top 35%", "top 15%");
      },
      "(min-width: 1280px) and (max-width: 1440px)": () => {
        createAnimationsp2("top 55%", "top 35%", "top 35%", "top 15%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp2("top 50%", "top 25%", "top 25%", "top 5%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp2("top 50%", "top 20%", "top 20%", "top 0%");
      },

      "(max-width: 765px)": () => {
        createAnimationsp2("top 45%", "top 5%", "top 5%", "top -25%");
      },
    });

    const createAnimationsp3 = (
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef3.current.querySelectorAll(".p3"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef3.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef3.current.querySelectorAll(".p3"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef3.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp3("top 65%", "top 45%", "top 45%", "top 25%");
      },

      "(min-width: 1500px) and (max-width: 1736px)": () => {
        createAnimationsp3("top 30%", "top 0%", "top 0%", "top -20%");
      },

      "(min-width: 1440px) and (max-width: 1500px)": () => {
        createAnimationsp3("top 35%", "top 10%", "top 10%", "top -10%");
      },
      "(min-width: 1280px) and (max-width: 1440px)": () => {
        createAnimationsp3("top 35%", "top 10%", "top 10%", "top -10%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp3("top 25%", "top -5%", "top -5%", "top -25%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp3("top 20%", "top -5%", "top -5%", "top -25%");
      },

      "(max-width: 765px)": () => {
        createAnimationsp3("top 10%", "top -30%", "top -30%", "top -70%");
      },
    });

    const createAnimationsp4 = (
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef4.current.querySelectorAll(".p4"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef4.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef4.current.querySelectorAll(".p4"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef4.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp4("top 40%", "top 20%", "top 20%", "top 0%");
      },

      "(min-width: 1500px) and (max-width: 1736px)": () => {
        createAnimationsp4("top 0%", "top -20%", "top -20%", "top -40%");
      },

      "(min-width: 1440px) and (max-width: 1500px)": () => {
        createAnimationsp4("top 10%", "top -15%", "top -15%", "top -35%");
      },
      "(min-width: 1280px) and (max-width: 1440px)": () => {
        createAnimationsp4("top 10%", "top -15%", "top -15%", "top -35%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp4("top 0%", "top -25%", "top -25%", "top -45%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp4("top -5%", "top -35%", "top -35%", "top -55%");
      },

      "(max-width: 765px)": () => {
        createAnimationsp4("top -30%", "top -70%", "top -70%", "top -110%");
      },
    });

    const createAnimationsp5 = (
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef5.current.querySelectorAll(".p5"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef5.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef5.current.querySelectorAll(".p5"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef5.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp5("top 20%", "top 0%", "top 0%", "top -20%");
      },

      "(min-width: 1500px) and (max-width: 1736px)": () => {
        createAnimationsp5("top -30%", "top -55%", "top -55%", "top -70%");
      },

      "(min-width: 1440px) and (max-width: 1500px)": () => {
        createAnimationsp5("top -25%", "top -45%", "top -45%", "top -65%");
      },
      "(min-width: 1280px) and (max-width: 1440px)": () => {
        createAnimationsp5("top -15%", "top -35%", "top -35%", "top -55%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp5("top -25%", "top -50%", "top -50%", "top -70%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp5("top -35%", "top -65%", "top -65%", "top -90%");
      },

      "(max-width: 765px)": () => {
        createAnimationsp5("top -70%", "top -120%", "top -120%", "top -160%");
      },
    });

    const createAnimationsp6 = (
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef6.current.querySelectorAll(".p6"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef6.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef6.current.querySelectorAll(".p6"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef6.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp6("top 0%", "top -20%", "top -20%", "top -40%");
      },

      "(min-width: 1500px) and (max-width: 1736px)": () => {
        createAnimationsp6("top -55%", "top -80%", "top -80%", "top -100%");
      },

      "(min-width: 1440px) and (max-width: 1500px)": () => {
        createAnimationsp6("top -50%", "top -70%", "top -70%", "top -90%");
      },
      "(min-width: 1280px) and (max-width: 1440px)": () => {
        createAnimationsp6("top -35%", "top -60%", "top -60%", "top -80%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp6("top -50%", "top -75%", "top -75%", "top -90%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp6("top -65%", "top -90%", "top -90%", "top -120%");
      },

      "(max-width: 765px)": () => {
        createAnimationsp6("top -110%", "top -165%", "top -165%", "top -200%");
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <section className="overflow-hidden p-0">
      <div
        ref={triggerRef}
        className="container h-[350vh] md:h-[280vh] lg:h-[260vh] xl:h-[300vh] 2xl:h-[270vh] flex w-full  px-0 overflow-x-hidden "
      >
        <div
          className="header-title w-full absolute max-md:top-20 md:-top-2 flex flex-col items-center text-center  leading-tight  font-semibold tracking-wide text-gray-800 md:p-2   mb-1  "
          ref={(el) => (stepRefs.current[0] = el)}
        >
          <div ref={stepHead} className=" flex flex-col items-center">
            <p className="font-bold leading-tight w-full  lg:leading-snug  mx-0 text-[15px]  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
              A Roadmap to building the
              <span className="text-sky-400 ">
                {" "}
                Technological <br /> Backbone{" "}
              </span>{" "}
              of our DeAs portal.
            </p>
            <div className="text-[#212121] max-md:relative md:absolute md:top-32 xl:top-20 2xl:top-32 w-full flex flex-col justify-center mt-3 items-center  ">
              <div className="flex w-60 justify-center gap-3 border-2 border-yellow-400 items-center  rounded-xl  relative p-1 max-md:scale-[80%]">
                <div className="  rounded-lg  duration-200 ease-in-out">
                  <video
                    key="1"
                    className="w-10 h-10 remove-white-bg"
                    src="/images/calendar.webm"
                    alt={""}
                    autoPlay
                    loop
                    muted
                  />
                </div>
                <p className="text-lg font-semibold md:font-bold rounded-lg uppercase pr-2">
                  {month}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={sectionRef}
          className="h-[95vh] md:h-[100vh] relative bottom-0 items-end   w-[1250vw] md:w-[650vw] max-md:-left-[1150px] max-lg:-left-[700px] max-xl:-left-96 flex max-md:scale-[40%] max-lg:scale-[65%] max-xl:scale-75 max-2xl:scale-90 "
        >
          {/* section 1 */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start pr-20 border-t-[2px] border-[#1c5050] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#a7a7a7] before:border-t-[2px] before:border-dashed before:border-[#ffffff] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#a7a7a7] after:border-t-[2px] after:border-[#1c5050] lg:ml-10 xl:ml-20 2xl:ml-60"
            ref={(el) => (stepRefs.current[1] = el)}
            id="section1"
          >
            <div ref={textContainerRefHead}>
              <div className="p1-head md:w-20 md:h-20 h-36 w-36 bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-44 md:bottom-[60px] border-none border-[#1c5050] z-10 overflow-hidden">
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  p-1 remove-white-bg"
                  src="/images/lock.webp"
                  alt=""
                />
              </div>
              <div className="p1-head relative max-md:-top-96 md:-top-44 duration-300 ease-out text-4xl md:text-lg lg:text-xl text-[#008080] font-semibold">
                Phase 1
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#1c5050]"
              ref={textContainerRef1}
            >
              <div className="p1 h-72 md:h-24 xl:h-20 w-0.5  bg-black absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              {/* <div className="p1 h-5 w-5 rounded-full  bg-black absolute -top-6 max-md:left-[450px] md:left-[290px] z-50"></div> */}
              <div className="p1 h-[550px] md:h-52 xl:h-40 w-0.5  bg-black absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              {/* <div className="p1 h-5 w-5 rounded-full  bg-black absolute -top-6 max-md:left-[850px] md:left-[550px] z-50"></div> */}
              <div className="p1 h-72 md:h-24 xl:h-20 w-0.5  bg-black absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              {/* <div className="p1 h-5 w-5 rounded-full  bg-black absolute -top-6 max-md:left-[850px] md:left-[550px] z-50"></div> */}
              <div className="p1 h-[550px] md:h-52 xl:h-40 w-0.5  bg-black absolute top-0 max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p1 text-3xl md:text-sm  max-md:mt-36 bg-white lg:text-base font-semibold  leading-relaxed w-96 md:w-60 mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#008080] ">1.1</span>
                Informative pages (static), UI/UX design and Frontend
                architecture.
              </p>
              <p className="p1 text-3xl md:text-sm max-md:mt-[420px] md:mt-24 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col  px-2">
                <span className="font-extrabold text-[#008080]">1.2</span>
                OTP-based User Registration, Login and database encryption.
              </p>

              <p className="p1 text-3xl md:text-sm  max-md:mt-36 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col  px-2">
                <span className="font-extrabold text-[#008080]">1.3</span>
                PAN/Aadhaar based KYC verification with external APIs.
              </p>
              <p className="p1 text-3xl md:text-sm max-md:mt-[420px] md:mt-24 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col  px-2">
                <span className="font-extrabold text-[#008080]">1.4</span>
                Secure authentication framework and Local Storage encryption.
              </p>
            </div>
          </div>

          {/* section 2 */}
          <div
            className="w-max h-[400px]  mt-[10px] group flex flex-col items-start pr-20 border-t-[2px] border-[#923c34] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#a7a7a7] before:border-t-[2px] before:border-dashed before:border-[#ffffff] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#a7a7a7] after:border-t-[2px] after:border-[#923c34]"
            ref={(el) => (stepRefs.current[2] = el)}
            id="section2"
          >
            <div ref={textContainerRefHead}>
              <div className="md:w-20 md:h-20 h-36 w-36 text-[#ffffff] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-44 md:bottom-[60px]  border-none  border-[#923c34] z-10">
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  p-1 remove-white-bg"
                  src="/images/folder.webp"
                  alt=""
                />
              </div>
              <div className="relative max-md:-top-96 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#FF6F61] font-semibold ">
                Phase 2
              </div>
            </div>
            <div
              className="col flex  ml-6  text-[#923c34]"
              ref={textContainerRef2}
            >
              <div className="p2 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p2 h-[550px] md:h-52 w-0.5  bg-black absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p2 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p2 h-[550px] md:h-52 w-0.5  bg-black absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p2 text-3xl md:text-sm max-md:mt-36 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col pl-1 pr-2">
                <span className="p-2 font-extrabold text-[#FF6F61]">2.1</span>
                User Bank A/C linking with Wallet and fraud prevention system.
              </p>
              <p className="p2 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#FF6F61]">2.2</span>
                Integration of Escrow mechanism with collateralized securities.
              </p>
              <p className="p2 text-3xl md:text-sm max-md:mt-36 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#FF6F61]">2.3</span>
                Creation of Transaction - Ownership - Interest Payout data
                structure.
              </p>
              <p className="p2 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#FF6F61]">2.4</span>
                Auction Exchange module for purchase, revision and redemption of
                DeAs.
              </p>
            </div>
          </div>

          {/* section 3 */}
          <div
            className="w-max h-[400px] mt-[10px] group relative flex flex-col items-start pr-20  border-t-[2px] border-[#807432] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#a7a7a7] before:border-t-[2px] before:border-dashed before:border-[#ffffff] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#a7a7a7] after:border-t-[2px] after:border-[#807432]"
            ref={(el) => (stepRefs.current[3] = el)}
            id="section3"
          >
            <div className="absolute -top-24   w-[580px] h-20  ">
              <div className="float-right h-max relative max-md:top-5 md:top-7 left-14 flex flex-col items-center justify-end">
                <p className="font-bold text-3xl md:text-xl text-[#807432]">
                  We are here
                </p>
                <div class="ping z-10 max-md:scale-150"></div>
              </div>
            </div>

            <div ref={textContainerRef3Head}>
              <div className="p3Head md:w-20 md:h-20 h-36 w-36  z-10 text-[#ffffff] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-44 md:bottom-[60px]  border-none  border-[#807432] ">
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  remove-white-bg"
                  src="/images/robot-head.webp"
                  alt=""
                />
              </div>
              <div className="p3Head relative max-md:-top-96 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#f1db5a] font-semibold">
                Phase 3
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#807432]"
              ref={textContainerRef3}
            >
              <div className="p3 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p3 h-[550px] md:h-52 w-0.5  bg-black absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p3 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p3 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p3 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#f1db5a]">3.1</span>
                Client Fees calculation, dynamic Invoice and Contract Note
                generation.
              </p>
              <p className="p3 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#f1db5a]">3.2</span>
                Incorporation of User PAN linked Form 16As (from TRACES).
              </p>
              <p className="p3 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#f1db5a]">3.3</span>
                Frontend development of the (machine-learning based) Risk-Sensor
                Engine.
              </p>
              <p className="p3 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#f1db5a]">3.4</span>
                'BoP algorithm' implementation on User portfolio and optimizing
                risk-return.
              </p>
            </div>
          </div>

          {/* section 4 */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start pr-20  border-t-[2px] border-[#5b3575] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#ffffff] before:border-t-[2px] before:border-dashed before:border-[#a7a7a7] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#ffffff] after:border-t-[2px] after:border-[#5b3575]"
            ref={(el) => (stepRefs.current[4] = el)}
          >
            <div ref={textContainerRef4Head}>
              <div className="p4Head md:w-20 md:h-20 h-36 w-36  z-10 text-[#ffffff] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-44 md:bottom-[60px] rounded-full border-none  border-gray-700 ">
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  remove-white-bg"
                  src="/images/stock-market.webp"
                  alt=""
                />
              </div>
              <div className="p4Head relative max-md:-top-96 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#9f48dd] font-semibold">
                Phase 4
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#5b3575]"
              ref={textContainerRef4}
            >
              <div className="p4 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p4 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p4 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p4 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p4 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#9f48dd]">4.1</span>
                Fine-tuning of AI on asset-price correlations using historical
                data.
              </p>
              <p className="p4 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#9f48dd]">4.2</span>
                P-2-P Negotiated Dealing System based on price-time priority
                matching.
              </p>
              <p className="p4 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#9f48dd]">4.3</span>
                Programming of lien-marking and trade-settlement mechanisms for
                DeAs.
              </p>
              <p className="p4 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#9f48dd]">4.4</span>
                Real-time (security-wise) Trade data reporting and technical
                analysis.
              </p>
            </div>
          </div>

          {/* section 5 */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start pr-20 border-t-[2px] border-[#234723] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#ffffff] before:border-t-[2px] before:border-dashed before:border-[#a7a7a7] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#ffffff] after:border-t-[2px] after:border-[#234723]"
            ref={(el) => (stepRefs.current[5] = el)}
          >
            <div ref={textContainerRef5Head}>
              <div className="p5Head md:w-20 md:h-20 h-36 w-36  z-10 text-[#ffffff] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-44 md:bottom-[60px] rounded-full border-none  border-gray-700 ">
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  remove-white-bg"
                  src="/images/cheque.webp"
                  alt=""
                />
              </div>
              <div className="p5Head relative max-md:-top-96 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#228B22] font-semibold">
                Phase 5
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#234723]"
              ref={textContainerRef5}
            >
              <div className="p5 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p5 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p5 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p5 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p5 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#228B22]">5.1</span>
                Conversion of MONEY Wallet to PoW Blockchain, with native Token
                as INR stablecoin.
              </p>
              <p className="p5 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#228B22]">5.2</span>
                Conversion of WALR database to PoW Blockchain, with native Token
                as current WALR Series.
              </p>
              <p className="p5 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#228B22]">5.3</span>
                RESIDEX database to PoW Blockchain, with Token as equal-weighted
                average of 16 cities.
              </p>
              <p className="p5 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#228B22]">5.4</span>
                Algorithms for periodical adjusting of mining difficulty based
                on network hash-rate.
              </p>
            </div>
          </div>

          {/* section 6 */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start pr-20 border-t-[2px] border-[#52262f] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#ffffff] before:border-t-[2px] before:border-dashed before:border-[#a7a7a7] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#ffffff] after:border-t-[2px] after:border-[#52262f]"
            ref={(el) => (stepRefs.current[6] = el)}
          >
            <div ref={textContainerRef6Head}>
              <div className="p6Head md:w-20 md:h-20 h-36 w-36  z-10 text-[#f8f8f8] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-44 md:bottom-[60px] rounded-full border-none  border-gray-700 ">
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute remove-white-bg "
                  src="/images/data-network.webp"
                  alt=""
                />
              </div>
              <div className="p6Head relative max-md:-top-96 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#DC143C] font-semibold">
                Phase 6
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#52262f]"
              ref={textContainerRef6}
            >
              <div className="p6 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p6 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p6 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p6 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p6 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#DC143C]">6.1</span>
                Smart Contract integration of Auction Exchange & Peer-2-Peer
                Trading with WALR Blockchain.
              </p>
              <p className="p6 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#DC143C]">6.2</span>
                Smart Contract integration of Auction Exchange & Peer-2-Peer
                Trading with RESIDEX Blockchain.
              </p>
              <p className="p6 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#DC143C]">6.3</span>
                Smart Contract integration of MONEY stablecoin Blockchain with
                WALR and RESIDEX.
              </p>
              <p className="p6 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#DC143C]">6.4</span>
                Reconfigure all portal modules to retrieve & render Client data
                from the three distributed ledgers.
              </p>
            </div>
          </div>

          {/* End Point  */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start "
            ref={(el) => (stepRefs.current[7] = el)}
          >
            <div ref={textContainerRef6Head}>
              <div className=" md:w-20 md:h-20 h-36 w-36  z-10 text-[#f8f8f8] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-20 md:bottom-[60px] rounded-full border-none  border-gray-700 ">
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute -rotate-12 remove-white-bg"
                  src="/images/finish-flag.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default App;
